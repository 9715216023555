import React from "react";
import styled from "styled-components";
import { Text13, Text16, Text22 } from "../../components/atoms/Typography";


export const PrintPlay = ({ plays, options, points = false, page }) => {


    const getListColumns = () => {
        const numOfPlays = plays.length;
        const max = 8;
        let returnTable = []
        if (max !== 8) {
            returnTable = [plays]
        } else {
            let numOfIterations = Math.ceil(numOfPlays / max);
            for (let i = 0; i < numOfIterations; i++) {
                let table = [];
                for (let j = 0; j < max; j++) {
                    if (plays[i * max + j]) {
                        table = [...table, plays[i * max + j]]
                    }
                }
                returnTable = [...returnTable, table]
            }


        }

        return [returnTable, max]

    }

    const distributePlays = () => {
        if (!options.diagram) {
            return getListColumns()
        }

        const playsPerWristband = options.numOfPlays[0];

        if (plays.length <= playsPerWristband) {
            return [[plays], 0]
        }
        const numOfWristbands = Math.ceil(plays.length / playsPerWristband);
        let returnTable = []
        for (let i = 0; i < numOfWristbands; i++) {
            let tempTable = []
            for (let j = 0; j < playsPerWristband; j++) {
                if (plays[j + i * playsPerWristband] !== undefined) {
                    tempTable = [...tempTable, plays[j + i * playsPerWristband]]
                }
            }

            returnTable = [...returnTable, tempTable]

        }
        return [[...returnTable], 0]
    }

    const [playsTable, max] = options.printType === 'wristband' ? distributePlays() : [[plays], 0];

    const maxPerDiv = options.printType === 'wristband' ? options.numOfPlays[0] : options.numOfPlays[0] * options.numOfPlays[1]

    const coachingPoints = plays?.map(play => {
        if(!play){
            return null
        }
        return play.coaching_points
    });

    const getZoom = () => {
        if (options.printType === "wristband") {
            return 98
            // if(options.numOfPlays[0] === 4){
            //     return 100
            // }
            // return plays.length > 1 ? 100 : 100;
        } else if (maxPerDiv === 1) {
            return 100
        } else {
            return 90
        }
    }


    return (<PrintContent>

            {(playsTable.length > 0 && !points) && playsTable.map((plays, index) => (
                <PlayDiv key={index} options={options} num={options.numOfPlays[0]}>
                    {(options.diagram && plays.length > 0) ? plays.map((play, id) => play && (
                        <div key={id}>
                            <PrintImage size={getZoom()} diagram={options.printType !== "wristband"}>
                                <img
                                    // src={(options.printType === "wristband" && play.printImage !== undefined) ? play.printImage : play.playImage}
                                    src={play.playImage ? play.playImage : play.printImage}
                                    alt=""/>
                            </PrintImage>
                            {(options.moreInfo.includes('title') || options.moreInfo.includes('number') && play) &&
                                <PrintTitle wb={options.printType === "wristband"}>
                                    <Text13 style={{ color: '#404040' }}>
                                        {options.moreInfo.includes('number') && (
                                            <>
                                                {id + 1 + index * maxPerDiv + page * plays.length}. 
                                            </>
                                        )}
                                        {options.moreInfo.includes('title') && play.title}
                                    </Text13>
                                </PrintTitle>
                            }
                        </div>
                    )) : playsTable.map((column, index) => (<ListWrapper key={index}>
                        {column.length > 0 && column?.map((play, id) => play && (

                            <ListItem key={id} num={column.length} full={options.printType !== "wristband"} even={(id + 1 + page * plays.length) % 2 === 0}>
                                <div>
                                    <span>{id + 1 + index * max}</span>
                                </div>

                                <Text13> {play.title}</Text13>
                            </ListItem>
                        ))}
                    </ListWrapper>))

                    }
                    
                </PlayDiv>
            ))}


            {points &&
                <ListWrapper>
                    <PointsHeader>
                        <Text22>Coaching Points</Text22>
                    </PointsHeader>
                    {
                        coachingPoints?.map((points, id) => {
                            const lines = points.split(/\r?\n/);
                            return(
                                <ListItem key={id} num={coachingPoints.length + 1} full={false}
                                          even={(id + 1 + page * plays.length) % 2 > 0}>
                                    <div>
                                        <span>{id + 1 + page * plays.length}</span>
                                    </div>
                                    <div className={'coaching-points'}>
                                        {lines.map(line=><Text16 key={line}> {line}</Text16>)}
                                    </div>


                                </ListItem>)

                        })
                    }

                </ListWrapper>

            }
        </PrintContent>
    )
}
const getHeight = options => {
    if (options.printType === 'wristband') {
        return options.numOfPlays[0] >= 4 ? options.height / 2 + 'in' : options.height + 'in';
    } else {

        return options.numOfPlays[1] > 0 ? 99 / options.numOfPlays[1] + '%' : '100%'
    }
}
const getWidth = (options, num) => {
    if (options.printType === 'wristband') {
        return options.numOfPlays[0] >= 4 ? options.width / num * 2 + 'in' : options.width / num + 'in';
    } else {
        return 99 / options.numOfPlays[0] + '%'
    }
}

const PlayDiv = styled.div`

  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-content: start;
  overflow: hidden;
  flex-basis: ${props => props.options.printType === 'wristband' ? props.options.width + 'in' : '204mm'};
  height: ${props => (props.options.printType === 'wristband') ? props => props.options.height + 'in' : '260mm'};
  //border: 1px solid #a1a1a1;
  //margin-right: 1mm;
  margin-bottom: 1mm;

  & > div {
    flex-basis: ${props => getWidth(props.options, props.num)};
    height: ${props => getHeight(props.options)};
    border: ${props => props.options.diagram ? '1px solid #a1a1a1' : 'none'};
    overflow: hidden;
  }

`

const PrintImage = styled.div`
  height: 85%;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;

  img {
    margin-top: ${({ diagram }) => diagram ? 0 : 0}%;
    width: ${props => props.size || 100}%;
  }
`

const PrintTitle = styled.div`

  margin-top: ${({ wb }) => wb ? -5 : 0}%;
  flex-grow: 0;
  color: #404040;
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
  text-overflow: ellipsis;
  overflow: hidden;

  & > p {
    display: block;
    height: 100%;
    margin: auto auto 0;
    text-align: center;
    font-weight: 300;
  }
`

const ListWrapper = styled.div`
  flex-basis: ${props => props.width || '100%'};
  margin-top: ${props => props.marginTop || '0'};
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: column;
`

const ListItem = styled.div`
  //border: 1px solid #a1a1a1;
    //height: ${props => props.full ? '20px' : 99 / props.num + '%'};
  height: ${props => props.full ? 4 : 10}%;
  max-height: ${props => props.num < 6 ? '100px' : '100px'};
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  overflow-wrap: break-word;
  overflow: hidden;

  p {
    display: flex;
    align-items: center;
    color: #404040;
    height: 100%;
    padding-left: 5%;
    padding-top: 1%;
    padding-right: 5%;
    width: calc(100% - 20px);
    word-wrap: normal;
    font-size: ${props => props.full ? 14 : 12}px;
    font-weight: 300;
    background-color: ${({ even }) => even ? '#c9c9c9' : 'none'};
  }
  
  & > div {
    display: grid;
    place-items: center;
    height: 100%;
    width: ${props => props.full ? 30 : 20}px;
    border-right: 1px solid #a1a1a1;
    background-color: #404040;
    color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }
  & > div.coaching-points{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    height: 100%;
    background-color: #f6f7f8;
    padding-top: .5rem;
    width: calc(100% - ${props => props.full ? 30 : 20}px);
    margin: 0;
    p{
      display: block;
      color: #404040;
      font-weight: 300;
      padding-left: 1rem;
      height: auto;
      margin-bottom: 2px;
      background-color: transparent;
    }
  }
  span {
    font-size: 10px;
    font-weight: 700;
    color: white;
  }
`

const PointsHeader = styled.div`
  padding: 1rem;
  display: grid;
  place-items: center;
  background-color: #404040;
  color-adjust: exact;
  -webkit-print-color-adjust: exact !important;
`

const PrintContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  flex-basis: 204mm;
  height: 260mm;
  flex-grow: 0;
`
