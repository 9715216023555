import React from "react";
import { Grid } from "@material-ui/core";
import { SectionHeader } from "./SectionParts/SectionHeader";
import { SettingButton } from "./SectionParts/SettingButton";
import Star from "../svg/Star.svg";
import Circle from "../svg/Circle.svg";
import Rectangle from "../svg/Rectangle.svg";
import Triangle from "../svg/Triangle.svg";
import Oval from "../svg/Oval.svg";
import Hexagon from "../svg/Hexagon.svg";
import Diamond from "../svg/Diamond.svg";
import Cross2  from "../svg/Cross2.svg";
import Ball from "../svg/Ball.svg";
import Flag from '../svg/Flag.svg'
import Astrick from '../svg/Astrick.svg'
import AddLine from '../svg/AddLine.svg'
import { StyledBordered, StyledToolsContainer } from "../StyledComponents/StyledComponents";


export const PlayerShape = ({ player, settingChange, open, setOpen }) => {

    

    const shapeTable = ['rectangle', 'circle', 'star', 'triangle', 'oval', 'diamond', 'hexagon', 'cross'];
    const iconTable = [Rectangle, Circle, Star, Triangle, Oval, Diamond, Hexagon, Cross2];
    const shapeTable2 = ['ball', 'flag', 'asterisk', 'addline'];
    const iconTable2 = [Ball, Flag, Astrick, AddLine]



    return (
      <>
      <StyledToolsContainer>
        <SectionHeader text={player.player ? 'PLAYER' : 'ELEMENT'}  setOpen={setOpen} open={open} sectionName={'shape'}/>
        </StyledToolsContainer>
        
        <StyledToolsContainer marginBottom={1}>
        <StyledBordered>
            <Grid container spacing={0} >
            
                {player.player ?shapeTable.map((shape, index) => {
                        return <SettingButton  key={shape} active={player.shape === shape} settingChange={settingChange}
                                              value={shape}
                                              setting={'shape'} icon={iconTable[index]} xs={3} title={shape}/>
                })
                 
                :shapeTable2.map((shape, index) => {

                    return <SettingButton key={shape} active={player.shape === shape} settingChange={settingChange}
                                          value={shape}
                                          setting={'shape'} icon={iconTable2[index]} xs={3} title={shape}/>})}

            </Grid>
            </StyledBordered>
        </StyledToolsContainer>
        
        </>
    )
}
