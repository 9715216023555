import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    checkIfMoving,
    getAnimationDuration,
    getArcAnimationPoints,
    isStartingPoint, movePlayer, resetPlayer,
} from "../../Helpers/animationHelper";
import { Text } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const Label = ({
                          player,
                          activeTopSetting,
                          ratio,
                          radius,
                          editable,
                          handleClickActivate,
                          handlePlayerDrag,
                          handleTarget,
                          pa,
                          delay,
                          rp,
                          activePlayer,
                      }) => {

    const movingPart = useRef();
    const falseStart = player.line_dash.includes('wiggly');
    const moving = useMemo(() => checkIfMoving(player), [player, pa, delay, activeTopSetting])
    const startPoints = useMemo(() => player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points, [player, pa, activePlayer, delay, activeTopSetting, rp])
    const [points, setPoints] = useState(player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points)
    const [timeout, setTime] = useState(0);

    useEffect(() => setPoints(player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points), [player, pa, delay, activeTopSetting, rp, activePlayer])

    useEffect(() => {
        if (moving && pa && points.length) {
            const duration = getAnimationDuration(points);
            const newPoints = points.filter((point, index) => index > 1)
            const startDelay = (isStartingPoint(player, movingPart.current.attrs) && !falseStart) ? delay * 1000 : 0

            if (startDelay) {
                setTimeout(() => movePlayer(movingPart, duration, newPoints[0], newPoints[1]), startDelay)
            } else {
                movePlayer(movingPart, duration, newPoints[0], newPoints[1])
            }

            if (newPoints.length > 2 && pa) {
                const id = setTimeout(() => setPoints([...newPoints]), (duration * 1000) + startDelay)
                setTime(id)
            }else if(pa){
                const id = setTimeout(() => setPoints([]), (duration * 1000) + startDelay)
                setTime(id)
            }

        } else if (!pa) {
            if (startPoints.length !== points.length) {
                setPoints([...startPoints]);
                clearTimeout(timeout)
                setTime(0)
                resetPlayer(movingPart, player);
            }
        }
    }, [pa, points, player])
    return <Text x={player.x}
                 y={player.y}
                 ref={movingPart}
                 width={radius * 2}
                 height={basePlayerSize * ratio}
                 text={player.label.toUpperCase()}
                 offsetX={radius}
                 offsetY={(basePlayerSize - 6) * ratio / 2}
                 fontSize={38 * player.size * ratio}
                 fontFamily="Jersey M54"
                 fill={player.label_fill}
                 fontStyle="Bold"
                 align={"center"}
                 verticalAlign={"middle"}
                 draggable={activeTopSetting !== 'DL' && editable}
                 onClick={e => handleClickActivate(e)}
                 onTouchEnd={e => handleClickActivate(e)}
                 onDragMove={e => {
                     handlePlayerDrag(e);
                     handleTarget(e, true)
                 }}
                 anchor={player.id}/>
}
