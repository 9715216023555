import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Text13, Text18 } from "../atoms/Typography";
import { NavLink, useHistory } from "react-router-dom";
import MORE_MENU_ICON from "../../assets/icons/more_menu.svg";
import Dropdown, { DropdownButton } from "../atoms/Dropdown";
import { setPrint } from "../../redux/actions";
import { useDispatch } from "react-redux";
import DeleteModal from "../modals/DeleteModal";

export const PlaybookLoader = () => {
    return (
        <LoaderWrapper className="gradient-animation"/>
    );
}

export const PlaybookButton = ({ onClick }) => {

    return (
        <Button className="main-button" onClick={onClick}>
            <Span></Span>
            <Text18>+ new playbook</Text18>
        </Button>
    );
}

const PlaybookBlock = ({ index, item, onEdit, onDelete, onDuplicate }) => {
    const moreRef = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const [showMore, setShowMore] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const { id, title, created_at, order } = item
    const to = `/panel/playbooks/${id}?title=${title}`

    const handleShow = useCallback(() => {
        setShowMore(true)
    }, [])

    const handleClose = useCallback(() => {
        setShowMore(false)
    }, [])

    const handleOpen = useCallback(() => {
        history.push(to)
    }, [])

    const handleClickDelete = useCallback(() => {
        setShowMore(false)
        setShowDeleteModal(true)
    }, [])

    const handleDelete = useCallback(() => {
        setShowDeleteModal(false)
        onDelete(item)
    }, [onDelete])

    const handleDuplicate = useCallback(() => {
        console.log( index );
        onDuplicate(item, index );
        setShowMore(false)
    }, [onDuplicate])

    const handleEdit = useCallback(() => {
        onEdit(item)
        setShowMore(false)
    }, [item, onEdit])

    const handlePrint = useCallback(() => {
        dispatch(setPrint({
            type: 'playbook',
            playbook: item,
        }))
        setShowMore(false)
    }, [])

    return (
        <>
            <Wrapper>
                <Link to={to}>
                    <Text18 margin="30px 0 20px">{title}</Text18>
                    <Text13>{created_at}</Text13>
                </Link>
                <MoreIcon ref={moreRef} onClick={handleShow}>
                    <img src={MORE_MENU_ICON}/>
                </MoreIcon>
            </Wrapper>
            <Dropdown
                open={showMore}
                refProp={moreRef}
                onClose={handleClose}
            >
                <DropdownButton text="Open" onClick={handleOpen}/>
                <DropdownButton text="Print" onClick={handlePrint}/>
                {onDuplicate && <DropdownButton text="Duplicate" onClick={handleDuplicate}/>}
                <DropdownButton text="Edit" onClick={handleEdit}/>
                {onDelete && <DropdownButton text="Delete" onClick={handleClickDelete}/>}
            </Dropdown>
            <DeleteModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                text="Are you sure you want to delete this playbook and its plays?"
                onDelete={handleDelete}/>
        </>
    );
}

export default PlaybookBlock;

const MoreIcon = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  cursor: pointer;
  background: none;
  border: none;
  img{
    width: 5px;
  }
  `;

const LoaderWrapper = styled.div`
  // background: ${({ theme }) => theme.color.black};
  border: 2px solid ${({ theme }) => theme.color.black};
  min-height: 190px;
  opacity: 0.5;
  border-radius: 15px;
  overflow: hidden;
  `;

const base = css`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 210px;
  padding: 3rem 2rem 1.2rem 2rem;
  background: ${({ theme }) => theme.color.lblue};
  border: 2px solid ${({ theme }) => theme.color.lblue};
  border-radius: 15px;
  transition: border .3s;
  position: relative;
  height: 100%;
  box-shadow: 6px 6px #0077ff8c, 12px 12px #0077FF59;
  
  &:hover {
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: left;
  ${base};
  padding: 2rem;
  box-shadow: none;
`;

const Span = styled.span`
  display: block;
  width: 100px;
  height: 70px;
  margin: 0 0 15px 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.primary};
  box-shadow: 4px 4px rgb(252 196 0 / 60%), 8px 8px rgb(252 196 0 / 40%);
`;

const Link = styled(NavLink)`

`;

const Wrapper = styled.div`
  ${base}
`;
