import React, { useCallback, useState } from 'react';
import { useForm } from "react-hook-form";
import { Row } from "../../theme/styledComponents";
import { Text22 } from "../atoms/Typography";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Radio from "../atoms/Radio";
import TagsBlock from "../blocks/TagsBlock";
import Select from "../atoms/Select";
import { useSelector } from "react-redux";
import Modal from "../molecules/Modal";
import { PlaysService } from "../../services/PlaysService";
import { getLastLocation } from "../../pages/PlayMaker/Components/Helpers/switchPlayHelper";
import { useHistory } from "react-router-dom";

const AddPlayModal = ({ show, setShow, refresh, playbookId, predefined = false }) => {
    const history = useHistory()
    const playbooks = useSelector(store => store.content.playbooks);
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            title:'',
            playbook_id: "-1",
        },
    })

    const title = watch('title')
    const playBookId = watch('playbook_id')

    const [state, setState] = useState({
        predefined,
        fieldSize:'long',
        fieldLines: 'yes',
        categories:[],
    })

    const handleHideModal = useCallback(() => {
        setShow(false)
    }, [])

    const checkKeyDown = e => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const handleChangeCategories = useCallback((id, arr) => {
        setState({
            ...state,
            categories: arr,
        })
    }, [state])

    const onSubmitFunc = useCallback(async el => {
        if(loading)
            return

        setLoading(true)

        let play;
        if(playbookId){
            play = await PlaysService.addPlayToPlaybook(playbookId, {
                ...el,
                ...state,
            })
        }else {
            play = await PlaysService.addPlay({
                ...el,
                ...state,
            })
        }
        history.push(`/app/${play.id}?from=${getLastLocation(history)}&title=${play.title}`)
    }, [state, loading, refresh]);

    const isValid = title.replace(/\s/g, '').length > 0;
    const renderOptions = [[-1, "Not assigned"], ...playbooks.items?.map(playbook => [playbook.id, playbook.title])];

    return (
        <Modal show={show} setShow={setShow} type="side" maxWidth="500px">
            <form onSubmit={handleSubmit(onSubmitFunc)} onKeyDown={e => checkKeyDown(e)}>
                <Row jc="space-between" margin="0 0 30px">
                    <Text22>new play</Text22>
                    <Row>
                        <Button type="third" margin="0 25px" abc="sxwxxw" onClick={handleHideModal}>
                            Cancel
                        </Button>
                        <Button width="max-content" disabled={!isValid} loading={loading}>
                            Create
                        </Button>
                    </Row>
                </Row>
                <Input margin="0 0 40px" label='Play title' error={errors.title} rhf={register('title', { required: true })}/>
                {
                    !predefined &&
                    <Select
                        margin="0 0 40px"
                        label='Playbook'
                        options={renderOptions}
                        error={errors.playbook}
                        rhf={register('playbook_id', { required: true })}
                    />
                }
                {
                  <>
                      <Radio label="Field lines" margin="0 0 30px" value={state.fieldLines} options={[['Yes', 'yes'], ['No', 'no']]} onChange={e => setState({ ...state, fieldLines: e })}/>
                  </>
                }
                <TagsBlock value={state.categories} onChange={handleChangeCategories}/>
            </form>
        </Modal>
    );
}

export default AddPlayModal;
