import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Text22 } from "../../components/atoms/Typography";
import Alert from "../../components/atoms/Alert";
import Button from "../../components/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions";
import { useForm } from 'react-hook-form';
import Input from "../../components/atoms/Input";
import LOGO from "../../assets/images/flagfootballu.png";
import BG from "../../assets/images/footballfield.jpeg";

const Login = () => {
    const dispatch = useDispatch();
    const { loginError, isLoggingIn } = useSelector(store => store.auth);
    const { register, handleSubmit, formState: { errors }} = useForm({
        defaultValues: {
            username:'',
            password:'',
        },
    })

    const onSubmit = useCallback(state => {
        dispatch(loginUser(state));
    }, []);

    return (
        <Wrapper>
            <Content>
                <div>
                    <Logo src={LOGO}/>
                    {loginError && <Alert>Invalid username or password</Alert>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input label='Username' error={errors.username} rhf={register('username', { required: true })}/>
                        <Input label='Password' error={errors.password} password rhf={register('password', { required: true })}/>
                        <Button loading={isLoggingIn}>
                            Log in
                        </Button>
                    </form>
                </div>
            </Content>
        </Wrapper>
    );
}

export default Login;

const Logo = styled.img`
  height: 40px;
  margin: 0 auto;
  display: block;
  margin-bottom: 60px;
  `;

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: linear-gradient(156.22deg, ${({ theme }) => theme.color.lblue} 0%, ${({ theme }) => theme.color.blue} 79.21%);
  box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  >div{
    width: 100%;
    max-width: 400px;
  }
  input {
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.black};
    border-radius: 10px;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  input:focus {
    border: none !important;
  }
  button {
    background: none;
    text-transform: uppercase;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    border: 2px solid ${({ theme }) => theme.color.white};
    border-radius: 10px;
    cursor: pointer;
  }
  button:hover {
    border-color: ${({ theme }) => theme.color.yellow};
    background: ${({ theme }) => theme.color.yellow};
    transform: translate(0px, -3px);
    box-shadow: 0 2px 5px -3px #000;
  }
  button .loading.active {
    border-radius: 10px;
  }
  `;

const Wrapper = styled.div`
    width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr;
  background: url(${BG}) no-repeat;
  background-size: cover;
  > div {
    animation: fade 0.75s ease;
  }
  @keyframes fade {
    0% {
        transform: translate(-600px, 0px);
    }
    100% {
        transform: translate(0px, 0px);
    }
  }
    `;
