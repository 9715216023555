import { APIService } from "./APIService";

const getTags = () => APIService.get('/ffb/tags')

const addCategory = cat => APIService.post('/ffb/categories', {
    title: cat,
})

const editCategory = (id, cat) => APIService.patch(`/ffb/categories/${id}`, {
    title: cat,
})

const deleteCategory = id => APIService.delete(`/ffb/categories/${id}`)

export const UserService = {
    getTags,
    addCategory,
    editCategory,
    deleteCategory,
}
