import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BookmarksView from "../../../templates/BookmarksView/BookmarksView";
import { PlayButton, PlayLoader } from "../../../components/blocks/PlayBlock";
import { useLocation, useParams } from "react-router-dom";
import { PlaysService } from "../../../services/PlaysService";
import { useQuery } from "../../../hooks/useQuery";
import PlaybookContainer from "./PlaybookContainer";
import { sortByPlaybookOrder } from "../../../utils/arrayHelpers";

const Playbook = () => {
    let { id } = useParams()
    const title = useQuery('title')
    const location = useLocation();

    const [plays, setPlays] = useState()
    const [showModal, setShowModal] = useState(false)

    const handleShowModal = useCallback(() => {
        setShowModal(true)
    }, [])

    const fetchData = useCallback(async () => {
        const data = await PlaysService.getPlays(1, false, '', [], id);
        setPlays(data.items);
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <BookmarksView goBack="/panel/playbooks" options={[{
            title,
            path: location.pathname,
        }]}>
            {
                () => <>
                    <Wrapper>
                        <PlaybookContainer playbookId={id} items={plays} setItems={setPlays} show={showModal}
                                           setShow={setShowModal} fetchData={fetchData}>
                            <PlayButton onClick={handleShowModal}/>
                            {
                                !plays &&
                                <>
                                    <PlayLoader/>
                                    <PlayLoader/>
                                </>
                            }
                        </PlaybookContainer>
                    </Wrapper>
                </>
            }
        </BookmarksView>
    );
}

export default Playbook;

const Wrapper = styled.div`

`;
