import { APIService } from "./APIService";

const getPlaybookPlays = id => APIService.get(`/ffb/playbooks/${id}/plays`);

const addPlayToPlaybook = (id, play) => APIService.post(`/ffb/playbooks/${id}/plays`, play);

const getPlays = (page, predefined = false, search, tags, playbook_id = '-1' ) => {
  return APIService.post(`/ffb/plays/filter?page=${page}&predefined=${predefined}${search ? `&phrase=${search}` : ''}&pid=${playbook_id}`, tags.length > 0 ? {
    filter: tags,
  } : null)
}
const getAllPlays = (predefined = false) => APIService.get(`/ffb/plays?predefined=${predefined}`);

const movePlay = type => APIService.patch(`/ffb/plays/move/${type ? `?type=${type}` : ''}`);

const addPlay = play => APIService.post(`/ffb/plays`, play);

const getPlay = id => APIService.get(`/ffb/plays/${id}`);


const editPlay = (id, play) => APIService.patch(`/ffb/plays/${id}`, play);
const getSiblingPlay = (id, direction) => APIService.get(`/ffb/siblings/${id}?direction=${direction}`);

const deletePlay = id => APIService.delete(`/ffb/plays/${id}`);

const duplicatePlay = ( id, use_template = false, predefined = false ) => APIService.patch(`/ffb/plays/${id}/?duplicate=true&use_template=${use_template}`, { predefined: predefined });
const useTemplate = id => APIService.patch(`/ffb/plays/${id}/?duplicate=true&use_template=true` );

const updatePlaysOrder = async ( old_index = 0, new_index) => {
  return await APIService.patch(`/ffb/plays/order?old_index=${old_index}&new_index=${new_index}` );
}

const updatePlaybookPlaysOrder = async (id, body) => {
  return await APIService.patch(`/ffb/playbooks/${id}/order`, body)
}

const getPlaysSwitch = (pid, pre) => APIService.get(`/ffb/plays/switch?pid=${pid}&predefined=${pre}`);

export const PlaysService = {
  getPlaybookPlays,
  addPlayToPlaybook,
  getPlays,
  getAllPlays,
  movePlay,
  addPlay,
  getPlay,
  editPlay,
  deletePlay,
  duplicatePlay,
  useTemplate,
  updatePlaysOrder,
  updatePlaybookPlaysOrder,
  getPlaysSwitch,
  getSiblingPlay,
}
