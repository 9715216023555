import React from "react";
import styled from "styled-components";
import { PrintPlay } from "./PrintPlay";
import { Text22 } from "../../components/atoms/Typography";


export const PrintComponent = ({ plays, printRef, options, playbook = false }) => {

    const getPlayPool = () => {
        let perBox = options.printType === 'wristband' ?  options.numOfPlays[0] : options.numOfPlays[0] * options.numOfPlays[1];
        if(!options.diagram){
            perBox = options.printType === 'wristband' ? options.numOfPlays[0] * 8 : options.numOfPlays[0] * 20;
        }

        const missing = perBox - plays.length % perBox;
        let returnTable = [...plays];
         if(missing !== perBox ){
             for (let i = 0; i < missing ; i++) {
                 returnTable.push(false)
             }
         }

         if(options.copies === 1){
             return returnTable
         }else{
             const temp = returnTable;
             for (let i = 1; i < options.copies ; i++) {
                 returnTable = [...returnTable, ...temp]
             }

             return returnTable
         }
    }

    const paginatePlays = () => {
        const playsPerPage = getMaxPerPage(options);
        let returnTable = []
        let playPool = getPlayPool();

        if (playPool.length <= playsPerPage) {
            returnTable = [playPool]
        } else {

            const numOfPages = Math.ceil(playPool.length / playsPerPage);

            for (let i = 0; i < numOfPages; i++) {
                let tempTable = [];
                for (let j = 0; j < playsPerPage; j++) {
                        tempTable = [...tempTable, playPool[j + i * playsPerPage]]
                }
                returnTable = [...returnTable, tempTable]
            }
        }
        return returnTable
    }

    const pagesContent = paginatePlays()?.map((table, index) => (
        <div key={index}>
            <PrintWrapper key={index} options={options} playsTable={table} playbook={playbook}
                          page={index}/>
            {options.moreInfo.includes('points') &&
                <PrintWrapper key={index} options={options} playsTable={table} playbook={playbook} page={index}
                              points={true}/>}
        </div>
    ))

    return (
        <PrintDiv>
            <PrintPaper ref={printRef}>
                {pagesContent}
            </PrintPaper>
        </PrintDiv>)
}


const PrintWrapper = ({ playsTable, options, playbook = false, points = false, page }) => {
    return (
        <div className="wrapper">
            <PlaybookHeader>
                {playbook && <Text22 style={{ color: 'black' }}>{playbook.title}</Text22>}
                <Text22 style={{ color: '#ffffff' }}>Flag Football</Text22>
            </PlaybookHeader>

            {playsTable?.length > 0 && <PrintPlay plays={playsTable} options={options} points={points} page={page}/>}

        </div>
    )


}

const getMaxPerPage = ({ numOfPlays, printType, height, width, diagram }) => {
    const mmInInch = 25.4;
    const maxHeight = 262;
    const maxWidth = 200;
    if (!diagram && printType !== 'wristband') {
        return numOfPlays[0] * 20;
    }

    if (printType === 'wristband') {
        const maxRows = Math.floor(maxHeight / (height * mmInInch));
        const maxColumns = Math.floor(maxWidth / (width * mmInInch));

        return maxRows * maxColumns * numOfPlays[0] * (diagram ? 1 : 8);

    } else {
        return numOfPlays[0] * numOfPlays[1];
    }
}


const PrintDiv = styled.div`
  margin: 0;
  background-color: #af1616;
  display: none;
  width: 210mm;
  height: 297mm;
`


const PrintPaper = styled.div`

  //margin-left: -2rem;
  box-sizing: border-box;
  background-color: #fff;
  width: 210mm;
  //height: 297mm;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  position: relative;


  div.wrapper {

    margin: 0 5mm;
    //padding: 10%;
    width: 200mm;
    height: 297mm;
    display: flex;
    flex-grow: 0;
    flex-wrap: wrap;
    justify-items: start;
    align-content: start;
    page-break-after: always;

  }

  @page {
    size: A4;
    margin: 0;
  }

`


const PlaybookHeader = styled.div`
  display: flex;
  padding: 10mm 0 10mm;
  justify-content: end;
  box-sizing: border-box;
  height: 35mm;
  //border: 1px solid black;
  flex-basis: 95%;

  h1 {
    font-size: 3rem;
  }

  h1:last-child {
    font-weight: 300;
    margin-left: auto;
  }
`
