import styled from 'styled-components'

export const Text22 = styled.h1`
  margin: ${({ margin }) => margin || "0"};
  font-size: ${({ theme }) => theme.font.XL};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme, color = "white" }) => theme.color[color]};
`;

export const Text20 = styled.h1`
  margin: ${({ margin }) => margin || "0"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme, color = "white" }) => theme.color[color]};
`;

export const Text18 = styled.h2`
  margin: ${({ margin }) => margin || "0"};
  font-size: ${({ theme }) => theme.font.L};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme, color = "white" }) => theme.color[color]};
`;

export const Text16 = styled.p`
  margin: ${({ margin }) => margin || "0"};
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.normal};
  color: ${({ theme, color = "white" }) => theme.color[color]};
  text-align: ${({ textAlign }) => textAlign || "left"};
`;


export const Text13 = styled.p`
  margin: ${({ margin }) => margin || "0"};
  font-size: ${({ theme }) => theme.font.S};
  font-weight: ${({ theme }) => theme.font.normal};
  color: ${({ theme, color = "text" }) => theme.color.white};
`;
