import React, { useState } from "react";
import { Line } from "react-konva";

import { ActiveLine } from "./ActiveLine";
import { generateDashTable, getWigglyLine } from "../../Helpers/LineHelper";
import { lineWithMultiplier } from "../../../constants";


export const SimpleLine = ({
                               player,
                               points,
                               dash,
                               activeLine,
                               lineActivation,
                               id,
                               dashTable = false,
                               lastPoints = null,
                               ratio,
                           }) => {

    const [hover, setHover] = useState(false)
    const getDashTable = () => {
        if (dash && dash !== 'solid' && dash !== 'wiggly' && dash !== 'long') {
            return dash === 'dot' ? [0.001, 10] : [15, 15]
        } else {
            if (dashTable) {
                return generateDashTable(dashTable, points)
            }
        }
    }

    const actualPoints = points => lastPoints === null ? [...points] : [...lastPoints, points[2], points[3]];

    return (
        <>
            <Line points={dash !== 'wiggly' ? actualPoints(points) : getWigglyLine(player, actualPoints(points))}
                  stroke={player.stroke_fill !== '' ? player.stroke_fill : '#6949FF'}
                  strokeWidth={player.line_width * ratio * lineWithMultiplier}
                  lineCap={"round"}
                  lineJoin={"round"}
                // tension={dash !== 'wiggly' ? player.line_tension : 0}
                  dashEnabled={(dash !== 'solid' && dash !== 'wiggly') || dash === 'long'}
                  dash={getDashTable()}
                  onMouseOver={() => setHover(true)}
                  onMouseOut={() => setHover(false)}
                  onClick={() => lineActivation(player.id, id)}
                  opacity={(hover || activeLine) ? 0.5 : 1}
            />
            {activeLine && <ActiveLine player={player} points={actualPoints(points)}/>}

        </>

    )
}

