export const GET_PLAYBOOKS = "GET_PLAYBOOKS";

export const GET_PLAYS = "GET_PLAYS";

export const GET_ACTIVE_FILTERS = "GET_ACTIVE_FILTERS";

export const GET_PLAYERS = "GET_PLAYERS";

export const GET_PREDEFINED_PLAYS = "GET_PREDEFINED_PLAYS";

export const GET_TAGS = "GET_TAGS";

export const CLEAR_CONTENT = "CLEAR_CONTENT";

export const getPlaybooks = playbooks => {
    return {
        type: GET_PLAYBOOKS,
        payload: playbooks,
    };
};

export const getPlays = plays => {
    return {
        type: GET_PLAYS,
        payload: plays,
    };
};


export const getActiveFilters = activeFilters => {
    return {
        type: GET_ACTIVE_FILTERS,
        payload: activeFilters,
    };
};

export const getPredefinedPlays = plays => {
    return {
        type: GET_PREDEFINED_PLAYS,
        payload: plays,
    };
};
export const getPlayers = players => {
    return {
        type: GET_PLAYERS,
        payload: players,
    };
};

export const getTags = tags => {
    return {
        type: GET_TAGS,
        payload: tags,
    };
};

export const clearContent = () => {
    return {
        type: CLEAR_CONTENT,
    };
};
