import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayBlock, { PlayButton } from "../../../../components/blocks/PlayBlock";
import { PlaysService } from "../../../../services/PlaysService";
import AddPlayModal from "../../../../components/modals/AddPlayModal";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import EditPlayModal from "../../../../components/modals/EditPlayModal";
import { mapPredefinedOrder, sortByPredefinedOrder } from "../../../../utils/arrayHelpers";
import DragAndDropGrid from "../../../../components/DragAndDropGrid";
import { useFilters } from "../../../../hooks/useFilters";
import { useLoading } from "../../../../hooks/useLoading";
import { getLastLocation } from "../../../PlayMaker/Components/Helpers/switchPlayHelper";
import { useHistory } from "react-router-dom";

const PredefinedList = () => {

    const history = useHistory();
    const { search, tags } = useFilters();
    const hasPermission = useHasPermission("predefined")

    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)

    const { setLoading : setGlobalLoading } = useLoading()
    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [hasMorePages, setHasMorePages] = useState(false)

    const [tmp, setTmp] = useState()

    const handleShowModal = useCallback(() => {
        setModal('add-play')
    }, [])

    const loadItems = useCallback(async page => {
        try {
            setLoading(true)
            const { items, page_count } = await PlaysService.getPlays(page, true, search, tags);
            const arr = items.sort(sortByPredefinedOrder)
            setData(page > 1 ? [...data, ...arr] : arr)
            setCurrentPage(page)
            setHasMorePages(page < page_count)
        }catch (e){
            console.log('[getPlays API]: ', e)
        }finally {
            setLoading(false)
        }
    }, [data, search, tags])

    const loadMoreItems = useCallback(async () => {
        const page = currentPage + 1
        loadItems(page)
    }, [loadItems, currentPage])

    useEffect(() => {
        setData([])
        loadItems(1)
    }, [search, tags])

    const handleDeletePlay = useCallback(async item => {
        try {
            setGlobalLoading(true)
            await PlaysService.deletePlay(item.id)
            loadItems(1)
        }catch (e){
            console.log("Error [deletePlay]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [currentPage])

    const handleDuplicatePlay = useCallback(async item => {
        try {
            setGlobalLoading(true)
            const duplicated = await PlaysService.duplicatePlay(item.id, false, true);
            setData([ duplicated, ...data].sort(sortByPredefinedOrder).map(mapPredefinedOrder))
        }catch (e){
            console.log("Error [duplicatePlay]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [data])

    const handleUseTemplate = useCallback(async item => {
        try {
            setGlobalLoading(true)
            const duplicated = await PlaysService.duplicatePlay(item.id, true);
            setData([ duplicated, ...data] );
        }catch (e){
            console.log("Error [duplicatePlay]:", e)
        }finally {
            history.push(`/panel/plays` );
            setGlobalLoading(false)
        }
    }, [data])

    const handleOnEdit = useCallback(async el => {
        setModal('edit-play')
        setTmp(el)
    }, [])

    const handleOnMove = useCallback(async newData => {
        try {
            setGlobalLoading(true)
            await PlaysService.updatePlaysOrder(newData, true)
        }catch (e){
            console.log("Error [updatePlaysOrder]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [currentPage])

    const renderItem = useCallback((item, index) => {
        return <PlayBlock
            key={item.id}
            index={index}
            item={item}
            predefined={true}
            onEdit={hasPermission && handleOnEdit}
            onDuplicate={handleDuplicatePlay}
            onUseTemplate={handleUseTemplate}
            onDelete={handleDeletePlay}
        />
    }, [handleDuplicatePlay, handleDeletePlay, handleOnEdit])

    return (
        <>
            <Wrapper>
                <DragAndDropGrid
                    items={data}
                    setItems={setData}
                    loading={loading}
                    loadMoreItems={loadMoreItems}
                    hasMorePages={hasMorePages}
                    renderItem={renderItem}
                    onMove={handleOnMove}
                    mainButton={hasPermission && <PlayButton onClick={handleShowModal} />}
                >
                    {
                        hasPermission &&
                            <>
                                <AddPlayModal
                                    show={modal === 'add-play'}
                                    setShow={setModal}
                                    refresh={() => loadItems(1)}
                                    predefined={true}
                                />
                                <EditPlayModal
                                    show={modal === 'edit-play'}
                                    setShow={setModal}
                                    data={tmp}
                                    refresh={() => loadItems(1)}
                                    predefined={true}
                                />
                            </>
                    }
                </DragAndDropGrid>
            </Wrapper>
        </>
    );
}

export default PredefinedList;

const Wrapper = styled.div`

`;
