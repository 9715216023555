import React from "react";
import { Star } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const StarPlayer = ({
                               player,
                               handleClickActivate,
                               activeTopSetting,
                               handlePlayerDrag,
                               editable,
                               handleTarget,
                               enableDraw,
                               ratio,
                               dm,
                               movingPart,
                           }) => {

    return (

        <Star numPoints={5}
              innerRadius={(basePlayerSize * player.size * ratio) / 4}
              outerRadius={basePlayerSize * player.size * ratio / 1.5}
              y={player.y}
              x={player.x}
              fill={player.shading === 'full' ? player.color : dm}
              stroke={player.shading === 'full' ? 'transparent' : player.color}
              draggable={activeTopSetting !== 'DL' && editable}
              onMouseEnter={() => enableDraw(false)}
              onMouseLeave={() => enableDraw(true)}
              onClick={e => handleClickActivate(e)}
              onTouchEnd={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}
              ref={movingPart}
              anchor={player.id}/>


    )
}
