import React from "react";
import styled from "styled-components";
import { VERSION } from "../../theme/constans";

const Footer = () => {

    return (
        <Wrapper>
            {VERSION} - {process.env.REACT_APP_ENVIRONMENT.toUpperCase()}
        </Wrapper>
    )
}

export default Footer;

const Wrapper = styled.div`
  font-size: 10px;
  text-align: center;
  color: white;
  padding: 5px;
  position: fixed;
  right: 0px;
  bottom:0;
  z-index: 999;
  `;
