import { deleteToken, setToken } from '../../services/TokenServce';
import { AuthService } from "../../services/AuthService";
import { clearContent, getTags, getPlaybooks } from "./content";
import { UserService } from "../../services/UserService";
import { PlaybooksService } from "../../services/PlaybooksService";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";

const requestLogin = () => {
    return {
        type: LOGIN_REQUEST,
    };
};

const receiveLogin = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    };
};

const loginError = () => {
    return {
        type: LOGIN_FAILURE,
    };
};

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST,
    };
};

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS,
    };
};

// eslint-disable-next-line no-unused-vars
const logoutError = () => {
    return {
        type: LOGOUT_FAILURE,
    };
};

const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST,
    };
};

const verifySuccess = user => {
    return {
        type: VERIFY_SUCCESS,
        payload: user,
    };
};

const verifyError = () => {
    return {
        type: VERIFY_ERROR,
    };
};

export const loginUser = us => async dispatch => {
    dispatch(requestLogin());
    try {
        const { statusCode, data } = await AuthService.login(us)
        if(statusCode !== 200){
            dispatch(verifyError())
        }
        const { isActive, token } = data
        setToken(token)
        if (isActive) {
            const tags = await UserService.getTags()
            const playbooks = await PlaybooksService.getPlaybooks()
            dispatch(getPlaybooks(playbooks))
            dispatch(getTags(tags))
            dispatch(receiveLogin(data))
            return
        } else {
            dispatch(loginError())
        }
    } catch (e) {
        dispatch(loginError())
    }
};

export const verifyUser = () => async dispatch => {
    dispatch(verifyRequest())
    try {
        const { statusCode, data } = await AuthService.verify()
        if(statusCode !== 200){
            dispatch(verifyError())
            return
        }
        const tags = await UserService.getTags()
        const playbooks = await PlaybooksService.getPlaybooks()
        dispatch(getPlaybooks(playbooks))
        dispatch(getTags(tags))
        dispatch(verifySuccess(data))
    }catch (e) {
        dispatch(verifyError())
    }
};

export const logout = () => dispatch => {
    dispatch(requestLogout())
    dispatch(clearContent())
    deleteToken()
    dispatch(receiveLogout())
};
