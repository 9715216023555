import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import {
  // DndProvider,
  useDrag,
  useDrop,
} from "react-dnd";
import { PlayLoader } from "./blocks/PlayBlock";
import update from "immutability-helper";
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "./atoms/Loader";

const ItemTypes = {
  CARD: 'card',
}

const ListElement = ({ id, index, item, renderItem, moveCard }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if(hoverBoundingRect.top > (window.innerHeight * 0.7)){
        console.log('SCROLL BOTTOM')
        window.scrollBy(0, 10)
        return;
      }

      if(hoverBoundingRect.top < (window.innerHeight * 0.3)){
        console.log('SCROLL TOP')
        window.scrollBy(0, -10)
        return;
      }

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));


  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {renderItem(item, index)}
    </div>
  )
}

const DragAndDropGrid = ({
                           items,
                           setItems = () => {},
                           onMove = () => {},
                           loadMoreItems,
                           hasMorePages,
                           renderItem,
                           mainButton,
                           children,
                           loading,
                         }) => {
  const timeoutId = useRef()

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    const dragCard = items[dragIndex]
    // const hoverCard = items[hoverIndex]
    const newData = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    })
    setItems(newData);

    timeoutId.current = setTimeout(async () => {
      await onMove({
        id: dragCard.id,
        order: hoverIndex + 1,
      })
    }, 2500)
  }, [items, onMove]);

  const renderCard = (item, index) => {
    return <ListElement
      key={item.id}
      index={index}
      id={item.id}
      item={item}
      renderItem={renderItem}
      moveCard={moveCard}
    />
  };

  if (loading && items.length === 0) {
    return <Wrapper>
      {mainButton}
      <PlayLoader/>
      <PlayLoader/>
    </Wrapper>
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={!loading ? loadMoreItems : () => {}}
        // loadMore={() => {}}
        hasMore={hasMorePages}
        // hasMore={false}
        loader={<Loader/>}
      >
        <Wrapper>
          {mainButton}
          {items?.map((item, i) => renderCard(item, i))}
        </Wrapper>
      </InfiniteScroll>
      {children}
    </>
  );
}

export default DragAndDropGrid;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
