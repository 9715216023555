import { angle, getCoordinates, getSectionLength } from "./LineHelper";
import { getLastPoints, quarterCheck } from "./arcHelper";

export const baseSpeed = 200; //units per second

export const getLongestAnimation = players => {

    if( typeof players === "undefined" ) {
        return false;
    }

    const points = players?.map(el => {
        if (el.line_tension < 1) {

            return el.line_points
        } else {
            return getArcAnimationPoints(el.line_points)
        }
    });
    let finalDuration = 0;
    let squiggly = 0;

    points?.forEach((el, id) => {
        const fullLength = el.length > 0 ? getFullLength(el) : 0;

        const duration = fullLength / baseSpeed;
        if (duration > finalDuration) {


            finalDuration = duration;

        }
        if (players[id].line_dash.includes('wiggly')) {
            squiggly = finalDuration === duration ? 1 : 2;
        }
    })

    if (squiggly > 1) {
        return finalDuration + searchForSquigglyLine(players);
    }
    return finalDuration;
}

export const getAnimationDuration = (points, text = false) => {
    const length = getSectionLength(points[0], points[1], points[2], points[3]);
    return (length / (baseSpeed * (text ? 1 : 1)));
}


export const checkIfMoving = player => (player.line_points.length > 2);

export const shouldReset = (position, player, points, startPoints) => (position.x !== player.x && position.y !== player.y && points.length === startPoints.length)

export const isStartingPoint = (player, position) => (position.x === player.x && position.y === player.y)

export const movePlayer = (movingPart, duration, x, y) => {
    movingPart.current.to({
        x: x,
        y: y,
        duration: duration,
    })
}
export const resetPlayer = (movingPart, player) => {
    movingPart.current.to({
        x: player.x,
        y: player.y,
        duration: 0,
    })
}


export const getFullLength = points => {
    const tableSize = points.length;
    let fullLength = 0;
    for (let i = 0; i < tableSize - 2; i += 2) {

        fullLength = fullLength + getSectionLength(points[i], points[i + 1], points[i + 2], points[i + 3])

    }
    return fullLength
}

const getPointsOnArc = ({ center, tangentPoints, radius, arcLength }, points, text = false) => {
    let temp = [tangentPoints[0], tangentPoints[1]];
    const num = radius > 200 ? text ? 5 : 15 : text ? 4 : 10;
    const tempAngle = arcLength > 0 ? arcLength : 360 + arcLength;
    const sectionAngle = Math.abs((tempAngle) / num);
    const startAngle = angle(null, [...center, ...temp]);
    for (let i = 1; i < num; i++) {
        const tempAngle = quarterCheck(points) ? startAngle + (i * sectionAngle) : startAngle - (i * sectionAngle);
        const newPoints = getCoordinates(center[0], center[1], radius, tempAngle);
        temp = [...temp, ...newPoints];
    }

    return [...temp, tangentPoints[2], tangentPoints[3]];


}

export const getArcAnimationPoints = (points, text = false) => {
    const numOfArcs = (points.length / 2) - 2;
    let returnTable = [points[0], points[1]];
    for (let i = 0; i < numOfArcs; i++) {
        const neededPoints = [points[i * 2], points[i * 2 + 1], points[i * 2 + 2], points[i * 2 + 3], points[i * 2 + 4], points[i * 2 + 5]]
        const arcInfo = getLastPoints(neededPoints, true)
        returnTable = [...returnTable, ...getPointsOnArc(arcInfo, neededPoints, text)];
    }
    returnTable = [...returnTable, points[points.length - 2], points[points.length - 1]];
    return returnTable
}

export const searchForSquigglyLine = players => {
    let duration = 0;
    players?.forEach(el => {
        if (el.line_dash.includes('wiggly')) {
            const temp = getAnimationDuration(el.line_points)
            duration = temp > duration ? temp : duration;
        }
    })
    return duration
}

export const getWaitingDuration = (points, fullTime, delay) => {
    return fullTime - (getFullLength(points) / baseSpeed) - delay
}
