import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

// import { verifyAuth } from "./redux/actions/";
import rootReducer from "./redux/reducers";
import { verifyUser } from "./redux/actions";

export default function configureStore(persistedState) {
    let store;

    if(process.env.REACT_APP_ENVIRONMENT === 'develop'){
        /* eslint-disable no-underscore-dangle */
        store = createStore(rootReducer, persistedState, /* preloadedState, */ compose(
            applyMiddleware(thunkMiddleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        ));
        /* eslint-enable */
    }else {
        store = createStore(rootReducer, persistedState, compose(
            applyMiddleware(thunkMiddleware),
        ));
    }

    store.dispatch(verifyUser());
    return store;
}