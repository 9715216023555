import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import Portal from "./Portal";

const Modal = ({ show, setShow, children, type }) => {

    const handleCloseModal = useCallback(() => {
        setShow(false)
    }, [setShow])

    return (
        <Portal id="root-modal">
            {
                show &&
                <>
                    <Background onClick={handleCloseModal}/>
                    <Content type={type} className="hide-scrollbar">
                        {children}
                    </Content>
                </>
            }
        </Portal>
    );
}

export default Modal;

const Content = styled.div`
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.third};
  width: 90%;
  max-width: ${({ maxWidth }) => maxWidth || '500px'};
  overflow: scroll;
  
  ${({ theme, type }) => type === "side" && css`
    right: 0px;
    left: auto;
    height: calc(100% - 30px);
    top: 30px;
    transform: translate(0);
    border-radius: 10px 0 0 0;
    box-shadow: 0px 0px 20px -10px ${({ theme }) => theme.color.black};
    
    @media (max-width: 800px){
      width: 100%; 
    }
  `}
`;

const Background = styled.div`
  display: block;
  z-index: 999;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(32, 32, 32, 0.7);
`;
