import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from "../components/molecules/Modal";
import { useDispatch, useSelector } from "react-redux";
import { clearPrint } from "../redux/actions";
import { PrintModal } from "../pages/Print/PrintModal";
import { PrintComponent } from "../pages/Print/PrintComponent";
import { PlaysService } from "../services/PlaysService";

const PrintProvider = ({ children }) => {
    const dispatch = useDispatch();
    const activeFilters = useSelector(store => store.content.activeFilters );
    const print = useSelector(store => store.print);

    const [playsToPrint, setPlaysToPrint] = useState([]);
    const [optionalPlays, setOptionalPlays] = useState([])

    const [printReady, setPrintReady] = useState(false);
    const [playbook, setPlaybook] = useState(false);


    const [options, setOptions] = useState({
        printType: 'wristband',
        width: 3,
        height: 2,
        copies: 1,
        diagram: true,
        numOfPlays: [2, 0],
        zoom: 1,
        moreInfo: ['title'],
    })


    const printRef = useRef();

    const handleClose = useCallback(() => {
        dispatch(clearPrint());
        setPlaysToPrint([]);
        setPlaybook(false)
        setOptionalPlays([]);
    }, []);

    const addPlay = id => {
        let newPlay;

        optionalPlays.forEach(play => {
            if (play.id === id) {
                newPlay = play
            }
        })

        const tempOptions = checkIfOptionsShouldChange(options, playsToPrint.length + 1);

        setOptions({ ...tempOptions });

        const tempTable = [...playsToPrint, newPlay];

        setPlaysToPrint(tempTable)

    }

    const removePlay = id => {
        const newPlays = playsToPrint.filter(play => play.id !== id);

        const tempOptions = checkIfOptionsShouldChange(options, newPlays.length);

        setOptions({ ...tempOptions })

        setPlaysToPrint(newPlays)
    }

    const addAll = (e, remove = false) => {
        const tempOptions = checkIfOptionsShouldChange(options, optionalPlays.length);

        setOptions({ ...tempOptions })
        if(remove){
            setPlaysToPrint([])
        }else{
            setPlaysToPrint(optionalPlays)
        }

    };

    useEffect(async () => {
        if (print !== null) {
            let table;


            if (print.type === 'play') {

                table = [print.play];
                setPlaysToPrint(table);


                const pathArray = window.location.pathname.split('/');

                if (pathArray.includes('playbooks')) {
                    const optTable = await PlaysService.getPlays(activeFilters.page, activeFilters.predefined, activeFilters.search, activeFilters.tags, print.play.playbook_id );
                    setOptionalPlays(optTable?.items)
                } else if (pathArray.includes('plays')) {
                    const optTable = await PlaysService.getPlays(activeFilters.page, activeFilters.predefined, activeFilters.search, activeFilters.tags );
                    setOptionalPlays(optTable?.items)
                }else if(!pathArray.includes('app')){
                    const optTable = await PlaysService.getPlays(activeFilters.page, true, activeFilters.search, activeFilters.tags );
                    setOptionalPlays(optTable?.items)
                }


            } else if (print.type === 'playbook') {
                const optTable = await PlaysService.getPlays(activeFilters.page, activeFilters.predefined, activeFilters.search, activeFilters.tags, print.playbook.id ) || [];
                setOptionalPlays(optTable?.items);
                setPlaysToPrint(optTable?.items);
            }

            if (print.type === 'playbook') {
                setPlaybook(print.playbook)
            } else if (options.numOfPlays[0] !== 1 && options.numOfPlays[1] !== 1 && print.type === 'play') {
                setOptions({
                    ...options,
                    numOfPlays: [1, 1],
                })
            }

        }

    }, [print]);

    useEffect(() => {

        if (playsToPrint.length !== 0 ) {

            setPrintReady(true)

        }
    }, [playsToPrint])

    return (
        <>
            {children}
            {print !== null &&
            <>
                <Modal show={print !== null} setShow={handleClose} type="side" maxWidth="500px">
                    <PrintModal printRef={printRef} setPrintReady={setPrintReady} printReady={playsToPrint.length !== 0}
                                close={handleClose} options={options} setOptions={setOptions} plays={playsToPrint}
                                addAll={addAll}
                                addPlay={addPlay} removePlay={removePlay} optionalPlays={optionalPlays}
                                playCount={playsToPrint?.length}/>
                </Modal>
                {(options !== null && printReady && typeof options.zoom !== "undefined") &&
                <PrintComponent plays={playsToPrint} options={options} printRef={printRef} playbook={playbook}/>}
            </>
            }
        </>
    );

};
export default PrintProvider;


function checkIfOptionsShouldChange(options, playCount) {
    const newOptions = { ...options };
    const checkOnePlay = (options.numOfPlays[0] === 1 && options.numOfPlays[1] === 1)

    if ((playCount > 1 && !checkOnePlay) || (playCount < 2 && checkOnePlay)) {
        return options
    }
    if (checkOnePlay && playCount > 1) {
        if (options.diagram) {
            newOptions.numOfPlays = options.printType === 'wristband' ? [2, 0] : [4, 6];
        } else {
            newOptions.numOfPlays = [1, 0];
        }
        return newOptions
    }
    if (!checkOnePlay && playCount < 2) {
        newOptions.numOfPlays = [1, 1];
        return newOptions
    }


}
