import axios from "axios";
import qs from 'qs'
import { store } from '../App';
import { logout } from '../redux/actions';
import { getToken } from './TokenServce';
import { getId } from "../utils/jwtHelpers";

export const API_URL = process.env.REACT_APP_API_URL

export const APIService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    transformRequest: data => JSON.stringify(data),
    paramsSerializer: params =>
        qs.stringify(params, {
            arrayFormat: 'brackets',
        }),
})

APIService.interceptors.request.use(
    async config => {
        const token = await getToken()
        config.baseURL = process.env.REACT_APP_API_URL
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        if (config.url.includes(':userId') && token) {
            const id = getId(token)
            config.url = config.url.replace(':userId', id)
        }
        return config
    },
    error => {
        // eslint-disable-next-line no-console
        console.log(error.data)
      // eslint-disable-next-line no-undef
        Promise.reject(error)
    },
)

APIService.interceptors.response.use(
    async response => {
        return response.data
    },
    async error => {
        const err = error.toJSON()
        const sholudLogOut = (err?.message.includes('403') || err?.message.includes('401'))

        if (sholudLogOut) {
            store.dispatch(logout())
        }

      // eslint-disable-next-line no-undef
        return Promise.reject(error)
    },
)