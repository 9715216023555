import React from 'react';
import styled from 'styled-components'
import { Alert as MAlert } from '@material-ui/lab';

export default function Alert({ type = "error", children, margin }) {
    return (
        <Wrapper margin={margin}>
            <MAlert severity={type}>{children}</MAlert>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin: ${({ margin }) => margin || ' 0 0 10px'};
  .MuiPaper-root{
      align-items: center;
  }  
  .MuiAlert-message {
    color: #F8563F !important;
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.S};
  }
`;