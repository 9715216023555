import { createTheme } from "@material-ui/core";


export const FfbTheme = createTheme({
    palette: {
        primary: {
            main: '#FFDB49',
        },
        secondary: {
            main:'#ffffff',
        },
    },

    overrides:{
      MuiSlider: {
        thumb:{
        marginTop: '-3px',
        },
        track: {
          height: 6,
          borderRadius: 4,


        },
        rail: {
          color: '#404040',
          height: 6,
          borderRadius: 4,


        },
        
      },
  },

})