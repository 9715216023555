import { baseCanvasHeight, baseCanvasWidth, canvasWithHeightRatio } from "../../constants";

export const baseLineConfig = {
    dash: 'solid',
    endpoint: 'arrow',
}

export const createPreviewLines = (players, activePlayer, x, y, config = baseLineConfig) => {
    return players.map(player => {
        if (player.id === activePlayer) {
            if (player.line_points[0] !== player.line_points[2] || player.line_points[1] !== player.line_points[3]) {
                player.line_points = [...player.line_points, x, y]
                player.line_dash = [...player.line_dash, config.dash]
                player.line_endpoint = [...player.line_endpoint, player.line_endpoint[player.line_endpoint.length - 1]]
                player.line_endpoint[player.line_endpoint.length - 2] = 'none'

            } else {
                player.line_points = [player.x, player.y, x, y]
                player.line_dash = [config.dash]
                player.line_endpoint = [config.endpoint]
            }
            return player
        }
        return player
    })
}

export const movePreviewLine = (players, activePlayer, x, y) => {
    return players.map(player => {
        if (player.id === activePlayer) {
            player.line_points = player.line_points.map((point, index) => {
                if (index === player.line_points.length - 2) {
                    return x
                } else if (index === player.line_points.length - 1) {
                    return y
                }
                return point
            })

            return player
        }
        return player
    })
}

export const editPlay = (play, setting, value = '') => {
    switch (setting) {
        case 'playTitle':
            return {
                ...play,
                title: value,
            }

        case 'fieldSize':
            return {
                ...play,
                fieldSize: value,
            }
        case 'fieldLines':
            return {
                ...play,
                fieldLines: value,
            }
        case 'DM':
            return {
                ...play,
                darkMode: !play.darkMode,
            }
        case 'GL':
            return {
                ...play,
                baseLineVisible: !play.baseLineVisible,
            }
        case 'coachingPoints':
            return {
                ...play,
                coaching_points: value,
            }
        case 'categoryAdd':
            return {
                ...play,
                categories: [...play.categories, value.toString()],
            }
        case 'categoryRemove':
            return {
                ...play,
                categories: play.categories.filter(cat => cat !== value.toString()),
            }
        default:
            return play
    }
}
export const snapToGrid = (jump, xy) => {
    const rest = xy % jump;
    const diff = xy - rest;
    return rest >= jump / 2 ? diff + jump : diff;

}

export const playerPositionChange = (players, id, ex, ey) => {
    const x = snapToGrid(5, ex);
    const y = snapToGrid(5, ey);

    return players.map(player => {


        if (id === player.id && player.line_points.length >= 4) {

            const dx = x - player.x;
            const dy = y - player.y;

            player.line_points = player.line_points.map((point, index) => {
                if (index % 2) {
                    return point + dy
                } else {
                    return point + dx
                }
            });
            player.x = x;
            player.y = y;

            return player
        } else if (id === player.id && player.line_points.length < 2) {
            player.x = x;
            player.y = y;
            return player
        } else {
            return player
        }
    })
}
export const playerAttributesChange = (players, activePlayer, value, activeLine, setting) => {
    return players.map(player => {

        if (player.id === activePlayer) {
            switch (setting) {
                case 'color':
                    if(player.stroke_fill === "6949FF" || player.stroke_fill === player.color){
                        player.stroke_fill = value;
                    }
                    player.color = value;
                    return player

                case 'dashed':
                    if (activeLine !== null) {
                        player.line_dash = player.line_dash.map((dash, index) => {
                            if (index === activeLine) {
                                return value
                            } else {
                                return dash
                            }
                        })

                    } else {
                        const id = value==='wiggly' ? 0 : player.line_dash.length - 1
                        player.line_dash[id] = value;
                    }

                    return player
                case 'tension':
                    player.line_tension = value;
                    return player
                case 'shape':
                    player.shape = value;
                    return player

                case 'endpoint':
                    if (activeLine !== null) {

                        player.line_endpoint = player.line_endpoint.map((endpoint, index) => {
                            if (index === activeLine) {

                                return value === endpoint ? 'none' : value;
                            } else {
                                return endpoint
                            }
                        })

                    } else {
                        player.line_endpoint[player.line_endpoint.length - 1] = value;
                    }
                    return player
                case 'undo':

                    if (player.line_points.length > 4) {
                        player.line_points = player.line_points.filter((point, index) => index < (player.line_points.length - 2))
                        player.line_dash = player.line_dash.filter((dash, index) => index < (player.line_dash.length - 1));
                        player.line_endpoint[player.line_endpoint.length - 2] = player.line_endpoint[player.line_endpoint.length - 1]
                        player.line_endpoint = player.line_endpoint.filter((endpoint, index) => index < (player.line_endpoint.length - 1));
                    } else {
                        player.line_points = [player.x, player.y, player.x, player.y];
                        player.line_endpoint = ['none'];
                        player.line_dash = ['solid'];
                    }
                    return player
                case 'size':
                    player.size = value
                    return player
                case 'width':
                    player.width = value
                    return player
                case 'height':
                    player.height = value
                    return player
                case 'thickness':
                    player.line_width = value
                    return player
                case 'label':
                    if (player.shape === 'text') {
                        player.label = value.length > 28 ? player.label : value
                    } else {
                        player.label = value.length > 1 ? value[0] + value[1] : value
                    }
                    return player
                case 'format':
                    player.label_format = value;
                    return player
                case 'labelFill':
                    player.label_fill = value
                    return player
                case 'strokeFill':
                    player.stroke_fill = value;
                    return player
                case 'borderFill':
                    player.border_fill = value;
                    return player
                case "shading":
                    player.shading = value
                    return player

                default:
                    return player
            }

        }
        return player
    })
}

export const downloadURI = (uri, name) => {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const setPrint = (setting, value, options) => {
    const newOptions = { ...options };
    if (setting === 'printType' && options.numOfPlays[0] !== 1 && options.numOfPlays[1] !== 1) {
        newOptions.diagram = true;
        newOptions.numOfPlays = value === 'wristband' ? [2, 0] : [1, 2];
    }
    if (setting === 'view') {
        newOptions['diagram'] = value[0];
        newOptions['numOfPlays'] = value[1];
    } else if (setting === 'info') {
        if (value === 'none') {
            newOptions.moreInfo = [];
        } else if (newOptions.moreInfo.includes(value)) {
            newOptions.moreInfo = newOptions.moreInfo.filter(option => option !== value);
        } else {
            newOptions.moreInfo = [...newOptions.moreInfo, value]
        }
    } else {
        newOptions[setting] = value;
    }
    return newOptions
}


export const transformToLocal = (player, width) => {
    const xRatio = width/baseCanvasWidth;
    const yRatio = (width*canvasWithHeightRatio)/baseCanvasHeight;
    const x = player.x * xRatio;
    const y = player.y * yRatio;
    const lineTable = player.line_points.map((coord, index)=>{

        return index%2 ? coord * yRatio : coord * xRatio;
    })
    return {
        ...player,
        x: x,
        y: y,
        line_points: lineTable,
    }
}

export const transformToGlobal = (coords, width) => {
    const xRatio = width/baseCanvasWidth;
    const yRatio = (width*canvasWithHeightRatio)/baseCanvasHeight;

    return [coords[0]/xRatio, coords[1]/yRatio];

}

export const transformToPrint =  (player, width = 600) =>{


    const lineTable = player.line_points.map((coord, index)=>{

        return index%2 ? coord : moveCloserToCenter(coord, width);
    })


    return {
        ...player,
        x: moveCloserToCenter(player.x, width),
        line_points: lineTable,
    }
}

export const moveCloserToCenter = (x, width= 600) =>{
    const ratio = 0.4;
    const delta = x - (width/2);
    const newDelta = delta * ratio;
    return x - newDelta;
}
