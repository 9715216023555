import React from "react";
import { Path } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const Ball = ({
                         player,
                         handleClickActivate,
                         activeTopSetting,
                         handlePlayerDrag,
                         editable,
                         handleTarget,
                         enableDraw,
                         ratio,
                         movingPart,
                     }) => {

    return (

        <Path width={basePlayerSize * player.size * ratio}
              height={basePlayerSize * player.size * ratio}
              y={player.y}
              x={player.x}
              data="M58.7879 13.5433C58.7629 11.1007 58.4347 5.16034 55.9317 2.75746C53.2977 0.228814 45.8309 -0.68441 37.7735 0.536991C32.0472 1.40487 21.1634 4.13843 12.6091 12.6943C4.07513 21.2297 1.37434 32.0658 0.524031 37.7653C-0.654133 45.6616 0.202755 53.1396 2.60776 55.9509C4.69911 58.3955 9.63579 58.9402 12.3922 59.0472C12.8494 59.065 13.3181 59.074 13.7993 59.074C23.0171 59.074 36.4484 55.7376 46.0468 46.1376C55.589 36.5938 58.8851 23.0428 58.7879 13.5433Z"
              fill="#524100"
              onMouseEnter={() => enableDraw(false)}
              onMouseLeave={() => enableDraw(true)}
              offsetX={basePlayerSize * player.size * ratio / 2}
              offsetY={basePlayerSize * player.size * ratio / 2}
              draggable={activeTopSetting !== 'DL' && editable}
              onClick={e => handleClickActivate(e)}
              onTouchEnd={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}
              ref={movingPart}
              anchor={player.id}
        />

    )
}
