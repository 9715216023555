import React from 'react';
import styled from 'styled-components';

import LOGO from "../../assets/images/spinner.gif";

const Loader = () => {

    return (
        <Wrapper>
            <Image src={LOGO} />
        </Wrapper>
    );
}

export default Loader;

const Image = styled.img`
  height: 60px;
  `;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
