export function sortByOrder(a, b) {
  if (parseInt(a.order) < parseInt(b.order)) {
    return -1;
  }
  if (parseInt(a.order) > parseInt(b.order)) {
    return 1;
  }
  return 0;
}

export function sortByPredefinedOrder(a, b) {
  if (parseInt(a.order_predefined) < parseInt(b.order_predefined)) {
    return -1;
  }
  if (parseInt(a.order_predefined) > parseInt(b.order_predefined)) {
    return 1;
  }
  return 0;
}

export function sortByPlaybookOrder(a, b) {
  if (parseInt(a.order_playbook) < parseInt(b.order_playbook)) {
    return -1;
  }
  if (parseInt(a.order_playbook) > parseInt(b.order_playbook)) {
    return 1;
  }
  return 0;
}

export function mapOrder(el, index) {
  return {
    ...el,
    order: index + 1,
  }
}

export function mapPredefinedOrder(el, index) {
  return {
    ...el,
    order_predefined: index + 1,
  }
}
