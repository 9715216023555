import React from "react";
import { Star } from "react-konva";
import { basePlayerSize } from "../../../constants";


export const CrossPlayer = ({
                                player,
                                handleClickActivate,
                                activeTopSetting,
                                handlePlayerDrag,
                                editable,
                                handleTarget,
                                enableDraw,
                                ratio,
                                movingPart,
                            }) => {


    return (

        <Star numPoints={4}
              innerRadius={1}
              outerRadius={basePlayerSize * player.size * ratio / 1.5}
              strokeWidth={10 * player.size * ratio}
              lineJoin={'round'}
              lineCap={'round'}
              y={player.y}
              x={player.x}
              fill={player.shading === 'full' ? player.color : 'white'}
              stroke={player.color}
              draggable={activeTopSetting !== 'DL' && editable}
              onMouseEnter={() => enableDraw(false)}
              onMouseLeave={() => enableDraw(true)}
              onClick={e => handleClickActivate(e)}
              onTouchEnd={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}
              ref={movingPart}
              rotation={45}
              anchor={player.id}/>


    )
}
