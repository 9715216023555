import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayBlock, { PlayButton } from "../../../../components/blocks/PlayBlock";
import { PlaysService } from "../../../../services/PlaysService";
import { mapOrder, sortByOrder } from "../../../../utils/arrayHelpers";
import AddPlayModal from "../../../../components/modals/AddPlayModal";
import EditPlayModal from "../../../../components/modals/EditPlayModal";
import { useFilters } from "../../../../hooks/useFilters";
import { useLoading } from "../../../../hooks/useLoading";
import Dnd from "../../../../components/dnd/dnd";

const PlaysList = () => {
    const { search, tags } = useFilters()

    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)

    const { setLoading : setGlobalLoading } = useLoading()
    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [hasMorePages, setHasMorePages] = useState(false)

    const [tmp, setTmp] = useState()

    const handleShowModal = useCallback(() => {
        setModal('add-play')
    }, [])

    const loadItems = useCallback(async page => {
        try {
            setLoading(true)
            const { items, page_count } = await PlaysService.getPlays(page, false, search, tags);
            const arr = items;

            setData(page > 1 ? [...data, ...arr] : arr)
            setCurrentPage(page)
            setHasMorePages(page < page_count)
        }catch (e){
            console.log('[getPlays API]: ', e)
        }finally {
            setLoading(false)
        }
    }, [data, search, tags])

    const loadMoreItems = useCallback(async () => {
        const page = currentPage + 1
        loadItems(page)
    }, [loadItems, currentPage])

    useEffect(() => {
        setData([])
        loadItems(1)
    }, [search, tags])

    const handleDeletePlay = useCallback(async item => {
        try {
            setGlobalLoading(true)
            await PlaysService.deletePlay(item.id)
            loadItems(1)
        }catch (e){
            console.log("Error [deletePlay]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [currentPage])

    const handleDuplicatePlay = useCallback(async ( item, index ) => {
        try {
            setGlobalLoading(true)
            const duplicated = await PlaysService.duplicatePlay(item.id);
            data.splice(1 + index, 0, duplicated );

            setData(data);
        }catch (e){
            // eslint-disable-next-line no-console
            console.log("Error [duplicatePlay]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [data])

    const handleEditPlay = useCallback(item => {
        setModal('edit-play')
        setTmp(item)
    }, [])

    const handleOnMove = useCallback(async newData => {
        try {
            setGlobalLoading(true)
            await PlaysService.updatePlaysOrder(newData)
        }catch (e){
            console.log("Error [updatePlaysOrder]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [currentPage])

    const renderItem = useCallback((item, index) => {
        return <PlayBlock
            key={item.id}
            index={index}
            order_id={index}
            item={item}
            onEdit={handleEditPlay}
            onDuplicate={handleDuplicatePlay}
            onDelete={handleDeletePlay}
        />
    }, [handleDuplicatePlay, handleDeletePlay, handleEditPlay])

    return (
        <>
            <Wrapper>
                <AddPlayModal
                    show={modal === 'add-play'}
                    setShow={setModal}
                    refresh={() => loadItems(1)}
                    predefined={false}
                />
                <EditPlayModal
                    show={modal === 'edit-play'}
                    setShow={setModal}
                    data={tmp}
                    refresh={() => loadItems(1)}
                    predefined={false}
                />

                { data && ( <Dnd
                    items={data}
                    setItems={setData}
                    renderItem={renderItem}
                    onMove={handleOnMove}
                    mainButton={<PlayButton onClick={handleShowModal}/>}
                /> ) }
            </Wrapper>
        </>
    );
}

export default PlaysList;

const Wrapper = styled.div`

`;
