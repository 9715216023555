import React from "react";
import { Grid } from "@material-ui/core";

import { SectionHeader } from "./SectionParts/SectionHeader";
import { SettingButton } from "./SectionParts/SettingButton";
import { ColorPicker } from "./SectionParts/ColorPicker";
import { StyledToolsContainer } from "../StyledComponents/StyledComponents";

import Blank from "../svg/shadings/Blank.svg";
import Full from "../svg/shadings/Full.svg";
import HR from "../svg/shadings/HR.svg";
import HL from "../svg/shadings/HL.svg";
import HB from "../svg/shadings/HB.svg";
import HT from "../svg/shadings/HT.svg";


export const PlayerFill = ({ player, settingChange, open, setOpen }) => {

    const nonShade = ['cross', 'flag', 'asterisk']
    const shadeTable = ['full', 'HL', 'HR', 'HB', 'HT', 'blank'];
    const iconTable = [Full, HR, HL, HB, HT, Blank]

    return (
        <StyledToolsContainer>
            <Grid container  spacing={0} >

              {player.shape === 'text' && <SectionHeader
                    text={player.shape !== 'text' ? `${player.player ? 'PLAYER' : 'SHAPE'} FILL` : 'BACKGROUND'}
                    setOpen={setOpen} open={open} sectionName={'fill'}/>}

                {<>
                    <Grid container  spacing={0} >
                    <ColorPicker player={player} settingChange={settingChange}/>
                    </Grid>

                    <Grid container style={{ marginTop: '.5rem' }} spacing={0} >

                    {(!nonShade.includes(player.shape)) && <>
                   
                        {shadeTable.map((shade, index) => {
                            if (shade !== 'full' && shade !== 'blank' && player.shape === 'text') {
                                return null
                            }
                            return  <SettingButton key={shade} active={player.shading === shade}
                                                  settingChange={settingChange} value={shade}
                                                  setting={'shading'} icon={iconTable[index]}
                                                  xs={player.shape !== 'text' ? 2 : 6} title={shade}/>
                        })}

                    </>}
                      </Grid>
                </>}

            </Grid>
        </StyledToolsContainer>
    )
}