import { APIService } from "./APIService";

const login = user => APIService.post('/jwt-auth/v1/token', user)

const verify = () => APIService.post('/jwt-auth/v1/token/validate')

export const AuthService = {
    login,
    verify,
}
