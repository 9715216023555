import { Line, Rect } from "react-konva";
import { angle } from "../../Helpers/LineHelper";
import React from "react";

export const ActiveLine = ({ player, points }) => {


    return (
        <>
            <Line points={points}
                  stroke={'red'}
                  strokeWidth={1}
                  lineCap={"butt"}

            />
            <Rect width={8}
                  height={8}
                  offsetX={4}
                  offsetY={4}
                  y={points[points.length - 1]}
                  x={points[points.length - 2]}
                  stroke={'red'}
                  strokeWidth={2}
                  rotation={angle(player, points)}
                  fill={'white'}/>
            <Rect width={8}
                  height={8}
                  offsetX={4}
                  offsetY={4}
                  y={points[1]}
                  x={points[0]}
                  stroke={'red'}
                  strokeWidth={2}
                  rotation={angle(player, points)}
                  fill={'white'}/>
        </>
    )
}