import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Text16, Text22 } from "../../components/atoms/Typography";
import TICK from "../../assets/icons/tick.svg";


export const PlaysSelection = ({ plays, optPlays, add, remove, all }) => {

    const idTable = plays.map(play => play.id);

    return (<>
            <Text22 margin="3rem 0 2rem">Choose Plays to print</Text22>
            <Wrapper>
                <Content className='hide-scrollbar'>
                    <SinglePlay play={{ title: 'add all to print' }} add={all} remove={e =>all(e, true)} checked={idTable.length === optPlays.length}/>
                </Content>
            </Wrapper>
            <Wrapper>
                <Label>Plays</Label>
                <Content className='hide-scrollbar'>
                    {optPlays.map(play => <SinglePlay key={play.id} play={play} checked={idTable.includes(play.id)}
                                                      add={() => add(play.id)} remove={() => remove(play.id)}/>)}

                </Content>
            </Wrapper>
        </>
    )
}

const SinglePlay = ({ play, checked, add, remove }) => {

    const [hover, setHover] = useState(false);


    return (
        <PlayOption onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                    onClick={checked ? remove : add}>
            <Tick src={TICK} checked={checked}/>
            {!hover ? <Text16>{play.title}</Text16> :
                <Text16 style={{ color: '#FFDB49', fontWeight: 700 }}>{play.title}</Text16>}


        </PlayOption>
    )
}


const Wrapper = styled.div`
  margin: ${({ margin }) => margin || "0 0 20px"};

`;

const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.white};
  margin: 0 0 10px;
`;

const Content = styled.div`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
`;


const Option = styled.div`
  flex: 1 1;
  padding: 12px 20px;
  display: flex;
  //justify-content: center;
  flex-basis: ${props => props.width || '50%'};
  align-items: center;
  background: ${({ theme }) => theme.color.blue};
  cursor: pointer;

  ${({ active }) => active && css`
    background: ${({ theme }) => theme.color.primary};

    * {
      color: ${({ theme }) => theme.color.secondary};
    }
  `}

`;

const PlayOption = styled(Option)`
  margin-bottom: 2px;
`
const Tick = styled.img`
  opacity: ${props => props.checked ? 1 : 0};
  height: 10px;
  margin-right: 10px;
`;
