import React, { useEffect, useState } from "react";
import { Text16, Text18, Text22 } from "../../components/atoms/Typography";
import Button from "../../components/atoms/Button";
import Radio from "../../components/atoms/Radio";
import None from "../PlayMaker/Components/svg/None.svg";
import styled, { css } from "styled-components";
import { setPrint } from "../PlayMaker/Components/Helpers/DrawHelper";
import ReactToPrint from "react-to-print";
import { MuiThemeProvider, Slider, Select, MenuItem, withStyles } from "@material-ui/core";
import { FfbTheme } from "../../styles/theme";
import { PlaysSelection } from "./PlaysSelection";
import Loader from "../../components/atoms/Loader";


const StyledSelect = withStyles({
    selectMenu: {
         backgroundColor: '#ffffff',
         padding: '10px',
        '&:focus': {
            backgroundColor: '#ffffff',
        },
    },
    root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ffffff',
        },
    },
})(Select);

const StyledMenuItem = withStyles({
    root: {
        backgroundColor: '#ffffff',
        '&$selected': {
            backgroundColor: '#e2e2e2',
            '&:hover': {
                backgroundColor: '#e2e2e2',
            },
        },
        '&:hover': {
            backgroundColor: '#e2e2e2',
        },
    },
    selected: {},
})(MenuItem);

export const PrintModal = ({
                               printRef,
                               setPrintReady,
                               close,
                               options,
                               setOptions,
                               printReady,
                               playCount,
                               optionalPlays,
                               plays,
                               addPlay,
                               removePlay,
                               addAll,
                           }) => {

    const [page, setPage] = useState('type');

    const [ready, setReady] = useState(false)

    const widthOptions = [['3"', 3], ['3.25"', 3.25], ['3.50"', 3.5], ['3.75"', 3.75], ['4"', 4]]
    const width2Options = [['4.25"', 4.25], ['4.50"', 4.5], ['4.75"', 4.75], ['5"', 5]];

    const heightOptions = [['2"', 2], ['2.25"', 2.25], ['2.50"', 2.5], ['2.75"', 2.75], ['3"', 3]];

    const width = [['3"', 3], ['3.25"', 3.25], ['3.50"', 3.5], ['3.75"', 3.75], ['4"', 4]]
    const width2 = [['4.25"', 4.25], ['4.50"', 4.5], ['4.75"', 4.75], ['5"', 5]];

    const height = [['2"', 2], ['2.25"', 2.25], ['2.50"', 2.5], ['2.75"', 2.75], ['3"', 3]];

    const list = options.printType === 'wristband' ?
        [[false, [1, 0]], [false, [2, 0]], [false, [3, 0]]] :
        [[false, [1, 0]], [false, [2, 0]], [false, [3, 0]]];

    const diagram = options.printType === 'wristband' ?
        [[true, [2, 0]], [true, [4, 0]], [true, [6, 0]], [true, [8, 0]]] :
        [[true, [1, 1]], [true, [1, 2]], [true, [2, 2]], [true, [2, 3]], [true, [2, 4]], [true, [3, 4]], [true, [4, 4]], [true, [4, 6]]];


    const getText = value => {
        return value[0] ? `${options.printType === 'wristband' ? value[1][0] + ' play' : value[1][0] * value[1][1] + ' play'}`+ `${value[1][1]===1?'':'s'}`: `${value[1][0]} column${value[1][0]===1?'':'s'}`;
    }

    const setPrintType = value => handleChange('printType', value);
    const setWidth = value => handleChange('width', value);
    const setHeight = value => handleChange('height', value);
    const setView = value => handleChange('view', value);
    const setInfo = value => handleChange('info', value);


    const handleChange = (setting, value) => {
        const newOptions = setPrint(setting, value, options);
        setOptions(newOptions);

        setReady(true);
    }

    const changePage = () => {
        if (page === 'type') {
            setPage('options')
        } else {
            setPage('type')
        }
        setReady(true);
    }

    const checkIfSpinner = () => {
        const pathArray = window.location.pathname.split('/');
        return !pathArray.includes('app');
    }
    useEffect(() => {
        if (ready) {
            setPrintReady(true)
        }
    }, [ready])


    return (<div className="hide-scrollbar">

        <PrintModalTop>
            {page !== 'type' && <Text16 onClick={changePage}> {'<'} Print type </Text16>}
            <div>
                <Text16 style={{ cursor: 'pointer' }} onClick={close}> Cancel </Text16>
                {page !== 'type' ? <ReactToPrint content={() => printRef.current}
                                                 trigger={() => <Button loading={!printReady}
                                                                        width={'50%'}>Print</Button>}
                                                 pageStyle={`@page { size: A4; margin: 0;}`}
                    />
                    : <Button onClick={changePage} type={'secondary'} width={'50%'}>Next {'>'}</Button>}
            </div>

        </PrintModalTop>
        <Text22 margin="3rem 0 5rem">Print out a Play</Text22>

        {page === 'type' && <Radio label="PRINT TYPE"
                                   options={[['WRISTBANDS', 'wristband'], ['PLAY CARDS', 'callSheet']]}
                                   onChange={setPrintType}
                                   value={options.printType}/>}

        {(page === 'type' && optionalPlays.length > 0) &&
        <PlaysSelection plays={plays} optPlays={optionalPlays} add={addPlay} remove={removePlay} all={addAll}/>}
        {(page === 'type' && optionalPlays.length < 1 && checkIfSpinner()) && <Loader style={{ height: '50%' }}/>}


        {page === 'options' && <>
            {options.printType === 'wristband' && <>
                <Wrapper>
                    <Label>WIDTH</Label>
                    <Content>
                        <StyledSelect label="WIDTH"
                            value={options.width}
                            onChange={e => setWidth(e.target.value)}
                            fullWidth>
                            {widthOptions.concat(width2Options).map((w, index) => (
                            <StyledMenuItem key={index} value={w[1]}>{w[0]}</StyledMenuItem>
                            ))}
                    </StyledSelect>
                    </Content>
                </Wrapper>
                    
                <Wrapper>
                    <Label>HEIGHT</Label>
                    <Content>
                        <StyledSelect label="HEIGHT"
                                value={options.height}
                                onChange={e => setHeight(e.target.value)}
                                fullWidth>
                                {heightOptions.map((h, index) => (
                                <StyledMenuItem  key={index} value={h[1]}>{h[0]}</StyledMenuItem >
                                ))}
                        </StyledSelect>
                    </Content>
                </Wrapper>
            </>}


            {playCount > 1 && <>
                <Wrapper>
                    <Label>LIST VIEW</Label>
                    <Content>
                        {
                            list.map((el, index) =>
                                <Option key={index}
                                        active={el[0] === options.diagram && el[1][0] === options.numOfPlays[0] && el[1][1] === options.numOfPlays[1]}
                                        onClick={() => setView(el)}>
                                    <Text18>{getText(el)}</Text18>
                                </Option>,
                            )
                        }
                    </Content>
                </Wrapper>
                <Wrapper>
                    <Label>DIAGRAM VIEW</Label>
                    <Content>
                        {
                            diagram.map((el, index) =>
                                <Option key={index}
                                        active={el[0] === options.diagram && el[1][0] === options.numOfPlays[0] && el[1][1] === options.numOfPlays[1]}
                                        onClick={() => setView(el)}>
                                    <Text18>{getText(el)}</Text18>
                                </Option>,
                            )
                        }
                    </Content>
                </Wrapper>
            </>}
            <MuiThemeProvider theme={FfbTheme}>
                <Wrapper>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Label>COPIES</Label>
                        <Label>{options.copies}</Label>
                    </div>
                    <Content>
                        <Slider color="primary" value={options.copies}
                                style={{
                                    width: '100%',
                                    backgroundColor: '#ffffff',
                                    border: '1rem solid #ffffff',
                                    boxSizing: 'content-box',
                                }}
                                onChange={(e, value) => handleChange('copies', value)}
                                step={1} max={10} min={1} defaultValue={1} title={'Copies'} name={'Copies'}/>
                    </Content>
                </Wrapper>
            </MuiThemeProvider>

            <Wrapper>
                <Label>MORE INFO</Label>
                <Content>
                    <Option active={options.moreInfo.includes('points')} onClick={() => setInfo('points')} width="25%">
                        <Text16><b>Coaching points</b></Text16>
                    </Option>
                    <Option active={options.moreInfo.includes('title')} onClick={() => setInfo('title')} width="25%">
                        <Text16><b>Play name</b></Text16>
                    </Option>

                    <Option active={options.moreInfo.includes('number')} onClick={() => setInfo('number')} width="25%">
                        <Text16><b>Play number</b></Text16>
                    </Option>

                    <Option active={options.moreInfo.length === 0} onClick={() => setInfo('none')} width="10%">
                        <img src={None} alt="none"/>
                    </Option>


                </Content>
            </Wrapper>


        </>}

    </div>)
}

const PrintModalTop = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: end;

  & > p {
    margin-right: auto;
    cursor: pointer;
  }

  & > div {
    display: flex;
    align-items: center;
    margin-right: 0;

    button {
      margin-left: 3rem;
    }
  }

`
const Option = styled.div`
  flex: 1 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-basis: ${props => props.width || '50%'};
  align-items: center;
  background: ${({ theme }) => theme.color.blue};
  cursor: pointer;

  ${({ active }) => active && css`
    background: ${({ theme }) => theme.color.primary};

    * {
      color: ${({ theme }) => theme.color.secondary};
    }
  `}

`;

const Content = styled.div`
  display: flex;
  border-radius: 5px;
  flex-wrap: wrap;
  overflow: hidden;
`;

const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.white};
  margin: 0 0 10px;
`;

const Wrapper = styled.div`
  margin: ${({ margin }) => margin || "0 0 20px"};
`;