import { Grid } from "@material-ui/core";
import { SectionHeader } from "./SectionParts/SectionHeader";
import { LabelInput, StyledPaper } from "../StyledComponents/StyledComponents";
import T from "../svg/T.svg";
import { SettingButton } from "./SectionParts/SettingButton";
import React from "react";
import styled from "styled-components";
// import { useSelector } from "react-redux";
// import { CategoryButton } from "./SectionParts/CategoryButton";

export const PlaySettings = ({ play, playerSettingChange }) => {

    // const categories = useSelector(store => store.content.categories);

    const handleCP = e => {
        console.log(e.target.value)
        playerSettingChange('coachingPoints', e.target.value, 'play')
    }
    return (
        <Div style={{ margin: '1rem' }}>
            <Grid container spacing={0}>
                <SectionHeader text={'EDIT PLAY'} sectionName="playSettings" bordered edit/>
                <SectionHeader text={'Play Title'} sectionName="playSettings" bordered edit/>
                <Grid item xs={12}>
                    <StyledPaper style={{ justifyContent: 'space-between', minHeight: '5vh' }}>
                        <LabelInput type="text" value={play.title}
                                    onChange={event => playerSettingChange('playTitle', event.target.value, 'play')}
                                    placeholder={'change name'} style={{ padding: '10px' }}/>

                        <img src={T} alt="" style={{ marginRight: '2rem' }}/>
                    </StyledPaper>
                </Grid>
                <SectionHeader text={'Field Lines'} sectionName="playSettings" bordered edit/>
                <LineButtons name="lineButtons" class={play.fieldLines === 'yes' ? 'fieldLines-button buttons-active' : 'fieldLines-button '}>
                <SettingButton setting="fieldLines" active={play.fieldLines === 'yes'} value="yes" xs={6}
                               title={'setting'}
                               settingChange={playerSettingChange} text="Yes" object="play" editplay/>
                </LineButtons>
                <LineButtons name="lineButtons" class={play.fieldLines === 'no' ? 'fieldLines-button buttons-active' : 'fieldLines-button '}>
                <SettingButton setting="fieldLines" active={play.fieldLines === 'no'} value="no" xs={6}
                               title={'setting'}
                               settingChange={playerSettingChange} text="No" object="play" editplay/>
                </LineButtons>
                <SectionHeader text={'Coaching Points'} sectionName="playSettings" bordered edit/>
                <Grid item xs={12}>
                    <StyledPaper>
                        <CoachingPointsTextArea value={play.coaching_points}
                                                onChange={handleCP}
                                                placeholder={'add coaching Points|'}/>


                    </StyledPaper>
                </Grid>
            </Grid>
        </Div>
    )
}

const CoachingPointsTextArea = styled.textarea`
  resize: none;
  color: #000000;
  padding: .5rem;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Circular Std', sans-serif;
  width: 100%;
  height: 10rem;
  outline: none;
  &:focus {
    color: #FCC400;
  }
`

const Div = styled.div `
  div[name=lineButtons] {
    width: 50%;
  }
  div[name=lineButtons] > div {
    max-width: 100% !important;
    width: 100% !important;
  }
  div[name=lineButtons] > div span {
    text-transform: uppercase; 
  }
  div[name=lineButtons] div[setting=fieldLines] {
    border: 2px solid #fcc400;
    background: none !important;
  }
  div[name=lineButtons].buttons-active div[setting=fieldLines] {
    background: #fcc400 !important;
  }
  div[name=lineButtons].buttons-active div[setting=fieldLines] span {
    color: #0077ff !important;
  }
`

const LineButtons = styled.div `
`