import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { StyledPaper, StyledTypography, Svg } from "../../StyledComponents/StyledComponents";


export const SettingButton = ({ settingChange, value, object='player', setting, editplay, icon = false, active=false, xs=4, text=false, title }) => {
  
  let upperCasedTitle

  if(title === 'wiggly') {
    upperCasedTitle = 'Motion'
  }
  else {
    upperCasedTitle = title[0].toUpperCase() + title.substring(1)
  }

  
  return(
    <Tooltip title={upperCasedTitle} arrow>
      <Grid item xs={xs}>
          <StyledPaper active={active} object={object} setting={setting} editplay={editplay}
                       onClick={() => settingChange(setting, value, object)}
          style={{ display: 'grid', placeItems: 'center', alignContent: 'center' }}>

              {icon?<Svg src={icon} active={active}/>:<StyledTypography editplay={editplay} active={active} variant={'p'}>{text}</StyledTypography>}
          </StyledPaper>
      </Grid>
      </Tooltip>
  )
}