import React from "react";
import { SectionHeader } from "./SectionParts/SectionHeader";
import { Grid } from "@material-ui/core";

import { ColorPicker } from "./SectionParts/ColorPicker";
import { StyledToolsContainer } from "../StyledComponents/StyledComponents";


export const Border = ({ player, settingChange, open, setOpen }) => {


    return (
        <StyledToolsContainer>
            <Grid container spacing={0}>
                <SectionHeader text={'BORDER'} setOpen={setOpen} open={open} sectionName={'border'}/>
                {<ColorPicker player={player} settingChange={settingChange} setting={'borderFill'}/>}
            </Grid>
        </StyledToolsContainer>)

}