import React, { useState } from "react";
import { Arc, Arrow } from "react-konva";
import { ActiveLine } from "./ActiveLine";
import { generateDashTable, getWigglyLine } from "../../Helpers/LineHelper";
import {
    getAlphaAngle,
    getArcCoordinates, getArcDash,
    getArcLength, getArcRotation,
    getCurvedLinePoints, getRadius,
    getTangentLength, getTangentPoints, quarterCheck,
} from "../../Helpers/arcHelper";
import { lineWithMultiplier } from "../../../constants";

export const CurvedArrow = ({
                                player,
                                points,
                                endpoint,
                                dash,
                                activeLine,
                                lineActivation,
                                id,
                                dashTable = false,
                                lastPoints,
                                ratio,
                            }) => {
    const [hover, setHover] = useState(false)

    const getDashTable = () => {
        if (dash && dash !== 'solid' && dash !== 'wiggly' && dash !== 'long') {
            return dash === 'dot' ? [0.001, 10] : [15, 15]
        } else {
            if (dashTable) {

                return generateDashTable(dashTable, points)
            }
        }
    }


    const arcLength = getArcLength(points);
    const tangent = getTangentLength(points, arcLength);
    const newPoints = getCurvedLinePoints(points, tangent);

    const alpha = getAlphaAngle(points);
    const arcCoords = getArcCoordinates(newPoints, tangent, alpha);
    const radius = getRadius(points, newPoints, arcCoords, tangent);
    const rotation = getArcRotation(arcCoords, newPoints, alpha, arcLength);

    const tangentPoints = getTangentPoints(arcCoords, arcLength, rotation, radius, points);

    const quarter = quarterCheck(points)


    const actualPoints = points => lastPoints === null ? [points[0], points[1], tangentPoints[0], tangentPoints[1]] : [...lastPoints, tangentPoints[0], tangentPoints[1]];

    getArcDash(radius, arcLength, player.line_dash[id])
    return (
        <>
            <Arrow points={dash !== 'wiggly' ? actualPoints(points) : getWigglyLine(player, actualPoints(points))}
                   stroke={player.stroke_fill !== '' ? player.stroke_fill : '#6949FF'}
                   strokeWidth={player.line_width * ratio* lineWithMultiplier}
                   lineCap={dash !== 'wiggly' ? "round" : "butt"}
                   tension={dash !== 'wiggly' ? player.line_tension : 0}
                   dashEnabled={(dash !== 'solid' && dash !== 'wiggly') || dash === 'long'}
                   dash={getDashTable()}
                   pointerLength={endpoint === 'arrow' ? 10 : 0}
                   pointerWidth={endpoint === 'arrow' ? 15 : 30}
                   fill={player.stroke_fill !== '' ? player.stroke_fill : '#6949FF'}
                   onMouseOver={() => setHover(true)}
                   onMouseOut={() => setHover(false)}
                   onClick={() => lineActivation(player.id, id)}
                   opacity={(hover || activeLine) ? 0.5 : 1}/>
            {activeLine && <ActiveLine player={player} points={actualPoints(points)}/>}
            <Arc angle={arcLength}
                 dash={getArcDash(radius, arcLength, player.line_dash[id + 1], player.line_dash[id], quarter)}
                 lineCap={"round"}
                 innerRadius={radius}
                 outerRadius={radius}
                 stroke={player.stroke_fill !== '' ? player.stroke_fill : '#6949FF'}
                 strokeWidth={player.line_width * ratio* lineWithMultiplier}
                 x={arcCoords[0]} y={arcCoords[1]}
                 opacity={(hover || activeLine) ? 0.5 : 1}
                 onMouseOver={() => setHover(true)}
                 onMouseOut={() => setHover(false)}
                 onClick={() => lineActivation(player.id, id)}
                 rotation={rotation}/>

        </>
    )
}
