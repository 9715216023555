import { CLEAR_PRINT, SET_PRINT } from "../actions/print";


export default (
    state = null,
    action,
) => {
    switch (action.type) {
        case SET_PRINT:
            return action.payload
        case CLEAR_PRINT:
            return null;
        default:
            return state;
    }
};