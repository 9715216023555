import React from "react";
import { StyledBordered, StyledPaper } from "../../StyledComponents/StyledComponents";
import { Grid } from "@material-ui/core";

import { colorTable } from "../../../constants";
import { ColorButton } from "./ColorButton";


export const ColorPicker = ({ settingChange, player, setting='color' }) => {
    const colors = colorTable

    const checkIfActive = color => {
      switch (setting){
          case 'color':
              return player.color === color;
          case 'strokeFill':
              return player.stroke_fill=== color;
          case 'labelFill':
              return player.label_fill === color;
          case 'borderFill':
              return player.border_fill === color;
      }
    }
    return (

        <Grid item xs={12}>

                <StyledPaper style={{ border: 'none' }}>
               <StyledBordered> 

                    <Grid container spacing={0}>

                        {colors.map(color => <ColorButton color={color} key={color} active={checkIfActive(color)}
                                                          settingChange={settingChange} setting={setting}/>)}
                    </Grid>
                 </StyledBordered> 

                </StyledPaper>

        </Grid>

    )
}