import React from 'react';
import styled from 'styled-components';
import { Popover } from "@material-ui/core";
import { Text16 } from "./Typography";

export const DropdownButton = ({ onClick, text }) => {
    return (
        <StyledButton onClick={onClick}>
            <Text16>{text}</Text16>
        </StyledButton>
    )
};

const Dropdown = ({ open, refProp, onClose, children }) => {

    return (
        <Popover
            open={open}
            anchorEl={refProp.current}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Wrapper>
                {children}
            </Wrapper>
        </Popover>
    );
}

export default Dropdown;

const StyledButton = styled.button`
  background: none;
  border: none;
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;

  *{
    color: ${({ theme }) => theme.color.black};
  }
  
  &:hover{
    background: ${({ theme }) => theme.color.primary};
    *{
      color: ${({ theme }) => theme.color.secondary};
    }
  }
  `;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.secondary};
  border: 1px solid ${({ theme }) => theme.color.border};
  padding: 0;
  min-width: 150px;
  width: 100%;
  border-radius: 5px;
  margin-right: 10px;
`;