import {
    CLEAR_CONTENT, GET_ACTIVE_FILTERS,
    GET_PLAYBOOKS,
    GET_PLAYERS,
    GET_PLAYS,
    GET_PREDEFINED_PLAYS, GET_TAGS,
} from "../actions";

const initialStore = {
    playbooks: {
        items: [],
        currentPage: 1,
        totalPages: 0,
    },
    plays: null,
    predefinedPlays: null,
    players: [],
    categories: [],
    activeFilters: {
        tags: [],
        search: "",
        predefined: false,
        page: 1,
        custom_sort: [],
    },
}

export default (
    state = initialStore,
    action,
) => {
    switch (action.type) {
        case GET_PLAYBOOKS:
            return {
                ...state,
                playbooks: action.payload,
            };
        case GET_PLAYS:
            return {
                ...state,
                plays: action.payload,
            };
        case GET_ACTIVE_FILTERS: {
            return {
                ...state,
                activeFilters: action.payload,
            };
        }
        case GET_PREDEFINED_PLAYS:
            return {
                ...state,
                predefinedPlays: action.payload,
            };
        case GET_PLAYERS:
            return{
                ...state,
                players: action.payload,
            }
        case GET_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case CLEAR_CONTENT:
            return initialStore;
        default:
            return state;
    }
};
