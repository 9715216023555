import React, { useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions";
import { Text16, Text22 } from "../atoms/Typography";
import { Row } from "../../theme/styledComponents";
import { NavLink } from "react-router-dom";

import LOGO from "../../assets/images/flagfootballu.png";
import left from "../../assets/icons/left.svg"
import right from "../../assets/icons/right.svg"
import { Tooltip } from "@material-ui/core";

const Header = ({ nextPlay, prevPlay, switchAvailable, title = false }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(store => store.auth)


    const handleLogOut = useCallback(() => {
        dispatch(logout())
    }, [])

    return (
        <HeaderWrapper>
            <HeaderContent>
                <NavLink to="/">
                    <Logo src={LOGO}/>
                </NavLink>
                {title && <PlayNav>
                    {switchAvailable > 0 &&
                        <Tooltip title="Previous play" arrow>
                            <img src={left} alt="arrow-left" onClick={prevPlay}/>
                        </Tooltip>}
                    <Text22>{title}</Text22>


                    {switchAvailable > 0 &&
                        <Tooltip title="Next play" arrow>
                            <img src={right} alt="arrow-right" onClick={nextPlay}/>
                        </Tooltip>}
                </PlayNav>

                }
                <div/>
                {
                    user &&
                    <UserWrapper>
                        <UserContent>
                            {user.displayName?.substr(0, 2)}
                        </UserContent>
                        <UserDropdownWrapper className="dropdown">
                            <UserDropdownContent>
                                <Row>
                                    <UserContent>
                                        {user.displayName?.substr(0, 2)}
                                    </UserContent>
                                    <Text16 margin="0 0 0 20px">
                                        {user.email}
                                    </Text16>
                                </Row>
                                <LogoutWrapper onClick={handleLogOut}>
                                    <Text16>
                                        Log out
                                    </Text16>
                                </LogoutWrapper>
                            </UserDropdownContent>
                        </UserDropdownWrapper>
                    </UserWrapper>
                }
            </HeaderContent>
        </HeaderWrapper>
    )
}

export default Header;

const LogoutWrapper = styled.div`
  border-top: 1px solid rgb(255 255 255 / 20%);
  cursor: pointer;
`;

const UserDropdownContent = styled.div`
  margin-top: 10px;
  background: ${({ theme }) => theme.color.lblue};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.black};
  box-shadow: 0px 4px 10px -6px #000;
  border: 1px solid rgb(0 0 0 / 10%);

  > div {
    padding: 20px;
  }
`;

const UserDropdownWrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateY(100%);
  z-index: 999;
  display: none;
`;

const UserContent = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.black};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.primary};
  }
`;

const UserWrapper = styled.div`
  position: relative;

  &:hover {
    background: #0077FF;

    .dropdown {
      display: block;
    }
  }
`;

const Logo = styled.img`
  margin: 18px 0;
  height: 30px;
  display: block;
  max-width: 90%;
`;

const HeaderContent = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderWrapper = styled.div`
    background: linear-gradient(270.08deg, ${({ theme }) => theme.color.lblue} 56.36%, ${({ theme }) => theme.color.blue} 99.93%);
`;

const PlayNav = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 15%;
  justify-content: space-between;
  margin-right: auto;
  margin-left: 3rem;

  h1 {
    margin: 0 1.5rem;
  }
`
