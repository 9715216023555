import React, { useCallback } from 'react';
import Modal from "../molecules/Modal";
import { Text16, Text20 } from "../atoms/Typography";
import { Row } from "../../theme/styledComponents";
import Button from "../atoms/Button";

const ShareModal = ({ show, setShow, text = "Are you sure you want to delete this play?", link }) => {

    const handleCloseModal = useCallback(() => {
        setShow(false)
    }, [])

    const handleShareButton = useCallback(() => {
        navigator.clipboard.writeText(link)
        setShow(false)
    }, [])

    return (
        <Modal show={show} setShow={setShow} maxWidth="600px">
            <Text20 textAlign="center" margin="0 0 20px">{text}</Text20>
            <Text16 textAlign="center">{link}</Text16>
            <Row margin="30px 0 0">
                <Button type="third" margin="0 25px" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button color="white" width="max-content" onClick={handleShareButton}>
                    Copy link
                </Button>
            </Row>
        </Modal>
    );
}

export default ShareModal;
