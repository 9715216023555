import React from 'react';
import styled, { css } from 'styled-components'
import Spinner from './Spinner';
import { NavLink } from 'react-router-dom';

function Button(props) {
    const {
        secondary,
        type,
        to,
        children,
        width,
        margin,
        onClick,
        loading = false,
        alignItems,
        justifyContent,
        color,
        background,
        disabled,
    } = props;

    return (
        <>
            {
                to ?
                    <StyledA 
                        to={to} 
                        secondary={secondary} 
                        type={type} 
                        width={width} 
                        margin={margin}
                        alignItems={alignItems} 
                        justifyContent={justifyContent}>
                        {children}
                    </StyledA> :
                    <StyledButton
                        type={type} 
                        width={width} 
                        margin={margin} 
                        onClick={onClick}
                        alignItems={alignItems} 
                        justifyContent={justifyContent}
                        color={color}
                        background={background}
                        disabled={disabled}
                    >
                        {children}
                        <span className={`loading ${loading ? 'active' : ''}`}><Spinner white height={18}
                                                                                        width={18}/></span>
                    </StyledButton>
            }
        </>
    )
}

export default Button;

const base = css`
  font-size: ${({ theme }) => theme.font.M};
  background: ${({ theme, background }) => background ? theme.color[background] : theme.color.primary};
  color: ${({ theme, color }) => color ? theme.color[color] : theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.normal};
  margin: ${({ margin }) => margin || '0px 0px'};
  padding: 10px 25px;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: ${({ width }) => width || '100%'};
  min-height: 45px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  ${({ type }) => type === 'secondary' && css`
    min-height: 0;
    background: ${({ theme, background }) => background ? theme.color[background] : theme.color.white};
    color: ${({ theme }) => theme.color.black};
    width: 100%;
    padding: 10px 25px;
  `}

  ${({ type }) => type === 'third' && css`
    min-height: 0;
    background: none;
    color: ${({ theme }) => theme.color.white};
    width: max-content;
    padding: 0px;
  `}
  
  &:disabled,
  &[disabled]{
    opacity: 0.7;
    cursor: not-allowed;
  }

  @media screen and (max-width:700px) {
    margin-bottom: 30px;
  }
`

const StyledA = styled(NavLink)`
  ${base}
  &.active {
    color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.background};
  }
`;

const StyledButton = styled.button`
  position: relative;

  .loading {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.15s;
    background: ${({ theme }) => theme.color.primary};

    &.active {
      opacity: 1;
    }
  }

  ${base}
`;