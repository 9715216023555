export const SET_PRINT = "SET_PRINT";
export const CLEAR_PRINT = "CLEAR_PRINT";

export const setPrint = playbooks => {
    return {
        type: SET_PRINT,
        payload: playbooks,
    };
};

export const clearPrint = () => {
    return {
        type: CLEAR_PRINT,
    };
};