const prototypePlayer = number => {
    return {
        id: number,
        player: true,
        rect: false,
        color: '#6949FF',
        x: 100,
        y: 400,
        shading: 'full',
        label: '',
        label_fill: '#000000',
        shape: 'circle',
        size: 1.3,
        line_endpoint: [],
        line_dash: [],
        line_tension: 0,
        line_width: 5,
        line_points: [],
        stroke_fill: '#6949FF',

    }
}

const prototypeShape = (number, shape) => {
    return {
        id: number,
        player: false,
        rect: false,
        color: shape === 'text' ? '#C4C4C4' : '#6949FF',
        x: 100,
        y: 400,
        width: 100,
        height: 60,
        shading: 'full',
        label: shape === 'text' ? 'text' : '',
        label_fill: '#000000',
        label_format: 'bold',
        stroke_fill: shape === 'text' ? '#BF1F1F' : '#6949FF',
        shape: shape,
        size: 1.3,
        line_endpoint: [],
        line_dash: [],
        line_tension: 0,
        line_width: shape === 'text' ? 3 : 5,
        line_points: [],
        border_fill: '#BF1F1F',

    }
}
export const getID = players => {
    if (!players || !players.length) {
        return 1
    }
    let id = 0;
    players.forEach(p => p.id <= id ? null : id = p.id)

    return id+1
}
export const getPrototypeElement = (element, id, shape = '') => {
    let rand = id;
    if (element === 'player') {
        return prototypePlayer(rand);

    } else {
        return prototypeShape(rand, shape);
    }
}

export const prototypePlay = {
    id: 0,
    user_id: 1,
    title: 'New Play',
    darkMode: false,
    baseLine: 500,
    baseLineVisible: true,
    predefined: true,

}
