import styled from "styled-components";
import { Paper, Typography } from "@material-ui/core";

const width = (value = 0, br = 1320) => {
    const base = window.innerWidth;
    if (base > br) {
        return value;
    } else {
        return value * (base / br)
    }
}

export const EditorDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-family: 'Circular Std', sans-serif;
  margin-left: auto;
`
export const StyledPlayer = styled.div`
  background-color: ${({ theme }) => theme.color.lblue};
  width: ${() => window.innerWidth > 1320 ? 18 : 27}vw;
  font-family: 'Circular Std', sans-serif;
  position: relative;
  height: calc(100vh - 6.5vh - 20px);
  top: 20px;
  overflow: auto;
  border-radius: 20px 0 0 0;
`
export const StyledPaper = styled(Paper)`
  background-color: ${props => props.active ? '#FFF' : !props.blank && '#FFF'} !important;
  box-shadow: none !important;
  width: ${({ width }) => width || 100}%;
  border: 1px solid #F9F9F9;
  border-radius: 4px !important;
  height: ${({ setting }) => setting && width(2.25)}rem;
  display: flex;
  align-items: center;
  min-height: ${({ editplay }) => editplay && '5vh'};
  color: #000;

  img  {
    max-height: ${({ setting }) => setting ? width(70)+'%' : 'auto'};
    max-height: ${({ setting }) => setting === 'dashed' || (setting === 'endpoint')? width(100)+'%' : 'auto'};

  }
`
export const StyledTypography = styled(Typography)`
  color: ${props => props.active ? 'black' : '#ffffff'};
  padding: ${({ sectionName }) => sectionName === 'playSettings' ? '.5rem 0 !important' : '1rem 0 1rem !important'};
  padding: ${({ editplay }) => editplay && '0 !important'};
  margin: ${({ sectionName }) => sectionName === 'playSettings' ? '0 !important' : '0 !important'};
  margin-top: ${({ edit }) => edit && '0 !important'};
  display: block;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
`

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  padding: 0.2rem 2rem;

  & > div.user {
    display: flex;
    align-items: center;
    height: 100%;

    button:last-child {
      display: grid;
      place-items: center;
      background-color: orange;
      margin-left: 1rem;

      span {
        display: block;
        font-size: 18px;
        font-weight: 700;
        padding: .2rem .4rem;
      }
    }
  }
`

export const StyledColorsContainer = styled.div`
  height: calc( (21vh)  / 7);
  height: 2.2rem;
`

export const StyledToolsContainer = styled.div`
  margin: 0rem 19px 0 22px;
  margin-bottom: ${ ({ marginBottom }) => marginBottom && marginBottom }rem;
    div[setting=format], 
    div[setting=dashed], 
    div[setting=tension],
    div[setting=endpoint],
    div[setting=shading],
    div[setting=shape] {
        background: none !important;
        border: 1px solid rgb(255 255 255 / 0.5);
    }

    div[setting=format]:hover, 
    div[setting=dashed]:hover, 
    div[setting=tension]:hover,
    div[setting=endpoint]:hover,
    div[setting=shading]:hover,
    div[setting=shape]:hover {
        border: 1px solid #fcc400;
    }
`

export const StyledBordered = styled.div`
  width: 100%;
`

export const StyledButton = styled.button`
  display: grid;
  place-items: center;
  align-content: center;
  background-color: #FCC400;
  border: none;
  border-radius: 4px;
  height: 80%;
  padding: .6rem;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #646464;
  }
`
export const Svg = styled.img`
  max-width: 32px;
  ${props => props.active && 'filter: invert();'}

`
export const LabelInput = styled.input`
  color: #000;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Circular Std', sans-serif;
  /* margin-left: 1rem; */
  outline: none;
  width: 81%;
`

export const SettingSection = styled.div`
  margin: 1rem;
  border-radius: 5px
`

export const Color = styled(StyledButton)`
  width: 100%;
  //min-width: 40px; 
  //min-height: 40px;
  height: 100%;
  margin-right: 0;
`
