import React, { useState } from "react";
import { PlayerShape } from "./PlayerToolbarSections/PlayerShape";
import { PlayerFill } from "./PlayerToolbarSections/PlayerFill";
import { PlayerText } from "./PlayerToolbarSections/PlayerText";
import { DrawingLine } from "./PlayerToolbarSections/DrawingLine";
import { StyledPlayer, StyledToolsContainer } from "./StyledComponents/StyledComponents";
import { Border } from "./PlayerToolbarSections/Border";
import { PlaySettings } from "./PlayerToolbarSections/PlaySettings";
import { SettingSlider } from "./PlayerToolbarSections/SectionParts/SettingSlider";



export const PlayerToolbar = ({
                                  playerSettingChange,
                                  player,
                                  activeLine,
                                  play,
                                  settingActive,
                              }) => {

    const [activeSection, setSection] = useState('shape');

  //   const sizeInLetter = () => {
  //
  //     if (player.size < 1) {
  //         return 'S'
  //     } else if (player.size > 1.5) {
  //         return 'L'
  //     } else {
  //         return 'M'
  //     }
  //
  // }



    const getContents = () => {
        if (player || settingActive === 'PS') {
            if (settingActive === 'PS') {
                return <PlaySettings play={play} playerSettingChange={playerSettingChange}/>
            } else {
                return (<>
                    {player.shape !== 'text' && <PlayerShape player={player} settingChange={playerSettingChange} open={activeSection === 'shape'} setOpen={setSection}/>}
                    {(player.shape !== 'ball' && player.shape !== 'addline') && <PlayerFill player={player} settingChange={playerSettingChange} open={activeSection === 'fill'} setOpen={setSection}/>}
                    <StyledToolsContainer>
                    {(player.player)&&<SettingSlider settingChange={playerSettingChange} size={player.size.toFixed(1)} setting="size"
                                text="PLAYER SIZE" currentValue={player.size}/>}
                                </StyledToolsContainer>
                    <PlayerText player={player} settingChange={playerSettingChange} open={activeSection === 'text'} setOpen={setSection}/>
                    {player.shape === 'text' && <Border player={player} settingChange={playerSettingChange} open={activeSection === 'border'} setOpen={setSection}/>}
                    <DrawingLine player={player} settingChange={playerSettingChange} activeLine={activeLine} open={activeSection === 'line'} setOpen={setSection}/>
                </>)
            }
        }  else return null
    }


    return (

        <StyledPlayer className={'player'}>
            {getContents()}
        </StyledPlayer>


    )

}

