import React from 'react';
import styled, { css } from 'styled-components';

const Input = ({ margin, error, label, rhf = {}, password, placeholder, value, onChange, ...props }) => {
    const { ref, ...rest } = rhf

    return (
        <Wrapper margin={margin}>
            {label && <Label>{label}</Label>}
            <SInput placeholder={placeholder} error={error} type={password ? 'password' : 'text'} value={value} onChange={onChange} ref={ref} {...rest}  {...props}/>
        </Wrapper>
    );
}

export default Input;

const SInput = styled.input`
  display: block;
  font-size: ${({ theme }) => theme.font.L};
  font-weight: ${({ theme }) => theme.font.bold};
  width: 100%;
  padding: 13px 15px;
  background: ${({ theme, background }) => theme.color[background] ?? theme.color.fourth};
  border: 0px;
  color: ${({ theme }) => theme.color.black};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.color.border};
  
  ${({ error }) => error && css`
    border: 2px solid ${({ theme }) => theme.color.red};
  `};

  &:focus {
    outline-width: 0;
  }
  
  &:focus{
    border: 2px solid ${({ theme }) => theme.color.primary} !important;
  }

  &::placeholder {
    color: #fff;
  }
  `;

const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.white};
  margin: 0 0 10px;
  `;

const Wrapper = styled.div`
    margin: ${({ margin }) => margin || "0 0 20px"};
    `;
