import React from 'react';
import styled, { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import configureStore from "./configureStore";
import { theme } from "./theme/theme";
import { Provider, useSelector } from 'react-redux';
import Login from "./pages/Login/Login";
import Panel from "./pages/Panel/Panel";
import { PlayMaker } from "./pages/PlayMaker/PlayMaker";
import Footer from "./components/shared/Footer";
import PrintProvider from "./providers/PrintProvider";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Loader from "./components/atoms/Loader";

export const store = configureStore();

const ProtectedRoutes = () => {
    const auth = useSelector(store => store.auth);

    if (auth.isVerifying) {
        return <LoadingScreen>
            <Loader/>
        </LoadingScreen>
    }

    if(!auth.isAuthenticated){
        return <Switch>
            <Route exact path="/app/:id">
                <PlayMaker />
            </Route>
            <Route exact path='/login'>
                <Login/>
            </Route>
            <Route>
                <Redirect to='/login'/>
            </Route>
        </Switch>
    }

    return (
        <Switch>
            <Route exact path="/app/:id">
                <PlayMaker />
            </Route>
            <Route path="/panel">
                <Panel/>
            </Route>
            <Route>
                <Redirect to='/panel/playbooks'/>
            </Route>
        </Switch>
    )
}

function App() {

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <DndProvider backend={HTML5Backend}>
                    <div className="App">
                        <Screen>
                            <PrintProvider store={store}>
                                <Router>
                                    <ProtectedRoutes/>
                                </Router>
                            </PrintProvider>
                        </Screen>
                        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <Footer/>}
                    </div>
                </DndProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;

const Screen = styled.div`
  min-height: 100vh;
  `;

const LoadingScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;
