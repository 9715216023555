import React from "react";
import { StyledTypography } from "../../StyledComponents/StyledComponents";
import { Grid } from "@material-ui/core";

export const SectionHeader = ({ text, sectionName, bordered, edit }) => {

    return (
      <Grid item xs={12}>
        <div>
      <StyledTypography sectionName={sectionName} variant={'p'} edit={edit}>{text}</StyledTypography>
      </div>
      </Grid>

    )
}