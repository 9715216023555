import React, { useCallback, useEffect, useRef, useState } from "react";

import { PlayCanvas } from "./Components/PlayCanvas";
import { PlayerToolbar } from "./Components/PlayerToolbar";
import "typeface-inter"
import { EditorDiv } from "./Components/StyledComponents/StyledComponents";
import { TopToolbar } from "./Components/TopToolbar";
import { getID, getPrototypeElement } from "../../dummy-data/prototypeElements";
import { flipHorizontally } from "./Components/Helpers/LineHelper";
import { MuiThemeProvider } from "@material-ui/core";
import { FfbTheme } from "../../styles/theme";
import { useHistory, useParams } from "react-router-dom";
import { PlaysService } from "../../services/PlaysService";
import { useSelector } from "react-redux";
import {
    baseLineConfig,
    createPreviewLines, downloadURI,
    editPlay,
    movePreviewLine,
    playerAttributesChange,
    playerPositionChange, snapToGrid, transformToGlobal,
} from "./Components/Helpers/DrawHelper";
import { SaveToast } from "./Components/SaveToast";
import { useHasPermission } from "../../hooks/useHasPermission";
import Header from "../../components/shared/Header";
import styled from "styled-components";
import { fetchAllPlays, getNextPrevPlay } from "./Components/Helpers/switchPlayHelper";
import { basicImage, canvasWithHeightRatio } from "./constants";
import { useQuery } from "../../hooks/useQuery";
import Loader from "../../components/atoms/Loader";


export const PlayMaker = () => {
        const auth = useSelector(store => store.auth);
        const hasPredefinedPermission = useHasPermission("predefined")

        const { id } = useParams();
        const from = useQuery('from');


        const [play, setPlay] = useState(null)

        const [players, setPlayers] = useState([]);
        const [newPLayer, setNewPlayer] = useState(false)

        const [saveActive, setSaveActive] = useState(false)


        const [playerChanged, setPlayerChanged] = useState(false)
        const [topSettingActive, setTopSettingActive] = useState('');

        const [activePlayer, setActivePlayer] = useState(null);
        const [activeLine, setActiveLine] = useState(null);

        const [playerEditorOpen, setPlayerEditorOpen] = useState(false);
        const [previewActive, setPreviewActive] = useState([false, false]);

        const [newShape, setNewShape] = useState('')
        const [newPlayerPreview, setNewPlayerPreview] = useState(false);

        const [enabledDraw, setEnabledDraw] = useState(false);

        const [playerToRemove, setPlayerToRemove] = useState(null);

        const [saveToast, setSaveToast] = useState(false);

        const [changeNewShape, setNewShapeChange] = useState(false)

        const [width, setWidth] = useState(window.innerWidth * 0.73 > 1200 ? 1200 : window.innerWidth * 0.73)

        window.addEventListener("resize", () => {
            setWidth(window.innerWidth * 0.73 > 1200 ? 1200 : window.innerWidth * 0.73)
        })


        const fetchData = useCallback(async () => {
            const data2 = await PlaysService.getPlay(id);
            setPlay(data2)
            setPlayers(data2.players)

        }, [])


        const saveChanges = useCallback((players, play, back = false) => {
            if (play !== null) {
                let newPlay = { ...play, players: players };
                PlaysService.editPlay(play.id, newPlay).then(r => {});
                if (!back) setSaveToast(true)
                if (goingBack) history.goBack();
            }
        }, [play, players]);
        //plays switching
        const [storage, setStorage] = useState([]);

        const changePlay = async (next = true) => {

            const data = await getNextPrevPlay(storage, play, players, next);

            setActivePlayer(null);
            setStorage(data.other);
            setPlayers(data.players);
            setPlay(data.play);
        }
        const prevPlay = () => changePlay(false);
        const nextPlay = () => changePlay();

        const fetchDataInBcg = useCallback(async () => {
            if (play !== null && storage.length <= 0 && canBeEdited()) {
                const plays = await fetchAllPlays(play.playbook_id, from);
                setStorage(plays)
            }
        }, [play, storage])

        useEffect(() => {
            if (storage.length === 0) {
                fetchDataInBcg();
            }
        }, [play])

        // end of switching

        useEffect(() => {
            if(topSettingActive !== 'PS' && activePlayer === null && playerEditorOpen){
                setPlayerEditorOpen(false)
            }else if((topSettingActive === 'PS' || activePlayer !== null)&& !playerEditorOpen){
                setPlayerEditorOpen(true)
            }
        }, [activePlayer, topSettingActive])

        const handleLineActivate = useCallback((playerId, lineId) => {
            setActivePlayer(playerId);
            setActiveLine(lineId);
            setTopSettingActive('')
            setEnabledDraw(false)

        }, [])


        const getActivePlayer = () => {
            if (activePlayer !== null) {
                let player;
                players?.forEach(plr => {
                    if (plr.id === activePlayer) {
                        player = plr
                    }
                })
                return player;
            } else {
                return false
            }
        }

        const history = useHistory();

        const [goingBack, setBack] = useState(false)

        const goBack = useCallback(() => {

            setAnimOptions({
                play: false,
                loop: false,
            })


            setBack(true);
            setActivePlayer(null)
            setSaveActive(true);
        }, [])


        const handleTopSettingChange = (setting, setting2 = 'star') => {
            const nonSettableSettings = ['GL', 'DM', 'FH'];

            if (topSettingActive === setting && topSettingActive !== 'AS' && topSettingActive !== 'AT') {
                setTopSettingActive('')
                setEnabledDraw(false)
            } else {
                if (!nonSettableSettings.includes(setting)) {
                    setTopSettingActive(setting)
                }
                switch (setting) {

                    case 'DL':
                        setEnabledDraw(true);
                        // eslint-disable-next-line no-case-declarations
                        const player = getActivePlayer();
                        if (player.line_points.length < 2) {
                            const newPlayers = players.map(plr => {
                                if (player.id === plr.id) {
                                    plr.line_points = [player.x, player.y, player.x, player.y]
                                    return plr
                                } else {
                                    return plr
                                }
                            })
                            setPlayers(newPlayers)
                        }
                        break
                    case 'AP':
                    case 'AS':
                    case 'AT':
                        if (width / 0.73 > 1200) {
                            setEnabledDraw(false);
                            setActivePlayer(null);
                            setPreviewActive([true, false]);
                            if (setting !== 'AP') {
                                setNewShape(setting2);
                            }
                        } else {
                            if (setting !== 'AP') {
                                createPlayerMobile(setting2)
                            } else {
                                createPlayerMobile()
                            }
                        }
                        break
                    case 'FH':
                        handleFlipHorizontally();
                        break
                    case 'DM':
                    case 'GL':
                        setPlay(editPlay(play, setting));
                        break
                    case 'save':
                        if (topSettingActive !== 'DL') {
                            setActivePlayer(null);
                        }
                        setTopSettingActive('')
                        setEnabledDraw(false)
                        setPlayerChanged(false)
                        setSaveActive(true);
                        setAnimOptions({
                            play: false,
                            loop: false,
                        })
                        break
                    case 'PS':
                        setActivePlayer(null)
                        break
                    default:
                        break
                }
            }

        }


        const handleClickActivate = e => {

            if (canBeEdited()) {
                if (!playerChanged) setPlayerChanged(true)

                if (activePlayer !== e.target.attrs.anchor || newPlayerPreview) {
                    setActivePlayer(e.target.attrs.anchor)

                    setActiveLine(null);
                    setTopSettingActive('')
                    setEnabledDraw(false)
                } else {
                    setActivePlayer(null)
                    setActiveLine(null)
                    setTopSettingActive('')

                }
            }
        }


        const handlePlayerDrag = e => {
            const ex = e.target.attrs.x;
            const ey = e.target.attrs.y;
            const id = e.target.attrs.anchor;
            const coords = width < 1200 ? transformToGlobal([ex, ey], width) : [ex, ey];
            const x = coords[0];
            const y = coords[1];

            const newLines = playerPositionChange(players, id, x, y)
            setPlayers(newLines)
            setPlayerChanged(true)
            setAnimOptions({
                play: false,
                loop: false,
            })
        }

        const handleChangeTransform = (e, setting) => {

            const ex = e.target.attrs.x;
            const ey = e.target.attrs.y;
            const id = e.target.attrs.anchor;
            const coords = width < 1200 ? transformToGlobal([ex, ey], width) : [ex, ey];
            const x = coords[0];
            const y = coords[1];

            for (let attr in setting) {
                const value = setting[attr]
                const newPlayers = playerAttributesChange(players, activePlayer, value, activeLine, attr)
                const newLines = playerPositionChange(newPlayers, id, x, y)
                setPlayers(newLines)
                setPlayerChanged(true)
            }
        }

        const handleUpdateTextSize = (e, value, setting) => {
            const ex = e.target.attrs.x;
            const ey = e.target.attrs.y;
            const id = e.target.attrs.anchor;
            const coords = width < 1200 ? transformToGlobal([ex, ey], width) : [ex, ey];
            const x = coords[0];
            const y = coords[1];

            const newPlayers = playerAttributesChange(players, activePlayer, value, activeLine, setting)
            const newLines = playerPositionChange(newPlayers, id, x, y)

            setPlayers(newLines)
            setPlayerChanged(true)

        }

        const handleLinePreview = (newState, e = false) => {

            setPreviewActive(newState)
            if (e) handleClickDraw(e)
        }

        //setting line before draw

        const [lineConfig, setLineConfig] = useState(baseLineConfig);

        useEffect(() => setLineConfig(baseLineConfig), [activePlayer]);

        const playerSettingChange = (setting, value = false, object = 'player') => {
            const lineTable = ['dashed', 'endpoint', 'tension', 'thickness', 'strokeFill'];

            if (setting === 'dashed' || setting === 'endpoint') {
                if (setting === 'dashed') {
                    setLineConfig({
                        ...lineConfig,
                        dash: value,
                    })
                } else {
                    setLineConfig({
                        ...lineConfig,
                        endpoint: value,
                    })
                }
            }
            if (object === 'player' && setting !== 'undo') {
                if (setting !== 'shape' && !lineTable.includes(setting)) {
                    setTopSettingActive('')
                }
                if (!lineTable.includes(setting)) {
                    setEnabledDraw(false)
                }
            }
            if (object === 'play') {

                setPlay(editPlay(play, setting, value))
            }
            if (setting === 'undo') {
                setNewPoints(newPoints + 1)
            }

            const newPlayers = playerAttributesChange(players, activePlayer, value, activeLine, setting)
            setPlayers(newPlayers)
            setAnimOptions({
                play: false,
                loop: false,
            })

            setPlayerChanged(true)
        }

        const createPlayerMobile = (setting = false) => {
            const nid = getID(players);
            let player = !setting ? getPrototypeElement('player', id) : getPrototypeElement('shape', nid, setting);
            player.x = Math.random() * width;
            player.y = (width * canvasWithHeightRatio) / 5;
            setNewPlayer(true);
            setActivePlayer(player.id);
            setPlayers([...players, player]);
        }
        const [newPoints, setNewPoints] = useState(0)
        const handleClickDraw = (e, newPlayerEmbed = false) => {
            const ex = e.target.getStage().getPointerPosition().x;
            const ey = e.target.getStage().getPointerPosition().y;

            const sx = snapToGrid(1, ex);
            const sy = snapToGrid(1, ey);

            const coords = width < 1200 ? transformToGlobal([sx, sy], width) : [sx, sy];
            const x = coords[0];
            const y = coords[1];


            //new Line
            if (enabledDraw && (activePlayer !== null) && (previewActive[0] || newPlayerEmbed)) {

                if (!previewActive[1]) {

                    const newLines = createPreviewLines(players, activePlayer, x, y, lineConfig)

                    setPlayers(newLines)
                    setPreviewActive([true, true])
                    setPlayerChanged(true)
                } else {

                    const newLines = movePreviewLine(players, activePlayer, x, y)

                    setNewPoints(newPoints + 1)
                    setPlayers(newLines)
                    setPlayerChanged(true)
                }
            } else if (previewActive[0] && (topSettingActive === 'AP' || topSettingActive === 'AS' || topSettingActive === 'AT')) {
                //new Player

                if (!newPlayerPreview) {
                    const nid = getID(players);
                    let player = topSettingActive === 'AP' ? getPrototypeElement('player', nid) : getPrototypeElement('shape', nid, newShape);
                    player.x = x;
                    player.y = y;

                    const newPlayerTable = players ? players.concat(player) : [ player ];

                    setActivePlayer(player.id)
                    setNewPlayerPreview(true);
                    setPlayers(newPlayerTable);
                    setPlayerChanged(true)
                    setNewPlayer(true)

                } else {


                    const newPlayerTable = players.map(plr => {
                        if (plr.id === activePlayer) {
                            plr.x = x;
                            plr.y = y;
                            return plr
                        } else {
                            return plr
                        }
                    })
                    setPlayers(newPlayerTable)
                    setPlayerChanged(true)
                }

            } else if (!previewActive[0] && newPlayerEmbed && newPlayerPreview) {

                if (!changeNewShape) {
                    setNewPlayerPreview(false)
                    setPreviewActive([false, false])
                    setTopSettingActive(null)
                    setNewShape('')

                    setPlayerChanged(true)
                }
            }

        }
        const handleFlipHorizontally = () => {
            setPlayers(flipHorizontally(players));
            setPlayerChanged(true)
        }


        const removePlayer = () => {
            if (activePlayer !== null) {

                setPlayerToRemove(activePlayer);
                setActivePlayer(null);


            }
        }

        const stageRef = useRef(null);

        const [download, setDownload] = useState(false)

        const handleExport = () => setDownload(true);

        useEffect(() => {
            if (download) {
                setDownload(false);

                const uri = stageRef.current.toDataURL({ mimeType: 'image/jpeg' });
                downloadURI(uri, `${play.title}`);
            }

        }, [download])

        const handleChangePlayerWhileDrawing = enabledDraw => {

            if (!enabledDraw) {
                setEnabledDraw(enabledDraw);
            } else {
                if (topSettingActive === 'DL') {
                    setEnabledDraw(enabledDraw)
                }
            }
        }
        const canBeEdited = () => {
            if (width / 0.75 < 768 || (window.innerHeight / (width / 0.75) <= 0.5 && width / 0.75 < 900)) {
                return false
            }
            if (play.predefined) {
                return hasPredefinedPermission;
            } else {

                return auth.user?.id.toString() === play.user_id;
            }
        }


        useEffect(() => {
            fetchData()

        }, [])
        const [smImage, setSmImage] = useState(false);

        useEffect(() => {
            if (play !== null && players?.length) {
                const imageUri = stageRef?.current.toDataURL({ pixelRatio: 2 });
                if (activePlayer === null && play?.playImage !== imageUri) {
                    const updatedPlay = {
                        ...play,
                        playImage: imageUri,
                    }
                    setPlay(updatedPlay);
                }
            }

        }, [play, activePlayer, playerChanged])

        useEffect(async () => {
            if (play !== null && smImage) {
                const imageUri = stageRef.current.toDataURL({ pixelRatio: 2 });

                const updatedPlay = {
                    ...play,
                    printImage: imageUri,
                }
                setSmImage(false)
                setPlay(updatedPlay);
            } else if (play !== null) {
                await saveChanges(players, play);
                setSaveActive(false)
            }
        }, [smImage])

        useEffect(async () => {
            if (saveActive) {
                const imageUri = stageRef.current.toDataURL({ pixelRatio: 2 });
                if(imageUri !== basicImage) {
                    const updatedPlay = {
                        ...play,
                        playImage: imageUri,
                    }
                    setPlay(updatedPlay)
                }
                setSmImage(true)
            } else if (play !== null && players.length) {
                const imageUri = stageRef.current.toDataURL({ pixelRatio: 2 });
                if(imageUri !== basicImage) {
                    const updatedPlay = {
                        ...play,
                        playImage: imageUri,
                    }
                    setPlay(updatedPlay)
                }
            }
        }, [saveActive])

        useEffect(() => {
            if (!newPlayerPreview && activePlayer !== null && newPLayer) {
                const newPlayer = getActivePlayer();
                if (newPlayer) {
                    setNewPlayer(false)
                }
            }
        }, [newPlayerPreview, newPLayer])

        useEffect(async () => {
            if (playerToRemove !== null) {
                const newPlayers = players.filter(player => player.id !== playerToRemove)
                setPlayers(newPlayers)

                // await PlayersService.deletePlayer(playerToRemove)
                setPlayerToRemove(null);
            }

        }, [playerToRemove])

        useEffect(() => {
            if (saveToast) {
                setTimeout(() => {
                    setSaveToast(false)
                }, 5000)
            }
        }, [saveToast])

        //Animations
        const [animOptions, setAnimOptions] = useState({
            play: false,
            loop: false,
        })

        useEffect(() => {
            if (activePlayer !== null) {
                setAnimOptions({
                    play: false,
                    loop: false,
                })
            }
            if (changeNewShape) {
                setNewShapeChange(false)
            }
        }, [activePlayer])

        useEffect(() => {
            if (animOptions.play) {
                setActivePlayer(null)
            }
        }, [animOptions])

        return play !== null ? (
            <MuiThemeProvider theme={FfbTheme}>
                {saveToast && <SaveToast close={setSaveToast} shrink={playerEditorOpen}/>}
                <div style={{
                    backgroundColor: play.darkMode ? '#4D4D4D' : 'white',
                    height: '100vh',
                    overflow: canBeEdited() ? 'hidden' : 'scroll',
                }}
                     className={'hide-scrollbar'}>
                    <Header title={play.title}
                            nextPlay={nextPlay}
                            prevPlay={prevPlay}
                            switchAvailable={storage.length > 1}/>
                    <EditorDiv>
                        <div style={{ margin: '0 auto', display: 'grid', placeItems: 'center', height: '93vh' }}>
                            <PlayCanvas players={players}
                                        activePlayer={activePlayer}
                                        handleClickDraw={handleClickDraw}
                                        handleClickActivate={handleClickActivate}
                                        handlePlayerDrag={handlePlayerDrag}
                                        handleUpdateTextSize={handleUpdateTextSize}
                                        handleChangeTransform={handleChangeTransform}
                                        handleLinePreview={handleLinePreview}
                                        activeTopSetting={topSettingActive}
                                        stageRef={stageRef}
                                        editable={canBeEdited()}
                                        play={play}
                                        width={width}
                                        enableDraw={handleChangePlayerWhileDrawing}
                                        activeLine={activeLine}
                                        download={download}
                                        lineActivation={handleLineActivate}
                                        anim={animOptions}
                                        print={smImage}
                                        rp={newPoints}
                            />
                        </div>
                        {(playerEditorOpen && (canBeEdited())) &&
                            <PlayerToolbar playerSettingChange={playerSettingChange}
                                           player={getActivePlayer()}
                                           play={play}
                                           handleExport={handleExport}
                                           activeLine={activeLine}
                                           settingActive={topSettingActive}/>}
                    </EditorDiv>
                    {(canBeEdited()) && <TopToolbar shrink={playerEditorOpen}
                                                    settingActive={topSettingActive}
                                                    handleTopSettingChange={handleTopSettingChange}
                                                    playerSettingChange={playerSettingChange}
                                                    darkMode={play.darkMode}
                                                    removePlayer={removePlayer}
                                                    save={playerChanged}
                                                    saving={saveActive}
                                                    activePLayer={activePlayer}
                                                    baseLine={play.baseLineVisible}
                                                    goBack={goBack}
                                                    newShapeChange={changeNewShape}
                                                    setShapeChange={setNewShapeChange}
                                                    shape={newShape}
                                                    play={play}
                                                    handleExport={handleExport}
                                                    goingBack={goingBack}
                                                    player={getActivePlayer()}
                                                    width={width / 0.75}
                                                    anim={animOptions}
                                                    setAnim={setAnimOptions}/>}

                </div>
            </MuiThemeProvider>
        ) : <PlaymakerSpinnerContainer>
            <Loader/>
        </PlaymakerSpinnerContainer>;
    }
;


const PlaymakerSpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
