import { PlaysService } from "../../../../services/PlaysService";

export function sortByPlaybookOrderSwitch(a, b) {
    if (parseInt(a.play.order_playbook) < parseInt(b.play.order_playbook)) {
        return -1;
    }
    if (parseInt(a.play.order_playbook) > parseInt(b.play.order_playbook)) {
        return 1;
    }
    return 0;
}

export function sortByOrderSwitch(a, b) {
    if (parseInt(a.play.order) < parseInt(b.play.order)) {
        return -1;
    }
    if (parseInt(a.play.order) > parseInt(b.play.order)) {
        return 1;
    }
    return 0;
}

export const fetchAllPlays = async (pid, from) => {
    const id = from === 'playbook' ? pid : -2;
    const predefined = from === 'library';
    const plays = await PlaysService.getPlaysSwitch(id, predefined);
    return plays
}

export const fetchSiblingsPlay = async (pid, direction) => {
    return await PlaysService.getSiblingPlay(pid, direction);
}

export const getNextPrevPlay = async (storage, play, players, next = true) => {

    const new_play = await fetchSiblingsPlay(play.id, next ? 'next' : 'prev' );

    return {
        play: new_play,
        players: new_play.players,
        other: [...storage],
    }
}

export const getLastLocation = history =>{
    const pathParts = history.location.pathname.split('/');

    if(pathParts.includes('plays')){
        return 'plays'
    }else if(pathParts.includes('play-library')){
        return 'library'
    }else{
        return 'playbook'
    }
}
