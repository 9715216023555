import React from "react";
import { Grid } from "@material-ui/core";
import { LabelInput, StyledPaper, StyledToolsContainer } from "../StyledComponents/StyledComponents";
import { SectionHeader } from "./SectionParts/SectionHeader";
import { ColorPicker } from "./SectionParts/ColorPicker";

import T from "../svg/T.svg";
import { SettingButton } from "./SectionParts/SettingButton";
import Normal from "../svg/textFormat/Normal.svg";
import Bold from "../svg/textFormat/Bold.svg";
import Italic from "../svg/textFormat/Italic.svg";
import Underline from "../svg/textFormat/Underline.svg";
import { SettingSlider } from "./SectionParts/SettingSlider";


export const PlayerText = ({ player, settingChange, open, setOpen }) => {



    const textTable = ['normal', 'bold', 'italic', 'underline'];
    const iconTable = [Normal, Bold, Italic, Underline];

    return (
      <StyledToolsContainer>
            <Grid container spacing={0}>
                <SectionHeader text='TEXT' setOpen={setOpen} open={open} sectionName={'text'}/>

                {<>
                    <Grid item xs={12}>
                        <StyledPaper style={{ justifyContent: 'space-between', padding: '13px', borderWidth: '2px' }} >
                            <LabelInput type="text" value={player.label} onChange={event => settingChange('label', event.target.value)} placeholder='Type here'/>

                            <img src={T} alt='' style={{ marginRight: '.5rem' }}/>
                        </StyledPaper>
                    </Grid>
                    <Grid container style={{ marginTop: '1rem' }} spacing={0} > 
                            <ColorPicker player={player} settingChange={settingChange} setting={'labelFill'}/>
                            </Grid>

                    {player.shape !== 'text' || <>
                        <SectionHeader text='Format' />
                        {textTable.map((text, index)=>{

                            return  <SettingButton active={player.label_format===text} key={text} title={text} settingChange={settingChange} value={text}
                                                     setting={'format'} icon={iconTable[index]} xs={3}/>
                        })}

                        {player.shape==='text'&&<SettingSlider settingChange={settingChange} size={player.size} setting="size"
                                        text="FONT SIZE" currentValue={player.size}/>}
                    </>}
                </>}

            </Grid>
        </StyledToolsContainer>
    )
}