import React, { useEffect, useRef } from "react";
import { Circle, Group } from "react-konva";
import { Animation } from "konva/lib/Animation";

export const ActivatedPlayer = ({ player, radius }) => {

    const activeRef = useRef()

    useEffect(() => {
        if (player) {
            let period = 400;

            let anim = new Animation(frame => {
                activeRef.current.opacity((Math.sin(frame.time / period) + 1.25) / 6);

                // activeRef.current.opacity(period/frame.time);
                activeRef.current.radius(((Math.sin(frame.time / period) + 13 ) * 4* player.size ));

            }, activeRef);

            anim.start();
            return () => {
                anim.stop();
            };
        }

    }, [player])



    return (
        <Group>

                <Circle radius={radius}
                        ref={activeRef}
                        y={player?player.y : 20000}
                        x={player?player.x : 20000}
                        fill={player.shape !== 'ball' ? player.color : "#524100"}
                        stroke={player.shape !== 'ball' ? player.color : "#524100"}
                        />


        </Group>
    )
}
