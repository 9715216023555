export const angle = (player, points) => {
    const ey = points[points.length - 1]
    const ex = points[points.length - 2]
    const cy = points[points.length - 3]
    const cx = points[points.length - 4]

    let dy = ey - cy;
    let dx = ex - cx;
    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
}

export const lineArrowPoints = (player, points) => {
    let arrowAngle = angle(player, points)
    const mx = points[points.length - 2];
    const my = points[points.length - 1];
    const d = 16;
    let angle1 = arrowAngle + 135;
    let angle2 = arrowAngle - 135;

    angle1 = angle1 - 360;
    angle1 *= Math.PI / 180

    angle2 = angle2 - 360;
    angle2 *= Math.PI / 180

    const lx = mx + (d * Math.cos(angle1));
    const ly = my + (d * Math.sin(angle1));
    const rx = mx + (d * Math.cos(angle2));
    const ry = my + (d * Math.sin(angle2));


    return [lx, ly, mx, my, rx, ry]
};
export const getSectionLength = (sx, sy, ex, ey) => {
    const dx = ex - sx;
    const dy = ey - sy;

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

}
export const getCoordinates = (sx, sy, d, angle) => {
    const ex = sx + (d * Math.cos((angle - 360)*Math.PI/180));
    const ey = sy + (d * Math.sin((angle - 360)*Math.PI/180));

    return [ex, ey]
}

export const getWigglyLine = (player, points) => {
    const mainAngle = (angle(player, points));
    const mainLength = getSectionLength(points[points.length - 4], points[points.length - 3], points[points.length - 2], points[points.length - 1])
    let directionFlag = 0;
    const partLength = 20;
    const [sx, sy] = getCoordinates(points[points.length - 2], points[points.length - 1], mainLength-20, mainAngle-180);
    let returnCoordinates = [points[points.length - 4], points[points.length - 3], sx, sy];
    for(let i = 10; i<mainLength-20; i+=17) {
        if(mainLength-20-i<20){
            if (directionFlag===1){
                const [tx, ty] = getCoordinates(returnCoordinates[returnCoordinates.length - 2], returnCoordinates[returnCoordinates.length - 1], partLength/2, mainAngle - 40);
                returnCoordinates = [...returnCoordinates, tx, ty];
                directionFlag = -1;


            }else{
                const [tx, ty] = getCoordinates(returnCoordinates[returnCoordinates.length - 2], returnCoordinates[returnCoordinates.length - 1], partLength/2, mainAngle + 40);
                returnCoordinates = [...returnCoordinates, tx, ty];
                directionFlag = +1;

            }
        }else {
            if (directionFlag === 0) {
                const [tx, ty] = getCoordinates(sx, sy, partLength / 2, mainAngle + 20);
                returnCoordinates = [...returnCoordinates, tx, ty];
                directionFlag = 1;

            } else if (directionFlag > 0) {
                const [tx, ty] = getCoordinates(returnCoordinates[returnCoordinates.length - 2], returnCoordinates[returnCoordinates.length - 1], partLength, mainAngle - 40);
                returnCoordinates = [...returnCoordinates, tx, ty];
                directionFlag = -1;

            } else if (directionFlag < 0) {
                const [tx, ty] = getCoordinates(returnCoordinates[returnCoordinates.length - 2], returnCoordinates[returnCoordinates.length - 1], partLength, mainAngle + 40);
                returnCoordinates = [...returnCoordinates, tx, ty];
                directionFlag = 1;

            }
        }
    }

    return [...returnCoordinates, points[points.length-2], points[points.length-1]]

}


export const flipHorizontally = players => {
    const baseX = 600;

    return players.map(player => {
        player.x = baseX + (baseX - player.x);
        player.line_points = player.line_points.map((point, index) => {
            if (index % 2 === 0) {
                return  baseX + (baseX - point);

            } else {
                return point
            }


        });

        return player
    })
}


export const generateDashTable = (dashTable, points) => {

    let returnTable = [];

    dashTable?.forEach((dash, index) =>{

        if(dash ==='solid' || dash === 'wiggly'){
            const length = getSectionLength(points[index*2], points[index*2+1], points[index*2+2], points[index*2+3]);

            returnTable = [...returnTable, Math.floor(length), 0.001];

        }else if(dash!=='solid'&&dash!=='wiggly'){

            const length = getSectionLength(points[index*2], points[index*2+1], points[index*2+2], points[index*2+3]);

            for (let i = 0; i < length; i+=30) {
                if (dash==='dashed') {
                    returnTable = [...returnTable, 15, 15];
                }else{
                    returnTable = [...returnTable, 0.001, 10, 0.001, 10];


                }

            }
        }
    })




    return returnTable
}
