import React from "react";
import { StyledButton, StyledPaper, StyledTypography } from "../../StyledComponents/StyledComponents";
import { Grid, Slider } from "@material-ui/core";


export const SettingSlider = ({ size, settingChange, setting, currentValue, object='player' }) => {

   return (
   <Grid item xs={12}>
        <StyledPaper style={{ display: 'block', border: 'none', paddingTop: '1rem' }} blank>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                // width: '90%',
                alignItems: 'center',
                marginLeft: '2%',
            }}>
                <Slider  style={{ width: '80%' }}
                    min={setting === 'size'?0.5:1}
                    step={setting === 'size'?0.1:1}
                    max={setting === 'size'?2:8}
                    defaultValue={setting === 'size'?1:5}
                    value={currentValue}
                    onChange={(e, value) => settingChange(setting, value, object)}/>

                <StyledButton style={{ backgroundColor: '#4163ae', padding: '2px 0px', marginRight: 0, width: '48px', height: '36px' }}>
                    <StyledTypography variant={'p'}
                                      style={{ color: '#FFDB49', padding: '1rem' }}>
                        {size}
                    </StyledTypography>
                </StyledButton>
            </div>



        </StyledPaper>
    </Grid>)
}
