import React, { useState } from "react";
import { Arrow } from "react-konva";
import { ActiveLine } from "./ActiveLine";
import { generateDashTable, getWigglyLine } from "../../Helpers/LineHelper";
import { lineWithMultiplier } from "../../../constants";

export const ArrowLine = ({
                              player,
                              points,
                              endpoint,
                              dash,
                              activeLine,
                              lineActivation,
                              id,
                              dashTable = false,
                              lastPoints = null,
                              ratio,
                          }) => {
    const [hover, setHover] = useState(false)

    const getDashTable = () => {
        if (dash && dash !== 'solid' && dash !== 'wiggly' && dash !== 'long') {
            return dash === 'dot' ? [0.001, 10] : [15, 15]
        } else {
            if (dashTable) {

                return generateDashTable(dashTable, points)
            }
        }
    }
    const actualPoints = points => lastPoints === null ? [...points] : [...lastPoints, points[2], points[3]];

    return (
        <>
            <Arrow points={dash !== 'wiggly' ? actualPoints(points) : getWigglyLine(player, actualPoints(points) )}
                   stroke={player.stroke_fill !== '' ? player.stroke_fill : '#6949FF'}
                   strokeWidth={player.line_width * ratio * lineWithMultiplier}
                   lineCap={"round"}
                   tension={dash !== 'wiggly' ? player.line_tension : 0}
                   dashEnabled={(dash !== 'solid' && dash !== 'wiggly') || dash === 'long'}
                   dash={getDashTable()}
                   pointerLength={endpoint === 'arrow' ? 10 : 0}
                   pointerWidth={endpoint === 'arrow' ? 15 : 30}
                   fill={player.stroke_fill !== '' ? player.stroke_fill : '#6949FF'}
                   onMouseOver={() => setHover(true)}
                   onMouseOut={() => setHover(false)}
                   onClick={() => lineActivation(player.id, id)}
                   opacity={(hover || activeLine) ? 0.5 : 1}/>
            {activeLine && <ActiveLine player={player} points={actualPoints(points)}/>}
        </>
    )
}
