import React from "react";
import { RegularPolygon } from "react-konva";
import { angle } from "../../Helpers/LineHelper";

export const ReverseTriangleEndpoint = ({ player, points }) => {



    return(
        <RegularPolygon sides={3} radius={8}
                        y={points[points.length - 1]}
                        x={points[points.length - 2]}
                        stroke={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}
                        strokeWidth={5}
                        rotation={angle(player, points)-90}
                        fill={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}/>
    )
}