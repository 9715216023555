import React from "react";
import { Rect } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const SquarePlayer = ({
                                 player,
                                 handleClickActivate,
                                 activeTopSetting,
                                 handlePlayerDrag,
                                 editable,
                                 handleTarget,
                                 enableDraw,
                                 ratio,
                                 dm,
                                 movingPart,
                             }) => {

    return (

        <Rect width={basePlayerSize * 0.8 * player.size * ratio}
              height={basePlayerSize * 0.8 * player.size * ratio}
              y={player.y}
              x={player.x}
              offsetX={basePlayerSize * 0.8 * player.size * ratio / 2}
              offsetY={basePlayerSize * 0.8 * player.size * ratio / 2}
              fill={player.shading === 'full' ? player.color : dm}
              stroke={player.shading === 'full' ? 'transparent' : player.color}
              draggable={activeTopSetting !== 'DL' && editable}
              onMouseEnter={() => enableDraw(false)}
              onMouseLeave={() => enableDraw(true)}
              onClick={e => handleClickActivate(e)}
              onTouchEnd={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}
              anchor={player.id}
              rotation={player.shape === 'diamond' ? 45 : 0}
              ref={movingPart}
        />

    )
}
