import React from "react";
import { Grid } from "@material-ui/core";
import { SectionHeader } from "./SectionParts/SectionHeader";
import { SettingButton } from "./SectionParts/SettingButton";
import { SettingSlider } from "./SectionParts/SettingSlider";
import Solid from "../svg/drawingLine/Solid.svg";
import Dashed from '../svg/drawingLine/Dashed.svg';
import Dot from '../svg/drawingLine/Dot.svg';
import Wiggly from '../svg/drawingLine/Wiggly.svg';
// import LineA from '../svg/drawingLine/LineA.svg';
import Line from '../svg/drawingLine/Line.svg';
import ThickA from '../svg/drawingLine/ThickA.svg';
// import ReverseA from '../svg/drawingLine/ReverseA.svg';
import CircleA from '../svg/drawingLine/CircleA.svg';
// import RectA from '../svg/drawingLine/RectA.svg';
import Curve from '../svg/drawingLine/Curve.svg';
import NotCurve from '../svg/drawingLine/NotCurve.svg';
import { ColorPicker } from "./SectionParts/ColorPicker";

import Cross from "../svg/Cross.svg";
import { StyledBordered, StyledToolsContainer, StyledTypography } from "../StyledComponents/StyledComponents";


export const DrawingLine = ({ settingChange, player, activeLine, open, setOpen }) => {

    const isActive = value => {
        let answer = false
        if (activeLine !== null) {

            player.line_dash.forEach((dash, index) => {

                if (index === activeLine && dash === value) {
                    answer = true
                }
            })
            player.line_endpoint.forEach((endpoint, index) => {
                if (index === activeLine && endpoint === value) {
                    answer = true
                }
            })


        } else {
            if (player.line_dash[player.line_dash.length - 1] === value) {
                answer = true
            } else if (player.line_endpoint[player.line_endpoint.length - 1] === value) {
                answer = true
            }


        }
        return answer
    }

    const dashTable = ['solid', 'dashed', 'dot', 'wiggly'];
    const iconTable = [Solid, Dashed, Dot, Wiggly];

    const endpointTable = ['arrow',  'line', 'circle', 'none'];
    const iconTable2 = [ ThickA,  Line, CircleA, Cross];

    return (
        <StyledToolsContainer marginBottom={4}>

                <SectionHeader text="LINE" setOpen={setOpen} open={open} sectionName={'line'}/>

                {<>
                  <StyledBordered>
                  <Grid container spacing={0}  wrap={'nowrap'}>

                  {dashTable.map((dash, index) => {
                      return <SettingButton key={dash} active={isActive(dash)} title={dash} settingChange={settingChange} value={dash}
                                            setting={'dashed'} icon={iconTable[index]} />
                  })}

                  <SettingButton settingChange={settingChange} title={player.line_tension === 0.4 ? 'Curve' : 'NotCurve'} value={player.line_tension === 0.4 ? 0 : 0.4}
                                                active={player.line_tension !== 0.4 ? 0 : 0.4} setting={'tension'} icon={player.line_tension === 0.4 ? Curve : NotCurve} />

                  </Grid>
                  </StyledBordered>


                <StyledTypography>
                <StyledBordered>
                  <Grid container spacing={0}>

                {endpointTable.map((endpoint, index) => {

                    return <SettingButton key={endpoint} title={endpoint} active={isActive(endpoint)} settingChange={settingChange}
                                          value={endpoint} xs={3}
                                          setting={'endpoint'} icon={iconTable2[index]}/>
                })}

                </Grid>
                </StyledBordered>
                </StyledTypography>


                <StyledTypography>
                <StyledBordered>
                  <Grid container spacing={0}>

                    <ColorPicker player={player} settingChange={settingChange} setting={'strokeFill'}/>

                  </Grid>
                </StyledBordered>
                </StyledTypography>

                <SettingSlider settingChange={settingChange} size={player.line_width}
                    currentValue={player.line_width}
                    setting="thickness" text={'THICKNESS'}/>

                </>}
                    </StyledToolsContainer>
                    )
                }