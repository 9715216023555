import React, { useCallback, useEffect, useState } from 'react';
import { Row } from "../../theme/styledComponents";
import { Text22 } from "../atoms/Typography";
import Button from "../atoms/Button";
import TagsBlock from "../blocks/TagsBlock";
import Modal from "../molecules/Modal";
import { useFilters } from "../../hooks/useFilters";

const TagsModal = ({ show, setShow }) => {
  const { tags, setTags } = useFilters()
  const [state, setState] = useState([])

  const handleChange = useCallback((id, value) => {
    setState( value );
  }, [state])

  const handleClear = useCallback(() => {
    setTags([])
    setState([])
  }, [tags])

  const handleSave = useCallback(() => {
    setTags(state)
    setShow(false)
  }, [state])

  useEffect(() => {
    setState(tags)
  }, [tags])

  return (
    <Modal type="side" show={show} setShow={setShow}>
      <Row jc="space-between" margin="0 0 30px">
        <Text22>Filters</Text22>
        <Row>
          <Button type="third" margin="0 25px" onClick={handleClear}>
            Clear
          </Button>
          <Button width="max-content" onClick={handleSave}>
            Filter
          </Button>
        </Row>
      </Row>
      <TagsBlock value={state} onChange={handleChange}/>
    </Modal>
  );
}

export default TagsModal;
