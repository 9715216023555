export function setToken(token) {
    return window.localStorage.setItem('token', token)
}

export async function getToken() {
    return window.localStorage.getItem('token')
}

export async function deleteToken() {
    return window.localStorage.removeItem('token')
}
