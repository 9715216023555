import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Group, Layer, Line, Rect, Stage } from "react-konva";
import { Player } from "./CanvasGroups/Player";
import styled from "styled-components";
import { FieldMarkings } from "./CanvasGroups/FieldMarkings";
import { baseCanvasWidth, canvasWithHeightRatio } from "../constants";
import { transformToLocal, transformToPrint } from "./Helpers/DrawHelper";
import { getLongestAnimation, searchForSquigglyLine } from "./Helpers/animationHelper";

export const PlayCanvas = ({
                               handleLinePreview,
                               handlePlayerDrag,
                               handleUpdateTextSize,
                               handleClickDraw,
                               handleClickActivate,
                               handleChangeTransform,
                               players,
                               activePlayer,
                               activeTopSetting,
                               stageRef,
                               editable,
                               play,
                               activeLine,
                               lineActivation,
                               enableDraw,
                               download,
                               width,
                               anim,
                               print,
    rp,
                           }) => {
    const [stageDimensions, setDimensions] = useState({
        width: width,
        height: width * canvasWithHeightRatio,
    });

    useEffect(() => {
        setDimensions({
            width: width,
            height: width * canvasWithHeightRatio,
        })
    }, [width, print])

    const [targetActive, setTargetActive] = useState(false)
    const [target, setTarget] = useState([0, 0])

    const handleTarget = (e, drag = false) => {
        let x
        let y
        if (drag) {
            x = e.target.attrs.x
            y = e.target.attrs.y
        } else {
            x = e.target.getStage().getPointerPosition().x
            y = e.target.getStage().getPointerPosition().y
        }

        if (drag || activeTopSetting === 'DL') {
            if (drag) setTargetActive(true)

            setTarget([x, y])
        }
    }

    const getFieldMarkings = useCallback(reverse => {
        const step = reverse ? 70 : -70;
        let returnTable = [];
        if (!reverse) {
            for (let i = stageDimensions.height / 2; i > 0; i += step) {

                returnTable = [...returnTable, i];
            }
        } else {
            for (let i = stageDimensions.height / 2; i < stageDimensions.height; i += step) {

                returnTable = [...returnTable, i];
            }
        }
        return returnTable

    }, [play, width]);

    const getRatio = () => {
        if (stageDimensions.width >= 1200 && !print) {
            return 1
        } else {
            if (print) {
                return 1.3 * stageDimensions.width / baseCanvasWidth
            }
            return stageDimensions.width / baseCanvasWidth
        }
    }

    const upMarkings = getFieldMarkings(false);
    const downMarkings = getFieldMarkings(true);


    const getCoords = useCallback(() => {
        if (print) {
            return players.map(player => transformToPrint(transformToLocal(player, stageDimensions.width), stageDimensions.width));
        } else if (width >= 1200) {
            return players
        } else {
            return players.map(player => transformToLocal(player, stageDimensions.width));
        }
    }, [players, print, width, activePlayer, anim])

    const [localPlayers, setLocalPlayers] = useState(() => getCoords())

    useEffect(() => {
        setLocalPlayers(getCoords())
    }, [print, width, players, activePlayer, anim, activeTopSetting, activePlayer, rp])

    const loopDuration = useMemo(() => getLongestAnimation(localPlayers), [anim, localPlayers])

    // const loopDuration = anim.loop ? getLongestAnimation(localPlayers) : 0
    const delay = useMemo(() => searchForSquigglyLine(localPlayers), [localPlayers])
    const [playAnimation, setPlayAnimation] = useState(false)
    const [timeout, setTime] = useState(0);

    useEffect(() => {
        if (playAnimation !== anim.play) {
            setPlayAnimation(anim.play)
        }
        if(!anim.play && timeout !== 0){
            clearTimeout(timeout)
            setTime(0)
        }
    }, [anim])

    useEffect(()=>{
        if(playAnimation){
            const id = setTimeout(()=>setPlayAnimation(false), (loopDuration + delay) * 1000 + 300)
            setTime(id);
        }else{
            if(anim.play && anim.loop){
                const id = setTimeout(()=>setPlayAnimation(true), 500);
                setTime(id);
            }
        }
    }, [playAnimation])
    return (

        <StyledStage width={stageDimensions.width} height={stageDimensions.height} ref={stageRef}
                     darkMode={play.darkMode} mobile={editable}
                     onMouseMove={e => {
                         handleClickDraw(e);
                         handleTarget(e);
                     }}
                     onTouchMove={e => {
                         handleClickDraw(e);
                         handleTarget(e);
                     }}
                     onClick={e => handleClickDraw(e, true)}
                     onMouseUp={() => {
                         handleLinePreview([false, false]);
                         setTargetActive(false)
                     }}
                     onTouchEnd={() => {
                         handleLinePreview([false, false]);
                         setTargetActive(false)
                     }}
                     onMouseDown={e => {
                         handleClickDraw(e, true);
                         handleTarget(e);
                         if (activeTopSetting === 'DL') setTargetActive(true);
                     }}
                     onTouchStart={e => {
                         handleClickDraw(e, true);
                         handleTarget(e);
                         if (activeTopSetting === 'DL') setTargetActive(true);
                     }}
        >


            <Layer>
                {download && <Rect width={stageDimensions.width} height={stageDimensions.height}
                                   fill={play.darkMode ? '#4D4D4D' : 'white'}/>}
                {(play.fieldLines === 'yes') && upMarkings.map(marking => <FieldMarkings
                    dimensions={stageDimensions} y={marking} key={marking} dm={play.darkMode}/>)}
                {(play.fieldLines === 'yes') && downMarkings.map(marking => <FieldMarkings
                    dimensions={stageDimensions} reverse={true} y={marking} key={marking} dm={play.darkMode}/>)}
                {play.baseLineVisible && <Line
                    points={[0, stageDimensions.height / 2, stageDimensions.width, stageDimensions.height / 2]}
                    stroke={'#6e6e6e'}
                    strokeWidth={2}/>}
                {targetActive && <Line
                    points={[target[0], stageDimensions.height, target[0], 0]}

                    stroke={'#bebebe'}
                    strokeWidth={2}/>}
                {targetActive && <Line
                    points={[stageDimensions.width, target[1], 0, target[1]]}

                    stroke={'#bebebe'}
                    strokeWidth={2}/>}

            </Layer>

            <Layer>
                <Group>

                    { // eslint-disable-next-line
                        localPlayers?.map(player =>
                            <Player player={player}
                                    activePlayer={activePlayer}
                                    handleClickActivate={handleClickActivate}
                                    activeTopSetting={activeTopSetting}
                                    handlePlayerDrag={handlePlayerDrag}
                                    handleUpdateTextSize={handleUpdateTextSize}
                                    handleChangeTransform={handleChangeTransform}
                                    key={player.id}
                                    editable={editable}
                                    activeLine={activeLine}
                                    enableDraw={enableDraw}
                                    handleTarget={handleTarget}
                                    ratio={getRatio()}
                                    print={print}
                                    lineActivation={lineActivation}
                                    anim={anim}
                                    loop={loopDuration}
                                    pa={playAnimation}
                                    delay={delay}
                                    darkmode={play.darkMode}
                                    rp={rp}
                            />,
                        )}

                </Group>

            </Layer>


        </StyledStage>
    )
}

const StyledStage = styled(Stage)`
  background-color: ${props => props.darkMode ? '#4D4D4D' : 'white'};
  margin-top: ${({ editable }) => editable ? 10 : 1}vh
`
