import { Text13 } from "../../../components/atoms/Typography";
import styled from "styled-components";
import React from "react";
import Check from './svg/Check.svg'
import Cross from "./svg/Cross.svg";

export const SaveToast = ({ close, shrink }) => {

    return (
        <Toast shrink={shrink}>
            <AlertIcon><img src={Check} alt=""/></AlertIcon>
            <Text13 style={{ color: 'black' }}>Your play has been saved successfully</Text13>
            <CloseButton onClick={()=>close(false)}>
                <img src={Cross} alt="cross"/>
            </CloseButton>
        </Toast>)
}


const Toast = styled.div`
  position: absolute;
  display: flex;
  top: 10rem;
  right: ${props => props.shrink ? 'calc(25% + 6rem)':'6rem'};
  width: 20rem !important;
  height: 4rem;
  background-color: white;
  color: white;
  border: 1px solid #49FF80;
  margin: 0 !important;
  border-radius: 5px;
  z-index: 333;
  overflow: hidden;
  align-items: center;
`

const AlertIcon = styled.div`
  background-color: #49FF80;
  height: 100%;
  display: grid;
  place-items: center;
  padding: 1rem;
  margin-right: 1rem;
`

const CloseButton = styled.div`

margin-right: 1rem;
  height: 20%;
  display: grid;
  place-items: flex-start;
  img{
    height: 60%;
  }
`