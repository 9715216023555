import { Ellipse } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const OvalPlayer = ({
                               player,
                               handleClickActivate,
                               activeTopSetting,
                               handlePlayerDrag,
                               editable,
                               handleTarget,
                               enableDraw,
                               ratio,
                               movingPart,
                               dm,
                           }) => {

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Ellipse radiusX={basePlayerSize * player.size * ratio / 2}
                 radiusY={basePlayerSize * player.size * ratio / 3}
                 y={player.y}
                 x={player.x}
                 fill={player.shading === 'full' ? player.color : dm}
                 stroke={player.shading === 'full' ? 'transparent' : player.color}
                 draggable={activeTopSetting !== 'DL' && editable}
                 onMouseEnter={() => enableDraw(false)}
                 onMouseLeave={() => enableDraw(true)}
                 onClick={e => handleClickActivate(e)}
                 onTouchEnd={e => handleClickActivate(e)}
                 onDragMove={e => {
                     handlePlayerDrag(e);
                     handleTarget(e, true)
                 }}
                 ref={movingPart}
                 anchor={player.id}
        />)
}
