import React from "react";
import { Line } from "react-konva";
import { lineArrowPoints } from "../../Helpers/LineHelper";

export const LineArrowEndpoint = ({ player, points }) => {
  return(
      <Line points={lineArrowPoints(player, points)}
            stroke={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}
            strokeWidth={player.line_width}
            lineCap={"round" }
            fill={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}
            />

  )
}