export const theme = {
    'color':{
        'primary': '#FCC400',
        'secondary': '#FFFFFF',
        'third': '#0077FF',
        'fourth': '#404040',
        'background':'#F9F9F9',
        'border':'#F9F9F9',
        'text':'#000000',
        'white':'#fff',
        'black':'#000000',
        'orange':'#FFA049',
        'red':'#FF4949',
        'blue':'#4163ae',
        'lblue':'#0077FF',
        'yellow':'#FCC400',
    },
    'font':{
        'S':'13px',
        'M':'16px',
        'L':'18px',
        'XL':'22px',
        'XXL':'26px',

        'light':400,
        'normal':600,
        'bold':700,
    },
}