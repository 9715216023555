import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Text18 } from "../atoms/Typography";
import { NavLink, useHistory } from "react-router-dom";
import Dropdown, { DropdownButton } from "../atoms/Dropdown";

import MORE_MENU_ICON from '../../assets/icons/more_menu.svg'
import { useHasPermission } from "../../hooks/useHasPermission";
import { useDispatch } from "react-redux";
import { setPrint } from "../../redux/actions";
import DeleteModal from "../modals/DeleteModal";
import { getLastLocation } from "../../pages/PlayMaker/Components/Helpers/switchPlayHelper";
import ShareModal from "../modals/ShareModal";

export const PlayLoader = () => {
    return (
        <LoaderWrapper className="gradient-animation"/>
    );
}

export const PlayButton = ({ onClick }) => {

    return (
        <Button className="main-button" onClick={onClick}>
            <Text18>+ new play</Text18>
        </Button>
    );
}

const PlayBlock = ({ item, onEdit, onDelete, onDuplicate, order_id, predefined, onUseTemplate }) => {
    const moreRef = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const hasPermission = useHasPermission("predefined")
    const [showMore, setShowMore] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { id, playImage, title, darkMode } = item
    const [showShareModal, setShowShareModal] = useState(false)

    const to = `/app/${id}?from=${getLastLocation(history)}&title=${title}`

    const handleShow = useCallback(() => {
        setShowMore(true)
    }, [])

    const handleUseTemplate = useCallback(() => {
        onUseTemplate(item);
        setShowMore(false);
    }, [] );

    const handleClose = useCallback(() => {
        setShowMore(false)
    }, [])

    const handleOpen = useCallback(() => {
        history.push(to)
    }, [])

    const handleClickDelete = useCallback(() => {
        setShowMore(false)
        setShowDeleteModal(true)
    }, [])

    const handleDelete = useCallback(() => {
        setShowDeleteModal(false)
        onDelete(item)
    }, [onDelete])

    const handleDuplicate = useCallback(() => {
        onDuplicate(item, order_id );
        setShowMore(false)
    }, [onDuplicate])

    const handleShare = useCallback(() => {
        setShowMore(false)
        setShowShareModal(true)
    }, [])

    const handleEdit = useCallback(() => {
        onEdit(item)
        setShowMore(false)
    }, [item, onEdit])

    const handlePrint = useCallback(() => {
        dispatch(setPrint({
            type: 'play',
            play: item,
        }))
        setShowMore(false)
    }, [])

    return (
        <>
            <Wrapper>
                <Link to={to}>
                    <PlayWrapper dark={darkMode}>
                        {playImage && <PlayImage src={playImage} loading="lazy"/>}
                        {!playImage && <PlayImageBlank/>}
                    </PlayWrapper>
                    <Text18 className={"dnd-block-title"} margin="10px 0 0px" title={title}>{title}</Text18>
                </Link>
                <MoreIcon ref={moreRef} onClick={handleShow}>
                    <img src={MORE_MENU_ICON}/>
                </MoreIcon>
            </Wrapper>
            <Dropdown
                open={showMore}
                refProp={moreRef}
                onClose={handleClose}
            >
                <DropdownButton text="Open" onClick={handleOpen}/>
                <DropdownButton text="Share" onClick={handleShare}/>
                <DropdownButton text="Print" onClick={handlePrint}/>
                {
                    ((predefined && hasPermission) || !predefined) && onDuplicate &&
                    <DropdownButton text="Duplicate" onClick={handleDuplicate}/>
                }
                {
                    ((predefined && hasPermission) || !predefined) && onEdit &&
                    <DropdownButton text="Edit" onClick={handleEdit}/>
                }
                {
                    ((predefined && hasPermission) || !predefined) && onDelete &&
                    <DropdownButton text="Delete" onClick={handleClickDelete}/>
                }
                {
                    predefined && onUseTemplate &&
                    <DropdownButton text="Copy to My Plays" onClick={handleUseTemplate}/>
                }
            </Dropdown>
            <DeleteModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                text="Are you sure you want to delete this play?"
                onDelete={handleDelete}
            />
            <ShareModal
                show={showShareModal}
                setShow={setShowShareModal}
                text="You can share this play with link"
                link={`${window.location.origin}${to}`}
            />
        </>
    );
}

export default PlayBlock;

const MoreIcon = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  cursor: pointer;
  background: none;
  border: none;
  img{
    width: 5px;
  }

  @media (max-width: 1200px){
    right: 10px;
    top: 10px;
  }
  `;

const LoaderWrapper = styled.div`
  // background: ${({ theme }) => theme.color.black};
  border: 2px solid ${({ theme }) => theme.color.black};
  min-height: 190px;
  opacity: 0.5;
  border-radius: 15px;
  overflow: hidden;
`;

export const Number = styled.span`
    
  `;

export const PlayWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 150px;
  margin-bottom: 1rem;
  background: white;
  border-radius: 5px;
  `;

export const PlayImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PlayImageBlank = styled.div`
  width: 185px;
  height: 100%;
  background: #FFFFFF;
`;

const base = css`
  padding: 3rem 2rem 1.2rem 2rem;
  background: ${({ theme }) => theme.color.third};
  border: 2px solid ${({ theme }) => theme.color.third};
  border-radius: 15px;
  transition: border .3s;
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  
  &:hover {
    border: 2px solid ${({ theme }) => theme.color.primary};
    box-shadow: 0px 0px 20px -10px ${({ theme }) => theme.color.primary};
  }
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: left;
  ${base};
  padding: 2rem;
`;

const Link = styled(NavLink)`
  width: 100%;
`;

const Wrapper = styled.div`
  ${base}
`;
