import React from "react";
import { Rect } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const ShadedSquarePlayer = ({
                                       player,
                                       handleClickActivate,
                                       activeTopSetting,
                                       handlePlayerDrag,
                                       editable,
                                       handleTarget,
                                       enableDraw,
                                       dm,
                                       ratio,
                                       movingPart,
                                   }) => {
    const getGradientPoints = (start = true) => {
        if (player.shape !== 'diamond') {
            return start ? {
                x: player.shading === 'HL' ? 55 * player.size : 0,
                y: player.shading === 'HB' ? 55 * player.size : 0,
            } : {
                x: player.shading === 'HR' ? 55 * player.size : 0,
                y: player.shading === 'HT' ? 55 * player.size : 0,
            }
        } else {
            if (start) {
                switch (player.shading) {
                    case 'HT':
                        return {
                            x: 0,
                            y: 0,
                        }
                    case 'HB':
                        return {
                            x: 55 * player.size,
                            y: 55 * player.size,
                        }
                    case 'HR':
                        return {
                            x: 0,
                            y: 55 * player.size,
                        }
                    case 'HL':
                        return {
                            x: 55 * player.size,
                            y: 0,
                        }
                    default:
                        break
                }
            } else {
                switch (player.shading) {
                    case 'HT':
                        return {
                            x: 55 * player.size,
                            y: 55 * player.size,
                        }
                    case 'HB':
                        return {
                            x: 0,
                            y: 0,
                        }
                    case 'HR':
                        return {
                            x: 55 * player.size,
                            y: 0,
                        }
                    case 'HL':
                        return {
                            x: 0,
                            y: 55 * player.size,
                        }
                    default:
                        break
                }
            }
        }
    }


    return (

        <Rect width={basePlayerSize * 0.8 * ratio * player.size}
              height={basePlayerSize * 0.8 * ratio * player.size}
              y={player.y}
              x={player.x}
              offsetX={basePlayerSize * 0.8 * ratio * player.size / 2}
              offsetY={basePlayerSize * 0.8 * ratio * player.size / 2}
              fillPriority="linear-gradient"
              stroke={player.color}
              strokeWidth={1}
              onMouseEnter={() => enableDraw(false)}
              onMouseLeave={() => enableDraw(true)}
              fillLinearGradientStartPoint={getGradientPoints()}
              fillLinearGradientEndPoint={getGradientPoints(false)}

              fillLinearGradientColorStops={[0, player.color, 0.49, player.color, .5, dm, 1, dm]}
              draggable={activeTopSetting !== 'DL' && editable}
              onClick={e => handleClickActivate(e)}
              onTouchEnd={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}
              ref={movingPart}
              anchor={player.id}
              rotation={player.shape === 'diamond' ? 45 : 0}/>

    )
}
