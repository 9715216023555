import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Text16 } from "./Typography";

import TICK from '../../assets/icons/tick.svg'
import ARROW from '../../assets/icons/left.svg'

const Tags = ({ options, index, label, value = [], onChange, margin, children, active, setActive }) => {

    const toggleRow = useCallback(e => {
        if(!value.includes(e)){
            const arr = [...value, e]
            onChange(arr)
        }else {
            const arr = value.filter(el => el !== e)
            onChange(arr)
        }
    }, [value, onChange])

    return (
        <Wrapper margin={margin} onClick={() => setActive(active === index ? null : index)}>
            <Header>
                <Text16>{label}</Text16>
                <Arrow active={active === index} src={ARROW}/>
            </Header>
          {
            active === index &&
            <Content onClick={e => e.stopPropagation()}>
              {
                options?.map((el, index) =>
                  <Row key={index} onClick={() => toggleRow(el.id)}>
                    {value?.includes(el.id) && <Tick src={TICK}/>}
                    <Text16>{el.title}</Text16>
                  </Row>,
                )
              }
              {children}
            </Content>
          }
        </Wrapper>
    );
}

export default Tags;

export const Arrow = styled.img`
  transform: rotate(-90deg);
  transition: transform 0.3s;
  
  ${({ active }) => active && css`
    transform: rotate(90deg);
  `}
  `;

export const Tick = styled.img`
  position: absolute;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  `;

const Row = styled.div`
  position: relative;
  background: ${({ theme }) => theme.color.white};
  padding: 15px 35px;
  padding-left: 60px;
  margin-bottom: 2px;
  border: 0px;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  p {
    color: ${({ theme }) => theme.color.lblue};
  }
`;

const Content = styled.div`
  
  `;

const Header = styled.div`
  padding: 15px 35px;
  background: ${({ theme }) => theme.color.third};
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `;

const Wrapper = styled.div`
  overflow: hidden;
  margin: ${({ margin }) => margin || "0 0 20px"};
    `;
