import { Group, Line } from "react-konva";
import React from "react";


export const FieldMarkings = ({ dimensions, y, reverse= false, dm = true }) => {
    const length = 70;
    const strokeColor = dm?'#7c7c7c' : '#4f4f4f';
if(!reverse) {
    return (
        <Group>
            <Line points={[0, y - length / 5, 50, y - length / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y - length / 5, dimensions.width, y - length / 5]}
                  stroke={strokeColor} strokeWidth={1}/>

            <Line points={[0, y - length * 2 / 5, 50, y - length * 2 / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y - length * 2 / 5, dimensions.width, y - length * 2 / 5]}
                  stroke={strokeColor} strokeWidth={1}/>

            <Line points={[0, y - length * 3 / 5, 50, y - length * 3 / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y - length * 3 / 5, dimensions.width, y - length * 3 / 5]}
                  stroke={strokeColor} strokeWidth={1}/>

            <Line points={[0, y - length * 4 / 5, 50, y - length * 4 / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y - length * 4 / 5, dimensions.width, y - length * 4 / 5]}
                  stroke={strokeColor} strokeWidth={1}/>


            <Line points={[0, y - length, 100, y - length]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 100, y - length, dimensions.width, y - length]} stroke={strokeColor}
                  strokeWidth={1}/>
            <Line points={[0, y - length, dimensions.width, y - length]} stroke={strokeColor} strokeWidth={0.5}/>
        </Group>
    )
}else{
    return (
        <Group>
            <Line points={[0, y + length / 5, 50, y + length / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y + length / 5, dimensions.width, y + length / 5]}
                  stroke={strokeColor} strokeWidth={1}/>

            <Line points={[0, y + length * 2 / 5, 50, y + length * 2 / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y + length * 2 / 5, dimensions.width, y + length * 2 / 5]}
                  stroke={strokeColor} strokeWidth={1}/>

            <Line points={[0, y + length * 3 / 5, 50, y + length * 3 / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y + length * 3 / 5, dimensions.width, y + length * 3 / 5]}
                  stroke={strokeColor} strokeWidth={1}/>

            <Line points={[0, y + length * 4 / 5, 50, y + length * 4 / 5]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 50, y + length * 4 / 5, dimensions.width, y + length * 4 / 5]}
                  stroke={strokeColor} strokeWidth={1}/>


            <Line points={[0, y + length, 100, y + length]} stroke={strokeColor} strokeWidth={1}/>
            <Line points={[dimensions.width - 100, y + length, dimensions.width, y + length]} stroke={strokeColor}
                  strokeWidth={1}/>
            <Line points={[0, y + length, dimensions.width, y + length]} stroke={strokeColor} strokeWidth={0.5}/>
        </Group>
    )
}
}
