import { APIService } from "./APIService";

const getPlaybooks = async page => {
    if(page){
        return await APIService.get(`/ffb/playbooks/?per_page=10&page=${page}`)
    }
    return await APIService.get(`/ffb/playbooks/`)
}

const addPlaybook = async playbook => {
    return await APIService.post('/ffb/playbooks', playbook)
}

const editPlaybook = async playbook => {
    return await APIService.patch(`/ffb/playbooks/${playbook.id}`, playbook)
}

const deletePlaybook = id => APIService.delete(`/ffb/playbooks/${id}`)

const movePlaybooks = () => APIService.patch(`/ffb/plays/move/?type=playbook`);

const updatePlaybooksOrder = async body => {
    return await APIService.patch('/ffb/playbooks/order', body)
}

const duplicatePlaybook = id => APIService.patch(`/ffb/playbooks/${id}/?duplicate=true`);

export const PlaybooksService = {
    getPlaybooks,
    addPlaybook,
    editPlaybook,
    movePlaybooks,
    deletePlaybook,
    updatePlaybooksOrder,
    duplicatePlaybook,
}
