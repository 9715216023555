import React from "react";
import { Circle } from "react-konva";
import { basePlayerSize } from "../../../constants";


export const CirclePlayer = ({
                                 player,
                                 handleClickActivate,
                                 handlePlayerDrag,
                                 activeTopSetting,
                                 editable,
                                 handleTarget,
                                 enableDraw,
                                 ratio,
                                 dm,
                                 movingPart,
                             }) => {


    return (

        <Circle radius={basePlayerSize * ratio * player.size / 2}
                y={player.y}
                x={player.x}
                ref={movingPart}
                onMouseEnter={() => enableDraw(false)}
                onMouseLeave={() => enableDraw(true)}
                fill={player.shading === 'full' ? player.color : dm}
                stroke={player.shading === 'full' ? 'transparent' : player.color}
                draggable={(activeTopSetting !== 'DL' && editable)}
                onClick={e => handleClickActivate(e)}
                onTouchEnd={e => handleClickActivate(e)}
                onDragMove={e => {
                    handlePlayerDrag(e);
                    handleTarget(e, true)
                }}
                anchor={player.id}/>


    )
}
