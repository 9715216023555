import React, { useState } from "react";
import { Circle, Rect } from "react-konva";

export const AdditionalLine = ({
                                   player,
                                   activeTopSetting,
                                   editable,
                                   handleClickActivate,
                                   handlePlayerDrag,
                                   handleTarget,
                                   enableDraw,
                                   activePLayer,
                                   ratio,
                                   print,
                               }) => {

    const [hover, setHover] = useState(false);
    if (print) {
        return null
    }

    return (<>
        <Circle x={player.x} y={player.y} radius={30 * ratio} fill="transparent" stroke="#000" dash={[10, 10]}
                draggable={(activeTopSetting !== 'DL' && editable)}
                opacity={!(hover || activePLayer === player.id) ? 0.2 : 1}/>
        <Circle x={player.x} y={player.y} radius={10 * ratio} fill="white" stroke="#000"
                draggable={(activeTopSetting !== 'DL' && editable)}
                opacity={!(hover || activePLayer === player.id) ? 0 : 1}/>
        <Circle x={player.x} y={player.y} radius={6 * ratio} fill="black"
                draggable={(activeTopSetting !== 'DL' && editable)}
                opacity={!(hover || activePLayer === player.id) ? 0 : 1}/>
        <Rect width={70 * player.size * ratio}
              height={70 * player.size * ratio}
              y={player.y}
              x={player.x}
              offsetX={70 * player.size * ratio / 2}
              offsetY={70 * player.size * ratio / 2}
              fill={player.shading === 'full' ? player.color : 'white'}
              draggable={activeTopSetting !== 'DL' && editable}
              onMouseEnter={() => {
                  enableDraw(false);
                  setHover(true)
              }}
              onMouseLeave={() => {
                  enableDraw(true);
                  setHover(false);
              }}
              onClick={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}

              onTouchEnd={e => handleClickActivate(e)}
              anchor={player.id}
              rotation={player.shape === 'diamond' ? 45 : 0}
              opacity={0}/>

    </>)
}
