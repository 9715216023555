import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PlaybooksService } from "../../services/PlaybooksService";
import { Row } from "../../theme/styledComponents";
import { Text22 } from "../atoms/Typography";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Radio from "../atoms/Radio";
import Modal from "../molecules/Modal";

const EditPlayBookModal = ({ show, setShow, data, refresh }) => {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            title: data?.title,
        },
    })

    const title = watch('title')
    const [state, setState] = useState({} )

    const handleHideModal = useCallback(() => {
        setShow(false)
    }, [])

    const checkKeyDown = e => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const onSubmit = useCallback(async el => {
        if (loading)
            return

        setLoading(true)
        await PlaybooksService.editPlaybook({
            ...data,
            ...el,
            ...state,
        })
        setLoading(false)
        setShow(false)
        refresh()
    }, [data, state, loading, refresh]);

    const isValid = title?.replace(/\s/g, '').length > 0

    useEffect(() => {
        if(data){
            setValue('title', data.title);
        }
    }, [data])

    return (
        <Modal show={show} setShow={setShow} type="side" maxWidth="500px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => checkKeyDown(e)}>
                <Row jc="space-between" margin="0 0 30px">
                    <Text22>Edit Playbook</Text22>
                    <Row>
                        <Button type="third" margin="0 25px" onClick={handleHideModal}>
                            Cancel
                        </Button>
                        <Button width="max-content" disabled={!isValid} loading={loading}>
                            Save
                        </Button>
                    </Row>
                </Row>
                {/*<Input margin="0 0 40px" label='Team selection' error={errors.team} rhf={register('team', { required: true })}/>*/}
                <Input margin="0 0 40px" label='Playbook title' error={errors.title}
                       rhf={register('title', { required: true })}/>
            </form>
        </Modal>
    )
}

export default EditPlayBookModal;
