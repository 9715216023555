import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import update from "immutability-helper";
import { useDrag, useDrop } from "react-dnd";
import { PlaysService } from "../../../services/PlaysService";
import PlayBlock from "../../../components/blocks/PlayBlock";
import { sortByPlaybookOrder } from "../../../utils/arrayHelpers";
import AddPlayModal from "../../../components/modals/AddPlayModal";
import EditPlayModal from "../../../components/modals/EditPlayModal";

const ItemTypes = {
    CARD: 'card',
}

const PlaybookElement = ({ id, index, item, moveCard, onEdit, onDelete, onDuplicate }) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));


    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
            <PlayBlock key={item.id} item={item} onEdit={onEdit} onDuplicate={onDuplicate} onDelete={onDelete}/>
        </div>
    )
}

const PlaybookContainer = ({ show, setShow, children, items = [], setItems, fetchData, playbookId }) => {
    const timeoutId = useRef()
    const [data, setData] = useState(items)
    const [editModal, setEditModal] = useState(false)

    useEffect(() => {
        setData(items)
    }, [items])

    const moveItem = useCallback((dragIndex, hoverIndex) => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current)
        }

        const dragCard = data[dragIndex]
        const newData = update(data, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })
        setData(newData);

        timeoutId.current = setTimeout(async () => {
            const res = await PlaysService.updatePlaybookPlaysOrder(playbookId, newData.map((el, index) => {
                return {
                    id: el.id,
                    order_playbook: index,
                }
            }))
            setItems(res.sort(sortByPlaybookOrder))
        }, 1500)
    }, [data, playbookId]);

    const handleDeletePlay = useCallback(item => {
        const tmp = items.filter(el => el.id !== item.id)
        setItems(tmp)
        PlaysService.deletePlay(item.id)
    }, [items])

    const handleDuplicatePlay = useCallback(async item => {
        const duplicatedPlay = await PlaysService.duplicatePlay(item.id)
        const newPlays = [...items, duplicatedPlay]
        setItems(newPlays)
    }, [items])

    const handleEdit = useCallback(el => {
        setEditModal(el)
    }, [])

    const renderCard = (item, index) => {
        return <PlaybookElement key={item.id} index={index} id={item.id} item={item} moveCard={moveItem} onEdit={handleEdit} onDelete={handleDeletePlay} onDuplicate={handleDuplicatePlay}/>
    };

    return (
        <>
            <Wrapper>
                {children}
                {data?.map((item, i) => renderCard(item, i))}
            </Wrapper>
            <AddPlayModal show={show} setShow={setShow} playbookId={playbookId} refresh={fetchData}/>
            {editModal && <EditPlayModal show={editModal !== false} setShow={setEditModal} data={editModal} refresh={fetchData}/>}
        </>
    );
}

export default PlaybookContainer;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;

  @media (max-width: 1200px){
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  @media (max-width: 800px){
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  @media (max-width: 600px){
    grid-template-columns: 1fr;
  }
`;
