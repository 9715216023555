import React from "react";
import { Circle } from "react-konva";
import { basePlayerSize } from "../../../constants";


export const ShadedCirclePlayer = ({
                                       player,
                                       handleClickActivate,
                                       activeTopSetting,
                                       handlePlayerDrag,
                                       editable,
                                       handleTarget,
                                       enableDraw,
                                       dm,
                                       ratio,
                                       movingPart,
                                   }) => {

    const getStartPoints = shading => {
        switch (shading) {
            case 'HT':
                return { x: 0, y: -30 }
            case 'HB':
                return { x: 0, y: 30 }
            case 'HR':
                return { x: -30, y: 0 }
            case 'HL':
                return { x: 30, y: 0 }
            default:
                return null
        }
    }
    const getEndPoints = shading => {
        switch (shading) {
            case 'HT':
                return { x: 0, y: 30 }
            case 'HB':
                return { x: 0, y: -30 }
            case 'HR':
                return { x: 30, y: 0 }
            case 'HL':
                return { x: -30, y: 0 }
            default:
                return null
        }
    }

    return (

        <Circle radius={basePlayerSize * ratio * player.size / 2}
                y={player.y}
                x={player.x}
                stroke={player.color}
                strokeWidth={1}
                onMouseEnter={() => enableDraw(false)}
                onMouseLeave={() => enableDraw(true)}
                fillLinearGradientStartPoint={getStartPoints(player.shading)}
                fillLinearGradientEndPoint={getEndPoints(player.shading)}
                fillLinearGradientColorStops={[0, player.color, 0.49, player.color, .5, dm, 1, dm]}
                draggable={activeTopSetting !== 'DL' && editable}
                onClick={e => handleClickActivate(e)}
                onTouchEnd={e => handleClickActivate(e)}
                onDragMove={e => {
                    handlePlayerDrag(e);
                    handleTarget(e, true)
                }}
                ref={movingPart}
                anchor={player.id}/>


    )
}
