import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  justify-content: ${({ jc }) => jc || 'center'};
  align-items: ${({ ai }) => ai || 'center'};
  margin: ${({ margin }) => margin};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ gtc }) => gtc || '1fr 1fr'};
  grid-gap: ${({ gg }) => gg || '20px'};
  margin: ${({ margin }) => margin};
`;