import React, { useEffect, useCallback } from 'react';
import { SortableContainer, SortableHandle, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from "array-move";
import styled from "styled-components";
import { PlaysService } from "../../services/PlaysService";
import { PlaybooksService } from "../../services/PlaybooksService";
import { useDispatch, useSelector } from "react-redux";

const DragHandle = SortableHandle(() => <div className="drag-handle">:::</div>);

const SortableItem = SortableElement(props => {
    const { value: item, renderItem, order_id } = props;
    return (
        <StyledItem key={item.id}>
            <div className={"content item-id-" + item.id + ' order-id-' + order_id}>
                <DragHandle />
                {renderItem(item, order_id)}
            </div>
        </StyledItem>
    );
});

const SortableList = SortableContainer(props => {
    const { items, renderItem, mainButton, ...restProps } = props;

    return (
        <StyledContainer>
            <StyledItem>
                {mainButton}
            </StyledItem>
            {items.map((item, index) => (
                <SortableItem
                    key={`item-${item.id}`}
                    index={index}
                    order_id={index}
                    renderItem={renderItem}
                    value={item}
                    {...restProps}
                />
            ))}
        </StyledContainer>
    );
});

const Dnd = ({ items, setItems, renderItem, mainButton, type }) => {

    const dispatch = useDispatch();
    const activeFilters = useSelector(store => store.content.activeFilters );

    // dispatch( getPlaybooks(playbooks) );
    const onSortEnd = useCallback(( { oldIndex, newIndex } ) => {
        setItems(items => {
            console.log( type );
            if( type === 'playbooks' ){
                PlaybooksService.updatePlaybooksOrder(
                    {
                        old_index: oldIndex,
                        new_index: newIndex,
                    }).then(r => {});
            } else {
                PlaysService.updatePlaysOrder(oldIndex, newIndex).then(r => {});
            }
            return arrayMoveImmutable(items, oldIndex, newIndex);
        });
    }, [ setItems ]);

    useEffect(() => {

        if( activeFilters.search.length || activeFilters.tags.length ) {
            console.log ('true!');
        }

    }, [activeFilters]);

    return (
        <Container>
            <SortableList
                axis="xy"
                items={items}
                renderItem={renderItem}
                mainButton={mainButton}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                useWindowAsScrollContainer={true}
            /></Container>
    );
}

const StyledItem = styled.div`
  float: none;
  width: 100%;
  flex: 1;
  z-index: 100;
  min-height: 190px;
  position: relative;
  
  .drag-handle {
    position: absolute;
    color: ${({ theme }) => theme.color.white};
    cursor: grab;
    width: 50px;
    height: 50px;
    font-weight: bold;
    font-size: 21px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease-in-out;
    
    &:hover,
    button:hover{
      color: #FCC400;
    }
  }

  .dnd-block-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .main-button {
    width: 100%;
    height: 208px;
    display: flex;
    justify-content: center;
    align-items: start;
  }
`;

const StyledContainer = styled.div`
  display: grid;
  max-width: 100%;
  grid: auto-flow dense / 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  &:after {
    content: "";
    clear: both;
    display: table;
  }

  @media screen and (max-width: 1200px) {
    grid: auto-flow dense / 1fr 1fr 1fr;
  }

  @media screen and (max-width: 800px) {
    grid: auto-flow dense / 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid: auto-flow dense / 1fr;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export default Dnd;
