import React from 'react';
import styled, { css } from 'styled-components';

const Select = ({ margin, error, label, rhf, options = []}) => {
    const { ref, ...rest } = rhf

    return (
        <Wrapper margin={margin}>
            <Label>{label}</Label>
            <SSelect error={error} ref={ref} {...rest}>
                {
                    options.map(option =>
                        <option key={option[0]} value={option[0]}>{option[1]}</option>,
                    )
                }
            </SSelect>
        </Wrapper>
    );
}

export default Select;

const SSelect = styled.select`
  display: block;
  font-size: ${({ theme }) => theme.font.L};
  font-weight: ${({ theme }) => theme.font.bold};
  width: 100%;
  padding: 13px 15px;
  background: ${({ theme }) => theme.color.fourth};
  border: 0px;
  color: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.color.fourth};
  
  ${({ error }) => error && css`
    border: 2px solid ${({ theme }) => theme.color.red};
  `};

  &:focus {
    outline-width: 0;
  }
  
  &:focus{
    border: 2px solid ${({ theme }) => theme.color.primary} !important;
  }
  `;

const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.white};
  margin: 0 0 10px;
  `;

const Wrapper = styled.div`
    margin: ${({ margin }) => margin || "0 0 20px"};
    `;
