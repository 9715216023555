import React from 'react';
import styled from 'styled-components';
import Header from "../../components/shared/Header";


const PanelView = ({ children }) => {

    return (
        <Wrapper>
            <Header/>
            <Content>
                {children}
            </Content>
        </Wrapper>
    );
}

export default PanelView;

const Content = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0px auto;
  margin-top: 20px;
  padding-bottom: 50px;

  @media (max-width: 800px){
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
  }
  `;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
