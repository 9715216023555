import React from 'react';
import PanelView from "../../templates/PanelView/PanelView";
import { Redirect, Route, Switch } from "react-router-dom";
import MainView from "./MainView/MainView";
import Playbook from "./Playbook/Playbook";
import FiltersProvider from "../../providers/FiltersProvider";
import LoadingProvider from "../../providers/LoadingProvider";

const Panel = () => {

  return (
    <LoadingProvider>
      <FiltersProvider>
        <PanelView>
          <Switch>
            <Route exact path={['/panel/playbooks', '/panel/plays', '/panel/play-library']}>
              <MainView/>
            </Route>
            <Route exact path="/panel/playbooks/:id">
              <Playbook/>
            </Route>
            <Route>
              <Redirect to="/panel/playbooks"/>
            </Route>
          </Switch>
        </PanelView>
      </FiltersProvider>
    </LoadingProvider>
  );
}

export default Panel;
