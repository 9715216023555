import { angle, getCoordinates, getSectionLength } from "./LineHelper";

export const getTangentLength = (points, arcLength) => {

    const length1 = Math.floor(getSectionLength(points[2], points[3], points[4], points[5]));
    const length2 = Math.floor(getSectionLength(points[0], points[1], points[2], points[3]));
    if (arcLength + 180 > 180 || arcLength + 180 < -90) {
        return length1 > length2 ? length2 / 2 : length1 / 2;
    } else {
        return length1 > length2 ? length2 / 3 : length1 / 3;
    }
}

export const getCurvedLinePoints = (points, radius, end = true, player = null) => {
    const direction = angle(player, [points[0], points[1], points[2], points[3]]);
    const direction2 = angle(null, points);

    if (end) {
        const [ex, ey] = getCoordinates(points[2], points[3], radius, direction - 180);
        const [sx, sy] = getCoordinates(points[2], points[3], radius, direction2);

        return [points[0], points[1], ex, ey, sx, sy]
    }

}
export const getArcCoordinates = (points, radius, alpha, player = null) => {
    const direction = angle(player, [points[0], points[1], points[2], points[3]]) + alpha;
    const [ax, ay] = getCoordinates(points[2], points[3], radius, direction);
    return [ax, ay]
}

export const getArcLength = points => {

    const direction1 = angle(null, [points[2], points[3], points[0], points[1]]);
    const direction2 = angle(null, points);


    let centerAngle;

    if ((direction2 - direction1) > 180 || (direction2 - direction1) < 0) {

        if ((direction2 - direction1) > 180) {
            centerAngle = 360 - direction2 + direction1
        } else {

            centerAngle = direction2 - direction1 <= -180 ? 360 + direction2 - direction1 : 360 - direction2 + direction1;
        }
    } else {
        centerAngle = 360 + direction2 - direction1
    }

    return 180 - centerAngle
}
export const getAlphaAngle = points => {
    const direction1 = angle(null, [points[2], points[3], points[0], points[1]]);
    const direction2 = angle(null, points);


    const centerAngle = 360 - direction2 + direction1;

    return (360 - centerAngle * 2) / 2
}
export const getArcRotation = (arcPoints, newPoints, alpha, arcLength) => {
    const radiusAngle = angle(null, [...arcPoints, newPoints[2], newPoints[3]])
    const addAngle = (360 - alpha * 2) / 4
    return radiusAngle + addAngle - arcLength / 2;
}

export const getRadius = (points, newPoints, arcPoints, a) => {
    const e = getSectionLength(newPoints[2], newPoints[3], newPoints[4], newPoints[5]);
    const f = getSectionLength(points[2], points[3], arcPoints[0], arcPoints[1]);
    const diamondArea = (e * f) / 2;

    return diamondArea / a;
}
export const quarterCheck = points => {
    const direction1 = angle(null, [points[2], points[3], points[0], points[1]]);
    const direction2 = angle(null, points);

    if (direction2 - direction1 <= -180) {
        return false
    }

    return ((direction2 - direction1) > 180 || (direction2 - direction1) < 0);

}
export const getTangentPoints = (arcPoints, arcLength, rotation, radius, points) => {

    const [ex, ey] = getCoordinates(arcPoints[0], arcPoints[1], radius, rotation);
    const [sx, sy] = getCoordinates(arcPoints[0], arcPoints[1], radius, rotation + arcLength);
    const check = quarterCheck(points)
    if (check) {
        return [ex, ey, sx, sy]
    }
    return [sx, sy, ex, ey]
}


export const getLastPoints = (points, anim = false) => {

    const arcLength = getArcLength(points);
    const tangent = getTangentLength(points, arcLength);
    const newPoints = getCurvedLinePoints(points, tangent);

    const alpha = getAlphaAngle(points);
    const arcCoords = getArcCoordinates(newPoints, tangent, alpha);
    const radius = getRadius(points, newPoints, arcCoords, tangent);
    const rotation = getArcRotation(arcCoords, newPoints, alpha, arcLength);
    const tangentPoints = getTangentPoints(arcCoords, arcLength, rotation, radius, points);

    return !anim ? [tangentPoints[2], tangentPoints[3]] : {
        center: arcCoords,
        tangentPoints: tangentPoints,
        radius: radius,
        rotation: rotation,
        arcLength: arcLength,
    };
}

export const getArcDash = (radius, arcAngle, dash1, dash2, quarter) => {
    let angle = arcAngle;
    if (angle < 0) {
        angle += 360
    } else if (angle > 360) {
        angle -= 360
    }
    const arcLength = 2 * radius * Math.PI * (angle / 360);


    let returnTable = quarter ? [...generateArcDash(arcLength, dash2), ...generateArcDash(arcLength, dash1)] : [...generateArcDash(arcLength, dash1), ...generateArcDash(arcLength, dash2)]


    returnTable = [...returnTable, 0, arcLength];


    return returnTable
}

const generateArcDash = (arcLength, dash) => {
    let returnTable = [];
    if (dash === 'wiggly' || dash === 'solid') {
        returnTable = [...returnTable, arcLength / 2, 0]
    } else {
        if (dash === 'dot') {
            for (let i = 0; i < arcLength / 2; i += 10) {
                returnTable = arcLength - i < 20 ? returnTable : [...returnTable, 0.001, 10]
            }
        } else if (dash === 'dashed') {
            for (let i = 0; i < arcLength / 2; i += 20) {

                returnTable = arcLength - i < 20 ? returnTable : [...returnTable, 10, 10]
            }
        }
    }
    return returnTable
}
