import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { getActiveFilters } from "../redux/actions";

export const FiltersContext = createContext('');

const FiltersProvider = ({ children }) => {
    const [search, setSearch] = useState('')
    const [tags, setTags] = useState([])
    const dispatch = useDispatch();

    const handleClear = useCallback(() => {
        setSearch("")
        setTags([])
    }, []);

    useEffect(() => {
        dispatch(getActiveFilters({
            tags: tags,
            search: search,
            predefined: false,
            page: 1,
        }));

        return () => {
            dispatch(getActiveFilters({
                tags: [],
                search: '',
                predefined: false,
                page: 1,
            }));
        };

    }, [search, tags]);

    return (
        <FiltersContext.Provider value={{
            search,
            setSearch,
            tags,
            setTags,
            clear: handleClear,
        }}>
            {children}
        </FiltersContext.Provider>
    );
}

export default FiltersProvider;
