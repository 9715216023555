import React from "react";
import { RegularPolygon } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const TrianglePlayer = ({
                                   player,
                                   handleClickActivate,
                                   activeTopSetting,
                                   handlePlayerDrag,
                                   editable,
                                   handleTarget,
                                   enableDraw,
                                   ratio,
                                   dm,
                                   movingPart,
                               }) => {

    return (
        <RegularPolygon radius={basePlayerSize * 1.1 * ratio * player.size / 2}
                        sides={player.shape === 'triangle' ? 3 : 6}
                        y={player.y}
                        x={player.x}
                        fill={player.shading === 'full' ? player.color : dm}
                        stroke={player.shading === 'full' ? 'transparent' : player.color}
                        draggable={activeTopSetting !== 'DL' && editable}
                        onMouseEnter={() => enableDraw(false)}
                        onMouseLeave={() => enableDraw(true)}
                        onClick={e => handleClickActivate(e)}
                        onTouchEnd={e => handleClickActivate(e)}
                        onDragMove={e => {
                            handlePlayerDrag(e);
                            handleTarget(e, true)
                        }}
                        ref={movingPart}
                        anchor={player.id}/>
    )
}
