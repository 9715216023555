import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components'
import PlaybookBlock, { PlaybookButton } from "../../../../components/blocks/PlaybookBlock";

import { PlaybooksService } from "../../../../services/PlaybooksService";
import { mapOrder, sortByOrder } from "../../../../utils/arrayHelpers";
import AddPlayBookModal from "../../../../components/modals/AddPlayBookModal";
import EditPlayBookModal from "../../../../components/modals/EditPlayBookModal";
import { useLoading } from "../../../../hooks/useLoading";
import Dnd from "../../../../components/dnd/dnd";

const PlaybooksList = () => {
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)

    const { setLoading : setGlobalLoading } = useLoading()

    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const [tmp, setTmp] = useState()

    const handleShowModal = useCallback(() => {
        setModal('add-playbook')
    }, [])

    const loadItems = useCallback(async page => {
        try {
            setLoading(true)
            const { items, page_count } = await PlaybooksService.getPlaybooks();
            setData(page > 1 ? [...data, ...items] : items)
            setCurrentPage(page)
        }catch (e){
            console.log('[getPlaybooks API]: ', e)
        }finally {
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        loadItems(1)
    }, [])

    const handleDeletePlaybook = useCallback(async item => {
        try {
            setGlobalLoading(true)
            await PlaybooksService.deletePlaybook(item.id)
            loadItems(1)
        }catch (e){
            console.log("Error [deletePlaybook]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [loadItems])

    const handleDuplicatePlaybook = useCallback(async ( item, index ) => {
        try {
            setGlobalLoading(true)
            const duplicated = await PlaybooksService.duplicatePlaybook(item.id)
            data.splice(1 + index, 0, duplicated );
            setData(data);
        }catch (e){
            console.log("Error [duplicatePlaybook]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [loadItems, data])

    const handleEditPlaybook = useCallback(item => {
       setModal('edit-playbook')
       setTmp(item)
    }, [])

    const handleOnMove = useCallback(async newData => {
        try {
            setGlobalLoading(true)
            await PlaybooksService.updatePlaybooksOrder(newData)
        }catch (e){
            console.log("Error [updatePlaysOrder]:", e)
        }finally {
            setGlobalLoading(false)
        }
    }, [currentPage])

    const renderItem = useCallback((item, index) => {
        return <PlaybookBlock
            key={item.id}
            index={index}
            item={item}
            onEdit={handleEditPlaybook}
            onDuplicate={handleDuplicatePlaybook}
            onDelete={handleDeletePlaybook}
        />
    }, [handleDeletePlaybook, handleDuplicatePlaybook, handleEditPlaybook])

    return (
        <>
            <Wrapper>
               <AddPlayBookModal
                   show={modal === 'add-playbook'}
                   setShow={setModal}
                   refresh={() => loadItems(1)}
               />
               <EditPlayBookModal
                   show={modal === 'edit-playbook'}
                   setShow={setModal}
                   data={tmp}
                   refresh={() => loadItems(1)}
               />
                { data && ( <Dnd
                    type={'playbooks'}
                    items={data}
                    setItems={setData}
                    renderItem={renderItem}
                    onMove={handleOnMove}
                    mainButton={<PlaybookButton onClick={handleShowModal} />}
                /> ) }
            </Wrapper>
        </>
    );
}

export default PlaybooksList;

const Wrapper = styled.div`

`;
