import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group } from "react-konva";
import { CirclePlayer } from "./PlayerGroups/CirclePlayer";
import { ArrowLine } from "./Lines/ArrowLine";
import { SquarePlayer } from "./PlayerGroups/SquarePlayer";
import { StarPlayer } from "./PlayerGroups/StarPlayer";
import { TrianglePlayer } from "./PlayerGroups/TrianglePlayer";
import { SimpleLine } from "./Lines/SimpleLine";
import { CircleEndpoint } from "./LineEndpoints/CircleEndpoint";
import { SquareEndpoint } from "./LineEndpoints/SquareEndpoint";
import { ReverseTriangleEndpoint } from "./LineEndpoints/ReverseTriangleEndpoint";
import { LineArrowEndpoint } from "./LineEndpoints/LineArrowEndpoint";
import { ActivatedPlayer } from "./PlayerGroups/ActivatedPlayer";
import { ShadedSquarePlayer } from "./shadings/ShadedSquarePlayer";
import { ShadedCirclePlayer } from "./shadings/ShadedCirclePlayer";
import { ShadedStarPlayer } from "./shadings/ShadedStarPlayer";
import { ShadedTrianglePlayer } from "./shadings/ShadedTrianglePlayer";
import { Ball } from "./PlayerGroups/Ball";
import { TextGroup } from "./PlayerGroups/TextGroup";
import { OtherShapePlayer } from "./PlayerGroups/OtherShapePlayer";
import { OvalPlayer } from "./PlayerGroups/OvalPlayer";
import { ShadedOvalPlayer } from "./shadings/ShadedOvalPlayer";
import { CurvedArrow } from "./Lines/CurvedArrow";
import { CurvedLine } from "./Lines/CurvedLine";
import { getLastPoints } from "../Helpers/arcHelper";
import { AdditionalLine } from "./PlayerGroups/AdditionalLine";
import { CrossPlayer } from "./PlayerGroups/CrossPlayer";
import { Label } from "./PlayerGroups/Label";
import {
    checkIfMoving,
    getAnimationDuration,
    getArcAnimationPoints,
    isStartingPoint, movePlayer, resetPlayer,
} from "../Helpers/animationHelper";


export const Player = ({
                           player,
                           activePlayer,
                           handleClickActivate,
                           activeTopSetting,
                           handlePlayerDrag,
                           handleUpdateTextSize,
                           handleChangeTransform,
                           editable,
                           activeLine,
                           lineActivation,
                           handleTarget,
                           enableDraw,
                           ratio,
                           delay,
                           darkmode,
                           pa,
                           rp,
                       }) => {
    const movingPart = useRef();
    const bgColor = player.line_points.length ? darkmode ? '#4D4D4D': 'white' : 'transparent';
    const choosePlayer = () => {
        const shapeProps = {
            player: player,
            handleClickActivate: handleClickActivate,
            activeTopSetting: activeTopSetting,
            handlePlayerDrag: handlePlayerDrag,
            handleTarget: handleTarget,
            enableDraw: enableDraw,
            ratio: ratio,
            editable: editable,
            dm: bgColor,
            movingPart: movingPart,
        }
        switch (player.shape) {
            case 'rectangle':
            case 'diamond':
                return <SquarePlayer {...shapeProps}/>
            case 'circle':
                return <CirclePlayer {...shapeProps}/>
            case 'star':
                return <StarPlayer {...shapeProps}/>
            case 'triangle':
            case 'hexagon':
                return <TrianglePlayer {...shapeProps}/>
            case 'ball':
                return <Ball {...shapeProps}/>
            case 'text':
                return <TextGroup {...shapeProps}
                                  handleUpdateTextSize={handleUpdateTextSize}
                                  handleChangeTransform={handleChangeTransform}
                                  activePLayer={activePlayer}
                                  shapeRef={movingPart}
                                  pa={pa}
                                  delay={delay}
                />
            case 'oval':
                return <OvalPlayer {...shapeProps}/>
            case 'addline':
                return <AdditionalLine {...shapeProps} activePLayer={activePlayer}/>
            case 'cross':
                return <CrossPlayer {...shapeProps}/>
            default:
                return <OtherShapePlayer {...shapeProps}/>
        }
    }

    const chooseShading = () => {
        const shapeProps = {
            player: player,
            handleClickActivate: handleClickActivate,
            activeTopSetting: activeTopSetting,
            handlePlayerDrag: handlePlayerDrag,
            handleTarget: handleTarget,
            enableDraw: enableDraw,
            ratio: ratio,
            editable: editable,
            dm: bgColor,
            movingPart: movingPart,
        }
        switch (player.shape) {
            case 'rectangle':
            case 'diamond':
                return <ShadedSquarePlayer {...shapeProps}/>
            case 'circle':
                return <ShadedCirclePlayer {...shapeProps}/>
            case 'star':
                return <ShadedStarPlayer {...shapeProps}/>
            case 'triangle':
            case 'hexagon':
                return <ShadedTrianglePlayer {...shapeProps}/>
            case 'text':
                return <TextGroup {...shapeProps}
                                  handleUpdateTextSize={handleUpdateTextSize}
                                  handleChangeTransform={handleChangeTransform}
                                  activePLayer={activePlayer}
                                  shapeRef={movingPart}
                                  pa={pa}
                                  delay={delay}
                />
            case 'oval':
                return <ShadedOvalPlayer {...shapeProps}/>
            case 'cross':
                return <CrossPlayer {...shapeProps}/>
            case 'addline':
                return <AdditionalLine {...shapeProps} activePLayer={activePlayer}/>
            default:
                return null
        }
    }

    const chooseEndpoint = (endpoint, points) => {
        switch (endpoint) {
            case 'circle':
                return <CircleEndpoint player={player} points={points}/>
            case 'square':
                return <SquareEndpoint player={player} points={points}/>
            case 'reverse':
                return <ReverseTriangleEndpoint player={player} points={points}/>
            case 'lineArrow':
                return <LineArrowEndpoint player={player} points={points}/>
            default:
                return null
        }
    }

    const generateLines = () => {

        if (player.line_tension === 0 || player.line_points.length < 6) {
            return player.line_dash?.map((dash, i) => {


                if (i < player.line_dash.length) {
                    if (player.line_endpoint[i] === 'arrow' || player.line_endpoint[i] === 'line') {
                        return (
                            <ArrowLine player={player} key={i} id={i} dash={dash} endpoint={player.line_endpoint[i]}
                                       activeLine={activePlayer === player.id && activeLine === i} ratio={ratio}
                                       lineActivation={lineActivation}
                                       points={[player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]]}/>
                        )
                    } else {
                        return (<>
                                <SimpleLine player={player} key={i} id={i} dash={dash}
                                            activeLine={activePlayer === player.id && activeLine === i}
                                            lineActivation={lineActivation} ratio={ratio}
                                            points={[player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]]}/>
                                {chooseEndpoint(player.line_endpoint[i], [player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]])}
                            </>
                        )
                    }
                }

            })
        } else {
            let lastPoints = null;
            return player.line_dash?.map((dash, i) => {


                if (i < player.line_dash.length) {


                    if (player.line_endpoint[i] === 'arrow' || player.line_endpoint[i] === 'line') {

                        if (i * 2 + 4 <= player.line_points.length - 1) {
                            let points = [player.line_points[(i - 1) * 2], player.line_points[(i - 1) * 2 + 1], player.line_points[(i - 1) * 2 + 2], player.line_points[(i - 1) * 2 + 3], player.line_points[(i - 1) * 2 + 4], player.line_points[(i - 1) * 2 + 5]]

                            lastPoints = getLastPoints(points)
                            return (
                                <CurvedArrow player={player} key={i} id={i} dash={dash} ratio={ratio}
                                             endpoint={player.line_endpoint[i]} lastPoints={i !== 0 ? lastPoints : null}
                                             activeLine={activePlayer === player.id && activeLine === i}
                                             lineActivation={lineActivation}
                                             points={[player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3], player.line_points[i * 2 + 4], player.line_points[i * 2 + 5]]}/>
                            )
                        } else {
                            let points = [player.line_points[(i - 1) * 2], player.line_points[(i - 1) * 2 + 1], player.line_points[(i - 1) * 2 + 2], player.line_points[(i - 1) * 2 + 3], player.line_points[(i - 1) * 2 + 4], player.line_points[(i - 1) * 2 + 5]]

                            lastPoints = getLastPoints(points)
                            return (
                                <ArrowLine player={player} key={i} id={i} dash={dash} endpoint={player.line_endpoint[i]}
                                           activeLine={activePlayer === player.id && activeLine === i} ratio={ratio}
                                           lineActivation={lineActivation} lastPoints={lastPoints}
                                           points={[player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]]}/>
                            )
                        }
                    } else {
                        if (i * 2 + 4 <= player.line_points.length - 1) {
                            let points = [player.line_points[(i - 1) * 2], player.line_points[(i - 1) * 2 + 1], player.line_points[(i - 1) * 2 + 2], player.line_points[(i - 1) * 2 + 3], player.line_points[(i - 1) * 2 + 4], player.line_points[(i - 1) * 2 + 5]]

                            lastPoints = getLastPoints(points)


                            return (<>

                                    <CurvedLine player={player} key={i} id={i} dash={dash} ratio={ratio}
                                                activeLine={activePlayer === player.id && activeLine === i}
                                                lineActivation={lineActivation} lastPoints={i !== 0 ? lastPoints : null}
                                                points={[player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3], player.line_points[i * 2 + 4], player.line_points[i * 2 + 5]]}/>
                                    {/*{chooseEndpoint(player.line_endpoint[i], [player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]])}*/}
                                </>
                            )
                        } else {
                            let points = [player.line_points[(i - 1) * 2], player.line_points[(i - 1) * 2 + 1], player.line_points[(i - 1) * 2 + 2], player.line_points[(i - 1) * 2 + 3], player.line_points[(i - 1) * 2 + 4], player.line_points[(i - 1) * 2 + 5]]

                            lastPoints = getLastPoints(points)
                            return (<>
                                    <SimpleLine player={player} key={i} id={i} dash={dash} ratio={ratio}
                                                activeLine={activePlayer === player.id && activeLine === i}
                                                lineActivation={lineActivation} lastPoints={lastPoints}
                                                points={[player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]]}/>
                                    {chooseEndpoint(player.line_endpoint[i], [player.line_points[i * 2], player.line_points[i * 2 + 1], player.line_points[i * 2 + 2], player.line_points[i * 2 + 3]])}
                                </>
                            )
                        }
                    }
                }

            })

        }
    }

    const radius = player ? 150 * ratio * player.size / 2 : 93 * ratio

    //animation
    const falseStart = player.line_dash.includes('wiggly')
    const moving = useMemo(() => checkIfMoving(player), [player, pa, delay, activeTopSetting])
    const startPoints = useMemo(() => player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points, [player, pa, activePlayer, delay, activeTopSetting, rp])
    const [points, setPoints] = useState(player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points)
    const [timeout, setTime] = useState(0);

    useEffect(() => setPoints(player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points), [player, pa, delay, activeTopSetting, rp, activePlayer])

    useEffect(() => {
        if (moving && pa && movingPart.current && points.length) {
            const duration = getAnimationDuration(points);
            const newPoints = points.filter((point, index) => index > 1)
            const startDelay = (isStartingPoint(player, movingPart.current.attrs) && !falseStart) ? delay * 1000 : 0
            if (startDelay) {
                setTimeout(() => movePlayer(movingPart, duration, newPoints[0], newPoints[1]), startDelay)
            } else {
                movePlayer(movingPart, duration, newPoints[0], newPoints[1])
            }

            if (newPoints.length > 2 && pa) {
                const id = setTimeout(() => setPoints([...newPoints]), (duration * 1000) + startDelay)
                setTime(id)
            } else if (pa) {
                const id = setTimeout(() => setPoints([]), (duration * 1000) + startDelay)
                setTime(id)
            }

        } else if (!pa) {
            if (startPoints.length !== points.length) {
                setPoints([...startPoints]);
                clearTimeout(timeout)
                setTime(0)
                resetPlayer(movingPart, player);
            }
        }
    }, [pa, points, player])

    return (
        <Group>
            {activePlayer === player.id ? <ActivatedPlayer player={player} ratio={ratio} radius={radius*2}/> :
                <ActivatedPlayer player={false} ratio={ratio} radius={radius}/>}
            {generateLines()}
            {(player.shading === 'full' || player.shading === 'blank') ? choosePlayer() : chooseShading()}
            {(player.label !== '' && player.shape !== 'text') &&
                <Label handlePlayerDrag={handlePlayerDrag}
                       editable={editable}
                       ratio={ratio}
                       radius={radius}
                       player={player}
                       handleTarget={handleTarget}
                       handleClickActivate={handleClickActivate}
                       activeTopSetting={activeTopSetting}
                       pa={pa}
                       rp={rp}
                       activePlayer={activePlayer}
                       delay={delay}/>
            }
        </Group>
    )
}


