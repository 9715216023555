import React from 'react';
import BookmarksView from "../../../templates/BookmarksView/BookmarksView";
import PlaysList from "./PlaysList/PlaysList";
import PlayBooksList from "./PlaybooksList/PlaybooksList";
import PredefinedList from "./PredefinedList/PredefinedList";
import { useLocation } from "react-router-dom";

const PLAYBOOKS_LINK = '/panel/playbooks'
const PLAYS_LINK = '/panel/plays'
const PLAYS_LIBRARY_LINK = '/panel/play-library'

const bookmarks = [
    {
        title:'my playbooks',
        path:PLAYBOOKS_LINK,
    },
    {
        title:'my plays',
        path:PLAYS_LINK,
    },
    {
        title:'play library',
        path:PLAYS_LIBRARY_LINK,
    },
]

const MainView = () => {
    const { pathname } = useLocation()

    const showFilters = pathname === PLAYS_LINK || pathname === PLAYS_LIBRARY_LINK
    return (
        <BookmarksView showFilters={showFilters} options={bookmarks}>
            {
                path => {
                    if(path === '/panel/playbooks'){
                        return <PlayBooksList />
                    }
                    if(path === '/panel/plays'){
                        return <PlaysList />
                    }
                    if(path === '/panel/play-library'){
                        return <PredefinedList />
                    }
                }
            }
        </BookmarksView>
    );
}

export default MainView;
