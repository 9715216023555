import React from "react";
import { Path } from "react-konva";
import { basePlayerSize } from "../../../constants";

export const OtherShapePlayer = ({
                                     player,
                                     handleClickActivate,
                                     activeTopSetting,
                                     handlePlayerDrag,
                                     editable,
                                     handleTarget,
                                     enableDraw,
                                     ratio,
                                     movingPart,
                                 }) => {
    const getShapeData = () => {
        switch (player.shape) {
            case 'cross':
                return 'M58.67,1.38a4.53,4.53,0,0,0-6.42,0L30,23.58,7.75,1.33A4.54,4.54,0,0,0,1.33,7.75L23.58,30,1.33,52.25a4.54,4.54,0,0,0,6.42,6.42L30,36.42,52.25,58.67a4.54,4.54,0,0,0,6.42-6.42L36.42,30,58.67,7.75A4.56,4.56,0,0,0,58.67,1.38Z';
            case 'flag':
                return 'M40.5,2.6c-2.7-1.6-5.6-2.4-8.9-2.4c-3.9,0-7.7,1.1-11.4,2.2c-3.6,1.1-7,2.1-10.4,2.1C7.6,4.5,5.6,4,3.7,3V1.9\n' +
                    '\tc0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v56.5c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8V30.8c1.9,0.8,4,1.1,6.2,1.1\n' +
                    '\tc3.9,0,7.7-1.1,11.4-2.2c3.6-1.1,7-2.1,10.4-2.1c2.6,0,4.9,0.6,7,1.9c0.5,0.3,1.2,0.3,1.8,0c0.5-0.3,0.9-0.9,0.9-1.5V4.1\n' +
                    '\tC41.3,3.5,41,2.9,40.5,2.6z';
            case 'asterisk':
                return 'M25.35,23l-.11.18a2.13,2.13,0,0,1-3,.68L15,19v8.84A2.13,2.13,0,0,1,12.87,30h0a2.12,2.12,0,0,1-2.12-2.13V19L3.42,23.88a2.12,2.12,0,0,1-3-.71L.29,23A2.12,2.12,0,0,1,1,20.11l8-5.19L1,9.74A2.12,2.12,0,0,1,.31,6.88L.4,6.73a2.13,2.13,0,0,1,3-.69l7.34,4.89V2.13A2.12,2.12,0,0,1,12.87,0h0A2.13,2.13,0,0,1,15,2.13v8.73L22.24,6a2.13,2.13,0,0,1,3,.68l.11.17a2.14,2.14,0,0,1-.65,2.88l-7.82,5.16,7.82,5.17A2.13,2.13,0,0,1,25.35,23Z"'
            default:
                return null
        }
    }

    const getOffsetX = () => {
        if (player.shape === 'flag') {
            return 20 * ratio
        } else if (player.shape === 'asterisk') {
            return 12.5 * ratio
        } else {
            return 30 * ratio
        }
    }

    return (

        <Path width={basePlayerSize * player.size * ratio}
              height={basePlayerSize * player.size * ratio}
              y={player.y}
              x={player.x}
              data={getShapeData()}
              fill={player.color}
              offsetX={getOffsetX()}
              offsetY={player.shape === 'asterisk' ? 15 : basePlayerSize * player.size / 2}
              draggable={activeTopSetting !== 'DL' && editable}
              onMouseEnter={() => enableDraw(false)}
              onMouseLeave={() => enableDraw(true)}
              onClick={e => handleClickActivate(e)}
              onTouchEnd={e => handleClickActivate(e)}
              onDragMove={e => {
                  handlePlayerDrag(e);
                  handleTarget(e, true)
              }}
              anchor={player.id}
              ref={movingPart}
        />

    )
}
