import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ArrowBack } from "@material-ui/icons";
import { Button, Grid, Popover, Tooltip, Typography } from "@material-ui/core";
import { StyledPaper } from "./StyledComponents/StyledComponents";
import AP from './svg/toptoolbar/AP.svg'
import AS from './svg/toptoolbar/AS.svg'
import AT from './svg/toptoolbar/AT.svg'
import DL from './svg/toptoolbar/DL.svg'
import DM from './svg/toptoolbar/DM.svg'
import DW from './svg/toptoolbar/DW.svg'
import FH from './svg/toptoolbar/FH.svg'
import GL from './svg/toptoolbar/GL.svg'
import GLN from './svg/toptoolbar/GLN.svg'
import PS from './svg/toptoolbar/PS.svg'
import checked from './svg/toptoolbar/checked.svg'
import undo from './svg/toptoolbar/undo.svg'
import trash from './svg/toptoolbar/trash.svg'
import Flag from './svg/toptoolbar/Flag.svg'
import Astrick from './svg/toptoolbar/Astrick.svg'
import AddLine from './svg/toptoolbar/AddLine.svg'
import Ball from './svg/toptoolbar/Ball.svg'
import Print from './svg/toptoolbar/Print.svg'
import Stop from './svg/toptoolbar/stop_1.svg'
import Play from './svg/toptoolbar/play_1.svg'
import Loop from './svg/toptoolbar/replay.svg'
import { useDispatch } from "react-redux";
import { setPrint } from "../../../redux/actions";
import Spinner from "../../../components/atoms/Spinner";


export const TopToolbar = ({
                               shrink,
                               handleTopSettingChange,
                               settingActive,
                               playerSettingChange,
                               darkMode,
                               removePlayer,
                               save,
                               saving,
                               baseLine,
                               activePLayer,
                               goBack,
                               goingBack,
                               newShapeChange,
                               setShapeChange,
                               shape,
                               handleExport,
                               player,
                               play,
                               anim,
                               setAnim,
                               width,
                           }) => {


    const [newElement, setNewElement] = useState(null);

    const dispatch = useDispatch()

    const newElementRef = useRef(false);


    const handlePrint = () => {
        dispatch(setPrint({
            type: 'play',
            play: play,
        }))
    }

    const openNewElement = () => {
        if (shape !== '') setShapeChange(true)
        setNewElement(newElementRef.current);

        // handleTopSettingChange('AS')
    }
    const closeNewElement = () => {
        setNewElement(null);
        setShapeChange(false)
    }
    const newElementOpen = Boolean(newElement);
    const id = newElementOpen ? 'simple-popover' : undefined;


    const handleShapePick = newShape => {
        setNewElement(null)
        if (!newShapeChange) {

            handleTopSettingChange('AS', newShape)
            setShapeChange(true)
        } else {
            playerSettingChange('shape', newShape)
        }
    }


    const canBeRemoved = player => {
        if (player) {
            const numOfPoints = player.line_points.length;
            let noLine;
            if (player.line_points.length > 0) {
                noLine = (player.line_points[0] === player.line_points[2] && player.line_points[1] === player.line_points[3]);
            } else {
                noLine = true
            }
            const topSettingDL = settingActive === 'DL';

            return (!topSettingDL && noLine && numOfPoints >= 4)

        } else return false
    }
    //Animations

    const startStopAnimations = () => {
        setAnim({
            ...anim,
            play: !anim.play,
            // loop: !anim.loop,
        })
    }
    const setLoop = () => setAnim({ ...anim, loop: !anim.loop });
    return (
        <Toolbar shrink={shrink} saveVisible={save} width={width}>
            <div onClick={goBack} style={{ cursor: 'pointer' }}>

                {goingBack ? <Spinner white={true}/> :
                    (<Tooltip title="Save and Exit" arrow>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ArrowBack/>
                            <Typography variant="p" style={{ fontWeight: 700 }}>BACK</Typography>
                        </div>
                    </Tooltip>)
                }
            </div>
            <div>
                <Tooltip title={'Settings'} arrow>
                    <TopButton variant="contained" active={settingActive === 'PS'} width={width} shrink={shrink}
                               onClick={() => handleTopSettingChange('PS')}>
                        <img alt="Settings" src={PS}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Add player" arrow>
                    <TopButton variant="contained" active={settingActive === 'AP'} width={width} shrink={shrink}
                               onClick={activePLayer !== 'new' ? () => handleTopSettingChange('AP') : () => null}>
                        {activePLayer !== 'new' ? <img alt="Add player" src={AP}/> : <Spinner white={true}/>}
                    </TopButton>
                </Tooltip>

                <Tooltip title="Add object" arrow>
                    <TopButton variant="contained" ref={newElementRef} active={settingActive === 'AS'} width={width}
                               shrink={shrink} onClick={newElement === null ? openNewElement : closeNewElement}>
                        <img alt="Add object" src={AS}/>
                    </TopButton>
                </Tooltip>

                <Popover
                    id={id}
                    open={newElementOpen}
                    onClose={closeNewElement}
                    anchorEl={newElementRef.current}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <StyledPaper style={{ width: width < 1320 ? (55 * width / 1320) + 'px' : '55px' }}>
                        <Grid container spacing={0}>
                            <Tooltip title={'Optional Line'} arrow placement="right">
                                <TopButton variant="contained" onClick={() => handleShapePick('addline')} width={width}
                                           shrink={shrink}>
                                    <img alt="addline" src={AddLine}/>
                                </TopButton>
                            </Tooltip>

                            <Tooltip title={'Asterisk'} arrow placement="right">
                                <TopButton variant="contained" onClick={() => handleShapePick('asterisk')} width={width}
                                           shrink={shrink}>
                                    <img alt="asterisk" src={Astrick}/>
                                </TopButton>
                            </Tooltip>

                            <Tooltip title={'Flag'} arrow placement="right">
                                <TopButton variant="contained" onClick={() => handleShapePick('flag')} width={width}
                                           shrink={shrink}>
                                    <img alt="flag" src={Flag}/>
                                </TopButton>
                            </Tooltip>

                            <Tooltip title={'Ball'} arrow placement="right">
                                <TopButton variant="contained" onClick={() => handleShapePick('ball')} width={width}
                                           shrink={shrink}>
                                    <img alt="ball" src={Ball}/>
                                </TopButton>
                            </Tooltip>
                        </Grid>
                    </StyledPaper>

                </Popover>


                {activePLayer !== null &&
                    <Tooltip title="Draw line" arrow>
                        <TopButton variant="contained" active={settingActive === 'DL'} width={width} shrink={shrink}
                                   onClick={() => handleTopSettingChange('DL')}>
                            <img alt="Draw line" src={DL}/>
                        </TopButton>
                    </Tooltip>
                }

                <Tooltip title="Add text box" arrow>
                    <TopButton variant="contained" active={settingActive === 'AT'} width={width} shrink={shrink}
                               onClick={() => handleTopSettingChange('AT', 'text')}>
                        <img alt="Add text box" src={AT}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Add line of scrimmage" arrow>
                    <TopButton variant="contained" active={baseLine} width={width} shrink={shrink}
                               onClick={() => handleTopSettingChange('GL')}>
                        <img alt="Add line of scrimmage" src={baseLine ? GL : GLN}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Flip play" arrow>
                    <TopButton variant="contained" active={settingActive === 'FH'} width={width} shrink={shrink}
                               onClick={() => handleTopSettingChange('FH')}>
                        <img alt="Flip play" src={FH}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Change background color" arrow>
                    <TopButton variant="contained" active={darkMode} width={width} shrink={shrink}
                               onClick={() => handleTopSettingChange('DM')}>
                        <img alt="Change background color" src={DM}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Download play image" arrow>
                    <TopButton variant="contained" active={settingActive === 'DW'} width={width} shrink={shrink}
                               onClick={handleExport}>
                        <img alt="download" src={DW}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Print" arrow>
                    <TopButton variant="contained" active={settingActive === 'Print'} width={width} shrink={shrink}
                               onClick={handlePrint}
                               hide={(width < 1320 && shrink)}>
                        <img alt="Print" src={Print}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title={anim.play ? 'Stop animation' : 'Start Animation'} arrow>
                    <TopButton variant="contained" active={anim.play} onClick={startStopAnimations}
                               hide={(width < 1320 && shrink)}
                               width={width} shrink={shrink}>
                        <img alt={anim.play ? 'Stop animation' : 'Start Animation'} src={anim.play ? Stop : Play}/>
                    </TopButton>
                </Tooltip>

                <Tooltip title="Loop animation" arrow>
                    <TopButton variant="contained" active={anim.loop} onClick={setLoop}
                               hide={(width < 1320 && shrink)} width={width}
                               shrink={shrink}>
                        <img alt="Loop animation" src={Loop}/>
                    </TopButton>
                </Tooltip>

            </div>


            <div className={'save-buttons'}>
                <Tooltip title={canBeRemoved(player) ? "Delete object" : "Remove last section"} arrow>
                    <img className="undo-btn" alt="delete" onClick={canBeRemoved(player) ? removePlayer : () => playerSettingChange('undo')}
                         src={canBeRemoved(player) ? trash : undo}/>
                </Tooltip>
                <Tooltip title="Save Changes" arrow>
                    <img className="save-btn" alt="save" onClick={() => handleTopSettingChange('save')} src={checked}/>
                </Tooltip>
            </div>
            <div style={{ fontWeight: 700, display: saving ? 'block' : 'none', marginLeft: "auto" }}>
                <Typography variant="p" style={{ fontWeight: 700 }}>Saving...</Typography>
            </div>


        </Toolbar>
    )
}

const getPosition = (width, shrink) => {
    if (width < 1200 && shrink) {
        return 38
    } else if (shrink) {
        return 40
    }
    return 50
}

export const Toolbar = styled.div`
  position: absolute;
  width: ${props => props.shrink ? 65 : 90}vw;
  border-radius: 10px;
  height: ${props => (props.width < 1320) ? 62 * (props.width / 1320) : 62}px;
  top: 100px;
  left: ${props => getPosition(props.width, props.shrink)}%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 10px rgb(0 0 0 / 35%);
  flex-wrap: wrap;
  z-index: 99;
  background-color: ${({ theme }) => theme.color.lblue};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    margin-left: ${props => (props.width < 1000 || (props.shrink && props.width < 1450)) ? 1 : 2}rem;
    margin-right: ${props => (props.width < 1000 || (props.shrink && props.width < 1450)) ? 1 : 2}rem;
  }

  & > div.save-buttons {
    margin-left: auto;
    display: ${props => props.saveVisible ? 'flex' : 'none'};

    img {
      height: ${props => (props.width < 1320) ? 50 * (props.width / 1320) : 50}px;
      padding: 8px;
      margin: 0 5px;
      border-radius: 100%;
    }
    img.undo-btn {
        background: #e71d36;
    }
    img.save-btn {
        background: #4bc405;
    }

    @media screen and (max-width: 1400px) {
        margin-top: 15px;
    }

  }


`
export const TopButton = styled(Button)`
  padding: 6px 6px !important;
  min-width: 30px !important;
  background-color: ${props => props.active ? '#FCC400' : '#FFF'} !important;
  border-radius: 100% !important;
  border: 2px solid ${({ theme }) => theme.color.lblue} !important;
  height: ${props => (props.width < 1320) ? 55 * (props.width / 1320) : 55}px;
  width: ${props => (props.width < 1320) ? 55 * (props.width / 1320) : 55}px;
  display: ${({ hide }) => hide ? 'none' : 'grid'};
  place-items: center;

  span {
    height: 80%;
    width: 80%;
  }

  img {
    max-width: 25px;
    //width: 80%;
    height: 100%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primary} !important;
  }

`


