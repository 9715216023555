import React, { useState } from 'react';
import Tags from "../atoms/Tags";
import { useTags } from "../../hooks/useTags";

const TagsBlock = ({ value, onChange }) => {
    const tags = useTags()
    const [active, setActive] = useState(0)

    return (
      <>
          {
              tags.map((el, index) =>
                <Tags key={el.id} index={index} active={active} setActive={setActive} label={el.title} value={value} options={el.tags} onChange={value => onChange(el.id, value)} />,
              )
          }
      </>
    );
}

export default TagsBlock;
