import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { PlaybooksService } from "../../services/PlaybooksService";
import { Row } from "../../theme/styledComponents";
import { Text22 } from "../atoms/Typography";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Modal from "../molecules/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getPlaybooks } from "../../redux/actions";

const AddPlayBookModal = ({ show, setShow, refresh }) => {
    const playbooks = useSelector(store => store.content.playbooks);
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            title:'',
        },
    })
    const dispatch = useDispatch();

    const title = watch('title')
    const [state, setState] = useState({} );

    const handleHideModal = useCallback(() => {
        setShow(false)
    }, [])

    const checkKeyDown = e => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const onSubmit = useCallback(async el => {
        if(loading)
            return

        setLoading(true)
        const order = playbooks[playbooks.length - 1] ? parseInt(playbooks[playbooks.length - 1].order) + 1 : 0
        const new_play = await PlaybooksService.addPlaybook({
            ...el,
            ...state,
            order,
        })
        setLoading(false)
        handleHideModal()
        refresh()

        playbooks.items.unshift( new_play );

        dispatch( getPlaybooks(playbooks) );

    }, [state, loading, refresh]);


    const isValid = title.replace(/\s/g, '').length > 0
    return (
        <Modal show={show} setShow={setShow} type="side" maxWidth="500px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => checkKeyDown(e)}>
                <Row jc="space-between" margin="0 0 30px">
                    <Text22>new playbook</Text22>
                    <Row>
                        <Button type="third" margin="0 25px" onClick={handleHideModal}>
                            Cancel
                        </Button>
                        <Button width="max-content" disabled={!isValid} loading={loading}>
                            Create
                        </Button>
                    </Row>
                </Row>
                <Input margin="0 0 40px" label='Playbook title' error={errors.title} rhf={register('title', { required: true })}/>
            </form>
        </Modal>
    )
}

export default AddPlayBookModal;
