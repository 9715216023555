import React from "react";
import { Color, StyledColorsContainer } from "../../StyledComponents/StyledComponents";
import { Grid } from "@material-ui/core";

export const ColorButton = ({ color, settingChange, active, setting = 'color' }) => {


    return (
      
        <Grid item style={{ flexBasis: '20%' }}>
          <StyledColorsContainer>
            <Color style={{
                backgroundColor: color,
                border: active ? '5px solid white' : '1px solid #F9F9F9',
            }} onClick={() => settingChange(setting, color)}>
            </Color>
            </StyledColorsContainer>
        </Grid>
    )

}
