import React from "react";
import { Rect } from "react-konva";
import { angle } from "../../Helpers/LineHelper";

export const SquareEndpoint = ({ player, points }) => {
  return(
      <Rect width={16}
            height={16}
            offsetX={8}
            offsetY={8}
            y={points[points.length - 1]}
            x={points[points.length - 2]}
            stroke={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}
            strokeWidth={5}
            rotation={angle(player, points)-45}
            fill={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}/>
  )
}