import React from 'react';
import styled, { css } from 'styled-components';
import { Text18 } from "./Typography";

const Radio = ({ margin, label, options, value, onChange }) => {

    return (
        <Wrapper margin={margin}>
            <Label>{label}</Label>
            <Content>
                {
                    options.map((el, index) =>
                        <Option key={index} active={value === el[1]} onClick={() => onChange(el[1])}>
                            <Text18>{el[0]}</Text18>
                        </Option>,
                    )
                }
            </Content>
        </Wrapper>
    );
}

export default Radio;

const Option = styled.div`
  flex: 1 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.blue};
  cursor: pointer;
  
  ${({ active }) => active && css`
    background: ${({ theme }) => theme.color.primary};
    *{
      color: ${({ theme }) => theme.color.secondary};
    }
  `}
  
  `;

const Content = styled.div`
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  `;

const Label = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.white};
  margin: 0 0 10px;
  `;

const Wrapper = styled.div`
  margin: ${({ margin }) => margin || "0 0 20px"};
    `;