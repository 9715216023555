import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Text18 } from "../../components/atoms/Typography";
import { NavLink, useLocation } from "react-router-dom";

import GO_BACK_ARROW from '../../assets/icons/arrow-back.svg'
import Button from "../../components/atoms/Button";
import TagsModal from "../../components/modals/TagsModal";
import Input from "../../components/atoms/Input";
import { useFilters } from "../../hooks/useFilters";
import { Row } from "../../theme/styledComponents";
import { useLoading } from "../../hooks/useLoading";
import Loader from "../../components/atoms/Loader";

const BookmarksView = ({ options, goBack, children, showFilters }) => {
  const { pathname } = useLocation();
  const { loading } = useLoading()
  const { search, setSearch, clear } = useFilters()
  const [modal, setModal] = useState()

  const handleFilters = useCallback(() => {
    setModal('filters')
  }, [])

  const handleChangeSearch = useCallback(e => {
    setSearch(e.target.value)
  }, [])


  return (
    <>
      <Wrapper showFilters={showFilters}>
        {
          showFilters &&
          <Row>
            <SearchWrapper>
              <Input margin="0" placeholder="Search" value={search} onChange={handleChangeSearch}
                     background="secondary"/>
            </SearchWrapper>
            <Button margin="0 10px" width="max-content" onClick={handleFilters}>
              Filters
            </Button>
          </Row>
        }
        <Header>
          {
            options.map((el, index) =>
              <Bookmark onClick={clear} key={index} to={goBack ?? el.path}
                        className={(el.active === pathname || pathname === el.path) ? 'selected' : ''}>
                {
                  goBack && <GoBackArrow src={GO_BACK_ARROW}/>
                }
                <Text18>{el.title}</Text18>
              </Bookmark>,
            )
          }
        </Header>
        <Content>
          {children(pathname)}
        </Content>
      </Wrapper>
      {showFilters && <TagsModal show={modal === 'filters'} setShow={setModal}/>}
      {
        loading &&
        <LoadingWrapper>
          <Loader/>
        </LoadingWrapper>
      }
    </>
  );
}

export default BookmarksView;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  z-index: 999;
`;

const SearchWrapper = styled.div`
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    display: none;
  }
`;

const GoBackArrow = styled.img`
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
`;

const Bookmark = styled(NavLink)`
  padding: 10px 30px;
  background: none;
  margin-right: 15px;
  border: 2px solid ${({ theme }) => theme.color.lblue};
  color: ${({ theme }) => theme.color.lblue};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  h2 {
    color: ${({ theme }) => theme.color.lblue};
  }

  &.selected, :hover {
    background: ${({ theme }) => theme.color.lblue};
    h2 {
        color: ${({ theme }) => theme.color.white};
    }
  }

  @media (max-width: 800px ) {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-bottom: 15px;

    &.selected {
      background: ${({ theme }) => theme.color.blue};

      &:before {
        left: 0px;
        width: 5px;
        height: 100%;
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 15px;
  margin-left: 45px;

  @media (max-width: 800px ) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 45px;
  width: 100%;
  background: ${({ theme }) => theme.color.secondary};
  border: 1px solid rgba(0, 0, 0, 0.075);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  z-index: 2;

  @media (max-width: 1000px) {
    padding: 35px;
  }
  @media (max-width: 800px) {
    padding: 20px;
  }
`;

const Wrapper = styled.div`
  padding-top: ${({ showFilters }) => showFilters ? '0' : '70px'};
  position: relative;
`;
