import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group, Rect, Text, Transformer } from "react-konva";
import {
    checkIfMoving,
    getAnimationDuration,
    getArcAnimationPoints,
    isStartingPoint, movePlayer, resetPlayer,
} from "../../Helpers/animationHelper";


export const TextGroup = ({
                              player,
                              handleClickActivate,
                              activeTopSetting,
                              handlePlayerDrag,
                              handleUpdateTextSize,
                              handleChangeTransform,
                              activePLayer,
                              editable,
                              handleTarget,
                              enableDraw,
                              ratio,
                              delay,
                              dm,
                              shapeRef,
                              pa,
                              rp,
                          }) => {


    const trRef = useRef()
    const textRef = useRef()

    // activate Transformer for active player
    useEffect(() => {
        if (activePLayer === player.id) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [activePLayer]);


    const optimalHeight = parseFloat(Math.max(player.height, player.size * 20)) * ratio
    const optimalWidth = parseFloat(Math.max(player.width, (player.size * 20 * 2) + (player.label.length * player.size * 10))) * ratio
    // animations - text
    const falseStart = player.line_dash.includes('wiggly');
    const moving = useMemo(() => checkIfMoving(player), [player, pa, delay, activeTopSetting])
    const startPoints = useMemo(() => player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points, [player, pa, activePLayer, delay, activeTopSetting, rp])
    const [points, setPoints] = useState(player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points)
    const [timeout, setTime] = useState(0);

    useEffect(() => setPoints(player.line_tension > 0 ? getArcAnimationPoints(player.line_points, player.label !== '') : player.line_points), [player, pa, delay, activeTopSetting, rp, activePLayer])

    useEffect(() => {
        if (moving && pa && points.length) {
            const duration = getAnimationDuration(points);
            const newPoints = points.filter((point, index) => index > 1)
            const startDelay = (isStartingPoint(player, textRef.current.attrs) && !falseStart) ? delay * 1000 : 0

            if (typeof startDelay === "number" && startDelay > 0) {
                setTimeout(() => movePlayer(textRef, duration, newPoints[0], newPoints[1]), startDelay)
            } else {
                movePlayer(textRef, duration, newPoints[0], newPoints[1])
            }

            if (newPoints.length > 2 && pa) {
                const t = startDelay ? startDelay : 0;
                const id = setTimeout(() => setPoints([...newPoints]), (duration * 1000) + t)
                setTime(id)
            }else if(pa){
                const id = setTimeout(() => setPoints([]), (duration * 1000) + startDelay)
                setTime(id)
            }

        } else if (!pa) {
            if (startPoints.length !== points.length) {
                setPoints([...startPoints]);
                clearTimeout(timeout)
                setTime(0)
                resetPlayer(textRef, player);
            }
        }
    }, [pa, points, player])


    return (
        <Group>
            <Rect
                width={optimalWidth}
                height={optimalHeight}
                y={player.y}
                x={player.x}
                offsetX={optimalWidth / 2}
                offsetY={optimalHeight / 2}
                fill={player.shading === 'full' ? player.color : dm}
                stroke={player.border_fill}
                strokeWidth={player.line_width}
                draggable={activeTopSetting !== 'DL' && editable}
                onMouseEnter={() => enableDraw(false)}
                onMouseLeave={() => enableDraw(true)}
                onClick={e => handleClickActivate(e)}
                onTouchEnd={e => handleClickActivate(e)}
                onDragMove={e => {
                    handlePlayerDrag(e);
                    handleTarget(e, true)
                }}
                anchor={player.id}
                cornerRadius={10}
                ref={shapeRef}
                onTransformEnd={e => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX().toFixed(2)
                    const scaleY = node.scaleY().toFixed(2)

                    const width = parseFloat((node.width() * scaleX).toFixed(2))
                    const height = parseFloat((node.height() * scaleY).toFixed(2))

                    let ratio = scaleX

                    const calculateTextSize = () => {
                        if (player.size * ratio > 2) {
                            return 2
                        }
                        if (player.size * ratio < 0.5) {
                            return 0.5
                        }
                        return player.size * ratio
                    }

                    const textSize = calculateTextSize().toFixed(2)

                    handleChangeTransform(e, { width, height })
                    handleUpdateTextSize(e, textSize, 'size')

                    // reset scale every render
                    node.scaleX(1)
                    node.scaleY(1)
                }}
                onTransform={() => {
                }}
            />


            <Text x={player.x}
                  y={player.y}
                  align={'center'}
                  width={optimalWidth}
                  height={optimalHeight}
                  text={player.label}
                  offsetX={optimalWidth / 2}
                  offsetY={player.size * 10}
                  fontSize={player.size * 20 * ratio}
                  fontFamily="Jersey M54"
                  fill={player.label_fill}
                  onMouseEnter={() => enableDraw(false)}
                  onMouseLeave={() => enableDraw(true)}
                  fontStyle={player.label_format !== 'underline' ? player.label_format : 'normal'}
                  textDecoration={player.label_format === 'underline' ? 'underline' : ''}
                  draggable={activeTopSetting !== 'DL'}
                  onClick={e => handleClickActivate(e)}
                  onDragMove={e => {
                      handlePlayerDrag(e);
                      handleTarget(e, true)
                  }}
                  anchor={player.id}
                  ref={textRef}
            />

            {activePLayer === player.id && (<Transformer
                ref={trRef}
                rotateEnabled={false}
            />)}
        </Group>
    )
}

