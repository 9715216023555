import React from "react";
import { Circle } from "react-konva";

export const CircleEndpoint = ({ player, points }) => {
  return(
      <Circle radius={8}
              y={points[points.length - 1]}
              x={points[points.length - 2]}
              stroke={player.stroke_fill !==''?player.stroke_fill:'#6949FF'}
              strokeWidth={5}
              fill={player.stroke_fill}
      />
  )
}